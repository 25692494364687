import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BreadCrumbService } from 'src/app/grubbrr/service/breadcrumb.service';
import { PageInfoService } from 'src/app/metronic/_metronic/layout/core/page-info.service';

@Injectable({
  providedIn: 'root',
})
/**
 * @class UtilService
 */
export class UtilService {
  Data$: Subject<any> = new BehaviorSubject<any>([]);
  GrubbrrRouteParams$: BehaviorSubject<GrubbrrRouteParams> =
    new BehaviorSubject<GrubbrrRouteParams>({});

  constructor(
    private translate: TranslateService,
    private pageService: PageInfoService,
    private breadCrumbService: BreadCrumbService
  ) {}

  /**
   * @description
   * PassData is an Observable with this Subject as the source. You can do this
   * to create customize Observer-side logic of the Subject and conceal it from
   * code that uses the Observable.
   *
   * * @usageNotes
   *
   * ```typescript
   * this.utilService.PassData('some data that you're passing');
   * ```
   *
   * ```typescript
   *     this.utilService.Data$
   *                      .subscribe(data => {
   *                         console.log(`${data} data`);
   *     })
   * ```
   *
   * @return {Observable} Observable that the Subject casts to
   */
  PassData(data: any) {
    this.Data$.next(data);
  }

  /**
   * @description
   * GrubbrrRouteEvent is an observable that emits the RouterEvents that contain
   * Grubbrr Route Params such as locationId and companyId
   * * @usageNotes
   *
   * ```typescript
   * this.utilService.PassData('some data that you're passing');
   * ```
   *
   * ```typescript
   *     this.utilService.Data$
   *                      .subscribe(data => {
   *                         console.log(`${data} data`);
   *     })
   * ```
   *
   * @return {Observable} Observable that the Subject casts to
   */
  GrubbrrRouteEvent(data: GrubbrrRouteParams) {
    this.GrubbrrRouteParams$.next(data);
  }

  /**
   * @description
   * isNullOrEmpty
   *
   * * @usageNotes
   *
   * ```typescript
   * this.utilService.isNullOrEmtpy('');
   * ```
   *
   * @return {boolean} returns true if the value is null, empty or undefined
   */
  isNullOrEmtpy(value: any): boolean {
    if (typeof value !== 'undefined' && value) {
      return false;
    }

    return true;
  }

  patchImage(
    renderer: Renderer2,
    formGroup: FormGroup,
    controlName: string,
    file: File,
    elementRef: ElementRef
  ) {
    formGroup.get(controlName)?.patchValue(file);
    renderer.setProperty(elementRef.nativeElement, 'innerHTML', file.name);
  }

  public GetTranslationValue(key: string): string {
    let keyValue = '';
    this.translate
      .get(key)
      .pipe(take(1))
      .subscribe((translation: string) => {
        keyValue = translation;
      });
    return keyValue;
  }

  async setupPageTitleAndBreadCrumb(
    title: string,
    locationId: string,
    area: string = '',
    areaURL: string = ''
  ): Promise<void> {
    const breadCrumbInfo = await this.breadCrumbService.getLocationBreadCrumb(
      locationId,
      area,
      areaURL
    );
    this.pageService.updateTitle(title);
    this.pageService.updateBreadcrumbs(breadCrumbInfo.breadCrumbs);
  }
}

export class GrubbrrRouteParams {
  locationId?: string;
  companyId?: string;
}

function getTimezoneOffset(atTime: Date, timeZone: string) {
  const localizedTime = new Date(atTime.toLocaleString('en-US', { timeZone }));
  const utcTime = new Date(atTime.toLocaleString('en-US', { timeZone: 'UTC' }));
  return Math.round(
    (localizedTime.getTime() - utcTime.getTime()) / (60 * 1000)
  );
}

export const adjustDateForLocationTimezone = (
  date: Date,
  timezone: string | undefined
) => {
  if (!timezone) {
    return;
  }

  const tzOffset = getTimezoneOffset(date, timezone);
  const currentOffset = new Date().getTimezoneOffset() * -1;
  const diff = tzOffset - currentOffset;
  date.setMinutes(date.getMinutes() - diff);
};

export const sortByProperty = (array = <any>[], property: string) => {
  array.sort((a: any, b: any) => {
    const nameA = a[property].toUpperCase();
    const nameB = b[property].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
