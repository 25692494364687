/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "locationsService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import { LocationStaffVM } from "./locations_pb";
import { AccessControlListVM } from "./accessList_pb";
import { EmptyResponse } from "./common_pb";
import { PosIntegrationMenuSettingsVM } from "./locations_pb";
import { PosIntegrationVM } from "./locations_pb";
import { LocationSetupStatusVM } from "./locations_pb";
import { LocationBaseVM } from "./locations_pb";
import { LocationRequest } from "./common_pb";
import { LocationsVM } from "./locations_pb";
import { CompanyRequest } from "./common_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PosSyncIntegrationConfigVM } from "./locations_pb";
import { PosIntegrationConfigVM } from "./locations_pb";
import { LocationDetailVM } from "./locations_pb";
import { StaffVM } from "./locations_pb";
import { TransformConfigVM } from "./locations_pb";
/**
 * @generated from protobuf message Mgmt.GEMAccessTokenRequest
 */
export interface GEMAccessTokenRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string application = 2;
     */
    application: string;
}
/**
 * @generated from protobuf message Mgmt.GEMAccessTokenResponse
 */
export interface GEMAccessTokenResponse {
    /**
     * @generated from protobuf field: string accessToken = 1;
     */
    accessToken: string;
}
/**
 * @generated from protobuf message Mgmt.AddTransformConfigRequest
 */
export interface AddTransformConfigRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.TransformConfigVM config = 2;
     */
    config?: TransformConfigVM;
}
/**
 * @generated from protobuf message Mgmt.RemoveStaffRequest
 */
export interface RemoveStaffRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message Mgmt.AddStaffRequest
 */
export interface AddStaffRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.StaffVM staff = 2;
     */
    staff?: StaffVM;
}
/**
 * @generated from protobuf message Mgmt.UpsertLocationRequest
 */
export interface UpsertLocationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.LocationDetailVM location = 2;
     */
    location?: LocationDetailVM;
}
/**
 * @generated from protobuf message Mgmt.AddLocationRequest
 */
export interface AddLocationRequest {
    /**
     * @generated from protobuf field: string CompanyId = 1 [json_name = "CompanyId"];
     */
    companyId: string;
    /**
     * @generated from protobuf field: Mgmt.LocationDetailVM location = 2;
     */
    location?: LocationDetailVM;
}
/**
 * @generated from protobuf message Mgmt.UpdateLocationBaseRequest
 */
export interface UpdateLocationBaseRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string catalogId = 2;
     */
    catalogId: string;
    /**
     * @generated from protobuf field: string posConnectorConfigurationId = 3;
     */
    posConnectorConfigurationId: string;
    /**
     * @generated from protobuf field: string menuId = 4;
     */
    menuId: string;
}
/**
 * @generated from protobuf message Mgmt.UpdatePosRequest
 */
export interface UpdatePosRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.PosIntegrationConfigVM config = 2;
     */
    config?: PosIntegrationConfigVM;
}
/**
 * @generated from protobuf message Mgmt.AddLocationUserAccessRequest
 */
export interface AddLocationUserAccessRequest {
    /**
     * @generated from protobuf field: string LocationId = 1 [json_name = "LocationId"];
     */
    locationId: string;
    /**
     * @generated from protobuf field: string UserEmail = 2 [json_name = "UserEmail"];
     */
    userEmail: string;
    /**
     * @generated from protobuf field: string RoleName = 3 [json_name = "RoleName"];
     */
    roleName: string;
}
/**
 * @generated from protobuf message Mgmt.UpdateLocationUserAccessRequest
 */
export interface UpdateLocationUserAccessRequest {
    /**
     * @generated from protobuf field: string LocationId = 1 [json_name = "LocationId"];
     */
    locationId: string;
    /**
     * @generated from protobuf field: string UserId = 2 [json_name = "UserId"];
     */
    userId: string;
    /**
     * @generated from protobuf field: string RoleName = 3 [json_name = "RoleName"];
     */
    roleName: string;
}
/**
 * @generated from protobuf message Mgmt.RemoveLocationUserAccessRequest
 */
export interface RemoveLocationUserAccessRequest {
    /**
     * @generated from protobuf field: string LocationId = 1 [json_name = "LocationId"];
     */
    locationId: string;
    /**
     * @generated from protobuf field: string UserId = 2 [json_name = "UserId"];
     */
    userId: string;
}
/**
 * @generated from protobuf message Mgmt.UpdatePosSyncRequest
 */
export interface UpdatePosSyncRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig = 2;
     */
    posSyncIntegrationConfig?: PosSyncIntegrationConfigVM;
}
// @generated message type with reflection information, may provide speed optimized methods
class GEMAccessTokenRequest$Type extends MessageType<GEMAccessTokenRequest> {
    constructor() {
        super("Mgmt.GEMAccessTokenRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "application", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GEMAccessTokenRequest>): GEMAccessTokenRequest {
        const message = { locationId: "", application: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GEMAccessTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GEMAccessTokenRequest): GEMAccessTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string application */ 2:
                    message.application = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GEMAccessTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string application = 2; */
        if (message.application !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.application);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GEMAccessTokenRequest
 */
export const GEMAccessTokenRequest = new GEMAccessTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GEMAccessTokenResponse$Type extends MessageType<GEMAccessTokenResponse> {
    constructor() {
        super("Mgmt.GEMAccessTokenResponse", [
            { no: 1, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GEMAccessTokenResponse>): GEMAccessTokenResponse {
        const message = { accessToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GEMAccessTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GEMAccessTokenResponse): GEMAccessTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accessToken */ 1:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GEMAccessTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accessToken = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GEMAccessTokenResponse
 */
export const GEMAccessTokenResponse = new GEMAccessTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTransformConfigRequest$Type extends MessageType<AddTransformConfigRequest> {
    constructor() {
        super("Mgmt.AddTransformConfigRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config", kind: "message", T: () => TransformConfigVM }
        ]);
    }
    create(value?: PartialMessage<AddTransformConfigRequest>): AddTransformConfigRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddTransformConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTransformConfigRequest): AddTransformConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.TransformConfigVM config */ 2:
                    message.config = TransformConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTransformConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.TransformConfigVM config = 2; */
        if (message.config)
            TransformConfigVM.internalBinaryWrite(message.config, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddTransformConfigRequest
 */
export const AddTransformConfigRequest = new AddTransformConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveStaffRequest$Type extends MessageType<RemoveStaffRequest> {
    constructor() {
        super("Mgmt.RemoveStaffRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveStaffRequest>): RemoveStaffRequest {
        const message = { locationId: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveStaffRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveStaffRequest): RemoveStaffRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveStaffRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RemoveStaffRequest
 */
export const RemoveStaffRequest = new RemoveStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddStaffRequest$Type extends MessageType<AddStaffRequest> {
    constructor() {
        super("Mgmt.AddStaffRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "staff", kind: "message", T: () => StaffVM }
        ]);
    }
    create(value?: PartialMessage<AddStaffRequest>): AddStaffRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddStaffRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddStaffRequest): AddStaffRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.StaffVM staff */ 2:
                    message.staff = StaffVM.internalBinaryRead(reader, reader.uint32(), options, message.staff);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddStaffRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.StaffVM staff = 2; */
        if (message.staff)
            StaffVM.internalBinaryWrite(message.staff, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddStaffRequest
 */
export const AddStaffRequest = new AddStaffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertLocationRequest$Type extends MessageType<UpsertLocationRequest> {
    constructor() {
        super("Mgmt.UpsertLocationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location", kind: "message", T: () => LocationDetailVM }
        ]);
    }
    create(value?: PartialMessage<UpsertLocationRequest>): UpsertLocationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertLocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertLocationRequest): UpsertLocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.LocationDetailVM location */ 2:
                    message.location = LocationDetailVM.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertLocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.LocationDetailVM location = 2; */
        if (message.location)
            LocationDetailVM.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpsertLocationRequest
 */
export const UpsertLocationRequest = new UpsertLocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddLocationRequest$Type extends MessageType<AddLocationRequest> {
    constructor() {
        super("Mgmt.AddLocationRequest", [
            { no: 1, name: "CompanyId", kind: "scalar", jsonName: "CompanyId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location", kind: "message", T: () => LocationDetailVM }
        ]);
    }
    create(value?: PartialMessage<AddLocationRequest>): AddLocationRequest {
        const message = { companyId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddLocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddLocationRequest): AddLocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CompanyId = 1 [json_name = "CompanyId"];*/ 1:
                    message.companyId = reader.string();
                    break;
                case /* Mgmt.LocationDetailVM location */ 2:
                    message.location = LocationDetailVM.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddLocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CompanyId = 1 [json_name = "CompanyId"]; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* Mgmt.LocationDetailVM location = 2; */
        if (message.location)
            LocationDetailVM.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddLocationRequest
 */
export const AddLocationRequest = new AddLocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationBaseRequest$Type extends MessageType<UpdateLocationBaseRequest> {
    constructor() {
        super("Mgmt.UpdateLocationBaseRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "catalogId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "posConnectorConfigurationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "menuId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationBaseRequest>): UpdateLocationBaseRequest {
        const message = { locationId: "", catalogId: "", posConnectorConfigurationId: "", menuId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationBaseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationBaseRequest): UpdateLocationBaseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string catalogId */ 2:
                    message.catalogId = reader.string();
                    break;
                case /* string posConnectorConfigurationId */ 3:
                    message.posConnectorConfigurationId = reader.string();
                    break;
                case /* string menuId */ 4:
                    message.menuId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationBaseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string catalogId = 2; */
        if (message.catalogId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.catalogId);
        /* string posConnectorConfigurationId = 3; */
        if (message.posConnectorConfigurationId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.posConnectorConfigurationId);
        /* string menuId = 4; */
        if (message.menuId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.menuId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateLocationBaseRequest
 */
export const UpdateLocationBaseRequest = new UpdateLocationBaseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePosRequest$Type extends MessageType<UpdatePosRequest> {
    constructor() {
        super("Mgmt.UpdatePosRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config", kind: "message", T: () => PosIntegrationConfigVM }
        ]);
    }
    create(value?: PartialMessage<UpdatePosRequest>): UpdatePosRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePosRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePosRequest): UpdatePosRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.PosIntegrationConfigVM config */ 2:
                    message.config = PosIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePosRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.PosIntegrationConfigVM config = 2; */
        if (message.config)
            PosIntegrationConfigVM.internalBinaryWrite(message.config, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdatePosRequest
 */
export const UpdatePosRequest = new UpdatePosRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddLocationUserAccessRequest$Type extends MessageType<AddLocationUserAccessRequest> {
    constructor() {
        super("Mgmt.AddLocationUserAccessRequest", [
            { no: 1, name: "LocationId", kind: "scalar", jsonName: "LocationId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserEmail", kind: "scalar", jsonName: "UserEmail", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "RoleName", kind: "scalar", jsonName: "RoleName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddLocationUserAccessRequest>): AddLocationUserAccessRequest {
        const message = { locationId: "", userEmail: "", roleName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddLocationUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddLocationUserAccessRequest): AddLocationUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string LocationId = 1 [json_name = "LocationId"];*/ 1:
                    message.locationId = reader.string();
                    break;
                case /* string UserEmail = 2 [json_name = "UserEmail"];*/ 2:
                    message.userEmail = reader.string();
                    break;
                case /* string RoleName = 3 [json_name = "RoleName"];*/ 3:
                    message.roleName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddLocationUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string LocationId = 1 [json_name = "LocationId"]; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string UserEmail = 2 [json_name = "UserEmail"]; */
        if (message.userEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userEmail);
        /* string RoleName = 3 [json_name = "RoleName"]; */
        if (message.roleName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roleName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddLocationUserAccessRequest
 */
export const AddLocationUserAccessRequest = new AddLocationUserAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLocationUserAccessRequest$Type extends MessageType<UpdateLocationUserAccessRequest> {
    constructor() {
        super("Mgmt.UpdateLocationUserAccessRequest", [
            { no: 1, name: "LocationId", kind: "scalar", jsonName: "LocationId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserId", kind: "scalar", jsonName: "UserId", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "RoleName", kind: "scalar", jsonName: "RoleName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLocationUserAccessRequest>): UpdateLocationUserAccessRequest {
        const message = { locationId: "", userId: "", roleName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateLocationUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLocationUserAccessRequest): UpdateLocationUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string LocationId = 1 [json_name = "LocationId"];*/ 1:
                    message.locationId = reader.string();
                    break;
                case /* string UserId = 2 [json_name = "UserId"];*/ 2:
                    message.userId = reader.string();
                    break;
                case /* string RoleName = 3 [json_name = "RoleName"];*/ 3:
                    message.roleName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLocationUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string LocationId = 1 [json_name = "LocationId"]; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string UserId = 2 [json_name = "UserId"]; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string RoleName = 3 [json_name = "RoleName"]; */
        if (message.roleName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roleName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateLocationUserAccessRequest
 */
export const UpdateLocationUserAccessRequest = new UpdateLocationUserAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveLocationUserAccessRequest$Type extends MessageType<RemoveLocationUserAccessRequest> {
    constructor() {
        super("Mgmt.RemoveLocationUserAccessRequest", [
            { no: 1, name: "LocationId", kind: "scalar", jsonName: "LocationId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserId", kind: "scalar", jsonName: "UserId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveLocationUserAccessRequest>): RemoveLocationUserAccessRequest {
        const message = { locationId: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveLocationUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveLocationUserAccessRequest): RemoveLocationUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string LocationId = 1 [json_name = "LocationId"];*/ 1:
                    message.locationId = reader.string();
                    break;
                case /* string UserId = 2 [json_name = "UserId"];*/ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveLocationUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string LocationId = 1 [json_name = "LocationId"]; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string UserId = 2 [json_name = "UserId"]; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RemoveLocationUserAccessRequest
 */
export const RemoveLocationUserAccessRequest = new RemoveLocationUserAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePosSyncRequest$Type extends MessageType<UpdatePosSyncRequest> {
    constructor() {
        super("Mgmt.UpdatePosSyncRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "posSyncIntegrationConfig", kind: "message", T: () => PosSyncIntegrationConfigVM }
        ]);
    }
    create(value?: PartialMessage<UpdatePosSyncRequest>): UpdatePosSyncRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePosSyncRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePosSyncRequest): UpdatePosSyncRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig */ 2:
                    message.posSyncIntegrationConfig = PosSyncIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncIntegrationConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePosSyncRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig = 2; */
        if (message.posSyncIntegrationConfig)
            PosSyncIntegrationConfigVM.internalBinaryWrite(message.posSyncIntegrationConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdatePosSyncRequest
 */
export const UpdatePosSyncRequest = new UpdatePosSyncRequest$Type();
/**
 * @generated ServiceType for protobuf service Mgmt.LocationsService
 */
export const LocationsService = new ServiceType("Mgmt.LocationsService", [
    { name: "GetLocations", options: {}, I: CompanyRequest, O: LocationsVM },
    { name: "GetLocationDetails", options: {}, I: LocationRequest, O: LocationDetailVM },
    { name: "GetLocationSummary", options: {}, I: LocationRequest, O: LocationBaseVM },
    { name: "GetLocationSetupStatus", options: {}, I: LocationRequest, O: LocationSetupStatusVM },
    { name: "GetLocationPosSettings", options: {}, I: LocationRequest, O: PosIntegrationVM },
    { name: "GetLocationPosMenuSettings", options: {}, I: LocationRequest, O: PosIntegrationMenuSettingsVM },
    { name: "UpdateLocationPos", options: {}, I: UpdatePosRequest, O: PosIntegrationConfigVM },
    { name: "RemoveLocationPos", options: {}, I: UpdatePosRequest, O: PosIntegrationConfigVM },
    { name: "UpdateLocationBase", options: {}, I: UpdateLocationBaseRequest, O: LocationBaseVM },
    { name: "ProxyRemoteSync", options: {}, I: LocationRequest, O: EmptyResponse },
    { name: "PullLatestPosServiceSnapshot", options: {}, I: LocationRequest, O: EmptyResponse },
    { name: "AddLocation", options: {}, I: AddLocationRequest, O: LocationDetailVM },
    { name: "UpdateLocation", options: {}, I: UpsertLocationRequest, O: LocationDetailVM },
    { name: "RemoveLocation", options: {}, I: LocationRequest, O: EmptyResponse },
    { name: "GetLocationUserAccessList", options: {}, I: LocationRequest, O: AccessControlListVM },
    { name: "AddUserAccess", options: {}, I: AddLocationUserAccessRequest, O: AccessControlListVM },
    { name: "UpdateUserAccess", options: {}, I: UpdateLocationUserAccessRequest, O: AccessControlListVM },
    { name: "RemoveUserAccess", options: {}, I: RemoveLocationUserAccessRequest, O: AccessControlListVM },
    { name: "AddStaff", options: {}, I: AddStaffRequest, O: LocationStaffVM },
    { name: "RemoveStaff", options: {}, I: RemoveStaffRequest, O: LocationStaffVM },
    { name: "GetStaff", options: {}, I: LocationRequest, O: LocationStaffVM },
    { name: "GetTransformConfig", options: {}, I: LocationRequest, O: TransformConfigVM },
    { name: "AddTransformConfig", options: {}, I: AddTransformConfigRequest, O: EmptyResponse },
    { name: "GetGEMAccessToken", options: {}, I: GEMAccessTokenRequest, O: GEMAccessTokenResponse },
    { name: "UpdatePosSyncSetting", options: {}, I: UpdatePosSyncRequest, O: PosSyncIntegrationConfigVM }
]);
