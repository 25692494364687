/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskConfig.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp_pb";
import { DayOfWeekVM } from "./common_pb";
import { KioskColorsVM } from "./appearance_pb";
import { LocalizedStringVM } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.KioskConfigVM
 */
export interface KioskConfigVM {
    /**
     * @generated from protobuf field: Mgmt.OrderTypeConfigVM orderTypes = 1;
     */
    orderTypes?: OrderTypeConfigVM;
    /**
     * @generated from protobuf field: Mgmt.AgeVerificationSettingsVM ageVerificationSettings = 2;
     */
    ageVerificationSettings?: AgeVerificationSettingsVM;
    /**
     * @generated from protobuf field: Mgmt.TimeoutSettingsVM timeouts = 3;
     */
    timeouts?: TimeoutSettingsVM;
    /**
     * @generated from protobuf field: Mgmt.TipSettingsVM tips = 4;
     */
    tips?: TipSettingsVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizationVM localization = 5;
     */
    localization?: LocalizationVM;
    /**
     * @generated from protobuf field: string defaultMenuLayoutId = 6;
     */
    defaultMenuLayoutId: string;
    /**
     * @generated from protobuf field: bool conceptsEnabled = 7;
     */
    conceptsEnabled: boolean;
    /**
     * @generated from protobuf field: bool caloriesEnabled = 8;
     */
    caloriesEnabled: boolean;
    /**
     * @generated from protobuf field: Mgmt.ItemSpecialRequestSettings itemSpecialRequestSettings = 9;
     */
    itemSpecialRequestSettings?: ItemSpecialRequestSettings;
    /**
     * @generated from protobuf field: Mgmt.DisclaimerConfigurationVM disclaimerConfiguration = 10;
     */
    disclaimerConfiguration?: DisclaimerConfigurationVM;
    /**
     * @generated from protobuf field: bool discountsEnabled = 11;
     */
    discountsEnabled: boolean;
    /**
     * @generated from protobuf field: Mgmt.AdaConfigurationVM adaConfiguration = 12;
     */
    adaConfiguration?: AdaConfigurationVM;
    /**
     * @generated from protobuf field: bool legalCopyEnabled = 13;
     */
    legalCopyEnabled: boolean;
    /**
     * @generated from protobuf field: Mgmt.LegalCopyVM legalCopy = 14;
     */
    legalCopy?: LegalCopyVM;
    /**
     * @generated from protobuf field: Mgmt.BarcodeScannerConfigurationVM barcodeScannerConfiguration = 15;
     */
    barcodeScannerConfiguration?: BarcodeScannerConfigurationVM;
    /**
     * @generated from protobuf field: Mgmt.MenuBehaviorConfigurationVM menuBehaviorConfiguration = 16;
     */
    menuBehaviorConfiguration?: MenuBehaviorConfigurationVM;
    /**
     * @generated from protobuf field: bool showCategoryHighlightedColor = 17;
     */
    showCategoryHighlightedColor: boolean;
    /**
     * @generated from protobuf field: Mgmt.OrderLimitsConfigVM orderLimitsConfig = 18;
     */
    orderLimitsConfig?: OrderLimitsConfigVM;
    /**
     * @generated from protobuf field: bool checkPosFlow = 19;
     */
    checkPosFlow: boolean;
    /**
     * @generated from protobuf field: bool calculateDefaultModifierPrice = 20;
     */
    calculateDefaultModifierPrice: boolean;
    /**
     * @generated from protobuf field: bool trackKioskUserBehavior = 21;
     */
    trackKioskUserBehavior: boolean;
    /**
     * @generated from protobuf field: bool loyaltyFeature = 22;
     */
    loyaltyFeature: boolean;
}
/**
 * @generated from protobuf message Mgmt.OrderLimitsConfigVM
 */
export interface OrderLimitsConfigVM {
    /**
     * @generated from protobuf field: int32 quantityLimitPerItem = 1;
     */
    quantityLimitPerItem: number;
    /**
     * @generated from protobuf field: int32 quantityLimitPerOrder = 2;
     */
    quantityLimitPerOrder: number;
}
/**
 * @generated from protobuf message Mgmt.AdaConfigurationVM
 */
export interface AdaConfigurationVM {
    /**
     * @generated from protobuf field: bool adaModeEnabled = 12;
     */
    adaModeEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.BarcodeScannerConfigurationVM
 */
export interface BarcodeScannerConfigurationVM {
    /**
     * @generated from protobuf field: Mgmt.ExternalBarcodeScannerModel model = 1;
     */
    model: ExternalBarcodeScannerModel;
}
/**
 * @generated from protobuf message Mgmt.MenuBehaviorConfigurationVM
 */
export interface MenuBehaviorConfigurationVM {
    /**
     * @generated from protobuf field: Mgmt.NegativeModifierBehavior negativeModifierBehavior = 1;
     */
    negativeModifierBehavior: NegativeModifierBehavior;
}
/**
 * @generated from protobuf message Mgmt.DisclaimerConfigurationVM
 */
export interface DisclaimerConfigurationVM {
    /**
     * @generated from protobuf field: string disclaimerText = 1;
     */
    disclaimerText: string;
    /**
     * @generated from protobuf field: string imageLink = 2;
     */
    imageLink: string;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedDisclaimerText = 3;
     */
    localizedDisclaimerText?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Mgmt.ItemSpecialRequestSettings
 */
export interface ItemSpecialRequestSettings {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: int32 characterLimit = 2;
     */
    characterLimit: number;
}
/**
 * @generated from protobuf message Mgmt.LocalizationVM
 */
export interface LocalizationVM {
    /**
     * @generated from protobuf field: Mgmt.CurrencyVM currency = 1;
     */
    currency?: CurrencyVM;
    /**
     * @generated from protobuf field: repeated string enabledLanguages = 2;
     */
    enabledLanguages: string[];
    /**
     * @generated from protobuf field: Mgmt.LocaleVM locale = 3;
     */
    locale?: LocaleVM;
    /**
     * @generated from protobuf field: string defaultLanguage = 4;
     */
    defaultLanguage: string;
}
/**
 * @generated from protobuf message Mgmt.LegalCopyVM
 */
export interface LegalCopyVM {
    /**
     * @generated from protobuf field: string legalButtonText = 1;
     */
    legalButtonText: string;
    /**
     * @generated from protobuf field: string legalMessageTitle = 2;
     */
    legalMessageTitle: string;
    /**
     * @generated from protobuf field: string legalTextContent = 3;
     */
    legalTextContent: string;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedLegalButtonText = 4;
     */
    localizedLegalButtonText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedLegalMessageTitle = 5;
     */
    localizedLegalMessageTitle?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedLegalTextContent = 6;
     */
    localizedLegalTextContent?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Mgmt.LocaleVM
 */
export interface LocaleVM {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message Mgmt.CurrencyVM
 */
export interface CurrencyVM {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: int32 divisor = 2;
     */
    divisor: number;
}
/**
 * @generated from protobuf message Mgmt.OrderTypeConfigVM
 */
export interface OrderTypeConfigVM {
    /**
     * @generated from protobuf field: repeated Mgmt.OrderTypeOptionVM options = 1;
     */
    options: OrderTypeOptionVM[];
    /**
     * @generated from protobuf field: Mgmt.OrderTokenSettingsVM tokenSettings = 2;
     */
    tokenSettings?: OrderTokenSettingsVM;
}
/**
 * @generated from protobuf message Mgmt.AgeVerificationSettingsVM
 */
export interface AgeVerificationSettingsVM {
}
/**
 * @generated from protobuf message Mgmt.TimeoutSettingsVM
 */
export interface TimeoutSettingsVM {
    /**
     * @generated from protobuf field: int32 idleTimeout = 1;
     */
    idleTimeout: number;
    /**
     * @generated from protobuf field: int32 restartPromptTimer = 2;
     */
    restartPromptTimer: number;
}
/**
 * @generated from protobuf message Mgmt.GratuityTendersVM
 */
export interface GratuityTendersVM {
    /**
     * @generated from protobuf field: repeated Mgmt.GratuityTenderOptionVM gratuityTenders = 1;
     */
    gratuityTenders: GratuityTenderOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.GratuityTenderOptionVM
 */
export interface GratuityTenderOptionVM {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message Mgmt.TipSettingsVM
 */
export interface TipSettingsVM {
    /**
     * @generated from protobuf field: bool enableTips = 1;
     */
    enableTips: boolean;
    /**
     * @generated from protobuf field: bool showCalculatedTip = 2;
     */
    showCalculatedTip: boolean;
    /**
     * @generated from protobuf field: bool customTipEnabled = 3;
     */
    customTipEnabled: boolean;
    /**
     * @generated from protobuf field: repeated Mgmt.TipVM tips = 4;
     */
    tips: TipVM[];
    /**
     * @generated from protobuf field: string tender = 5;
     */
    tender: string;
}
/**
 * @generated from protobuf message Mgmt.TipVM
 */
export interface TipVM {
    /**
     * @generated from protobuf field: int32 value = 1;
     */
    value: number;
    /**
     * @generated from protobuf field: bool isDefault = 2;
     */
    isDefault: boolean;
}
/**
 * @generated from protobuf message Mgmt.OrderTypeOptionVM
 */
export interface OrderTypeOptionVM {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string externalDeliveryMode = 3;
     */
    externalDeliveryMode: string;
    /**
     * @generated from protobuf field: Mgmt.OrderIdentityVM orderIdentity = 4;
     */
    orderIdentity?: OrderIdentityVM;
    /**
     * @generated from protobuf field: bool enabled = 5;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedLabel = 6;
     */
    localizedLabel?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Mgmt.OrderIdentityVM
 */
export interface OrderIdentityVM {
    /**
     * @generated from protobuf field: int32 orderIdentityMode = 1;
     */
    orderIdentityMode: number;
    /**
     * @generated from protobuf field: int32 customerIdentityMode = 2;
     */
    customerIdentityMode: number;
    /**
     * @generated from protobuf field: Mgmt.NameIdSettingsVM nameSettings = 5;
     */
    nameSettings?: NameIdSettingsVM;
    /**
     * @generated from protobuf field: string orderIdPrefix = 6;
     */
    orderIdPrefix: string;
}
/**
 * @generated from protobuf message Mgmt.OrderTokenSettingsVM
 */
export interface OrderTokenSettingsVM {
    /**
     * @generated from protobuf field: int32 orderNumberStart = 1;
     */
    orderNumberStart: number;
    /**
     * @generated from protobuf field: int32 allotment = 2;
     */
    allotment: number;
}
/**
 * @generated from protobuf message Mgmt.TableTentIdSettingsVM
 */
export interface TableTentIdSettingsVM {
    /**
     * @generated from protobuf field: string prefix = 1;
     */
    prefix: string;
}
/**
 * @generated from protobuf message Mgmt.NameIdSettingsVM
 */
export interface NameIdSettingsVM {
    /**
     * @generated from protobuf field: bool askBeforeOrder = 1;
     */
    askBeforeOrder: boolean;
}
/**
 * @generated from protobuf message Mgmt.PosOrderTypeVM
 */
export interface PosOrderTypeVM {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
}
/**
 * @generated from protobuf message Mgmt.ConceptVM
 */
export interface ConceptVM {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bool isActive = 2;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string menuLayoutId = 5;
     */
    menuLayoutId: string;
    /**
     * @generated from protobuf field: Mgmt.ConceptThemeVM theme = 4;
     */
    theme?: ConceptThemeVM;
}
/**
 * @generated from protobuf message Mgmt.ConceptThemeVM
 */
export interface ConceptThemeVM {
    /**
     * @generated from protobuf field: Mgmt.ConceptMediaVM media = 1;
     */
    media?: ConceptMediaVM;
    /**
     * @generated from protobuf field: Mgmt.KioskColorsVM colors = 2;
     */
    colors?: KioskColorsVM;
}
/**
 * @generated from protobuf message Mgmt.ConceptMediaVM
 */
export interface ConceptMediaVM {
    /**
     * @generated from protobuf field: string categoryHeader = 1;
     */
    categoryHeader: string;
    /**
     * @generated from protobuf field: string logo = 2;
     */
    logo: string;
}
/**
 * @generated from protobuf message Mgmt.ConceptsResponse
 */
export interface ConceptsResponse {
    /**
     * @generated from protobuf field: repeated Mgmt.ConceptVM concepts = 1;
     */
    concepts: ConceptVM[];
}
/**
 * @generated from protobuf message Mgmt.BusinessHoursMessageVM
 */
export interface BusinessHoursMessageVM {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 2;
     */
    subtitle: string;
    /**
     * @generated from protobuf field: string message = 3;
     */
    message: string;
    /**
     * @generated from protobuf field: bool showScreensaver = 4;
     */
    showScreensaver: boolean;
}
/**
 * @generated from protobuf message Mgmt.BusinessHoursConfigurationVM
 */
export interface BusinessHoursConfigurationVM {
    /**
     * @generated from protobuf field: repeated Mgmt.BusinessHoursVM businessHours = 1;
     */
    businessHours: BusinessHoursVM[];
    /**
     * @generated from protobuf field: Mgmt.BusinessHoursMessageVM message = 2;
     */
    message?: BusinessHoursMessageVM;
    /**
     * @generated from protobuf field: bool showMessage = 3;
     */
    showMessage: boolean;
    /**
     * @generated from protobuf field: bool enabled = 4;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: bool posHoursEnabled = 5;
     */
    posHoursEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.BusinessHoursVM
 */
export interface BusinessHoursVM {
    /**
     * @generated from protobuf field: Mgmt.DayOfWeekVM day = 1;
     */
    day: DayOfWeekVM;
    /**
     * @generated from protobuf field: repeated Mgmt.HourRangeVM hours = 2;
     */
    hours: HourRangeVM[];
}
/**
 * @generated from protobuf message Mgmt.HourRangeVM
 */
export interface HourRangeVM {
    /**
     * @generated from protobuf field: int32 startMinutes = 1;
     */
    startMinutes: number;
    /**
     * @generated from protobuf field: int32 endMinutes = 2;
     */
    endMinutes: number;
}
/**
 * @generated from protobuf message Mgmt.SyncInterval
 */
export interface SyncInterval {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp startTime = 2;
     */
    startTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endTime = 3;
     */
    endTime?: Timestamp;
    /**
     * @generated from protobuf field: int32 intervalMinutes = 4;
     */
    intervalMinutes: number;
    /**
     * @generated from protobuf field: bool enabled = 5;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf enum Mgmt.ExternalBarcodeScannerModel
 */
export enum ExternalBarcodeScannerModel {
    /**
     * @generated from protobuf enum value: NoExternalScanner = 0;
     */
    NoExternalScanner = 0,
    /**
     * @generated from protobuf enum value: ZebraUsb = 1;
     */
    ZebraUsb = 1
}
/**
 * @generated from protobuf enum Mgmt.NegativeModifierBehavior
 */
export enum NegativeModifierBehavior {
    /**
     * @generated from protobuf enum value: ShowNegativeModifiersAsUnselected = 0;
     */
    ShowNegativeModifiersAsUnselected = 0,
    /**
     * @generated from protobuf enum value: ShowDefaultModifiersAsPreSelected = 1;
     */
    ShowDefaultModifiersAsPreSelected = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class KioskConfigVM$Type extends MessageType<KioskConfigVM> {
    constructor() {
        super("Mgmt.KioskConfigVM", [
            { no: 1, name: "orderTypes", kind: "message", T: () => OrderTypeConfigVM },
            { no: 2, name: "ageVerificationSettings", kind: "message", T: () => AgeVerificationSettingsVM },
            { no: 3, name: "timeouts", kind: "message", T: () => TimeoutSettingsVM },
            { no: 4, name: "tips", kind: "message", T: () => TipSettingsVM },
            { no: 5, name: "localization", kind: "message", T: () => LocalizationVM },
            { no: 6, name: "defaultMenuLayoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "conceptsEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "caloriesEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "itemSpecialRequestSettings", kind: "message", T: () => ItemSpecialRequestSettings },
            { no: 10, name: "disclaimerConfiguration", kind: "message", T: () => DisclaimerConfigurationVM },
            { no: 11, name: "discountsEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "adaConfiguration", kind: "message", T: () => AdaConfigurationVM },
            { no: 13, name: "legalCopyEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "legalCopy", kind: "message", T: () => LegalCopyVM },
            { no: 15, name: "barcodeScannerConfiguration", kind: "message", T: () => BarcodeScannerConfigurationVM },
            { no: 16, name: "menuBehaviorConfiguration", kind: "message", T: () => MenuBehaviorConfigurationVM },
            { no: 17, name: "showCategoryHighlightedColor", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "orderLimitsConfig", kind: "message", T: () => OrderLimitsConfigVM },
            { no: 19, name: "checkPosFlow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "calculateDefaultModifierPrice", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "trackKioskUserBehavior", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "loyaltyFeature", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KioskConfigVM>): KioskConfigVM {
        const message = { defaultMenuLayoutId: "", conceptsEnabled: false, caloriesEnabled: false, discountsEnabled: false, legalCopyEnabled: false, showCategoryHighlightedColor: false, checkPosFlow: false, calculateDefaultModifierPrice: false, trackKioskUserBehavior: false, loyaltyFeature: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskConfigVM): KioskConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.OrderTypeConfigVM orderTypes */ 1:
                    message.orderTypes = OrderTypeConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.orderTypes);
                    break;
                case /* Mgmt.AgeVerificationSettingsVM ageVerificationSettings */ 2:
                    message.ageVerificationSettings = AgeVerificationSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.ageVerificationSettings);
                    break;
                case /* Mgmt.TimeoutSettingsVM timeouts */ 3:
                    message.timeouts = TimeoutSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.timeouts);
                    break;
                case /* Mgmt.TipSettingsVM tips */ 4:
                    message.tips = TipSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.tips);
                    break;
                case /* Mgmt.LocalizationVM localization */ 5:
                    message.localization = LocalizationVM.internalBinaryRead(reader, reader.uint32(), options, message.localization);
                    break;
                case /* string defaultMenuLayoutId */ 6:
                    message.defaultMenuLayoutId = reader.string();
                    break;
                case /* bool conceptsEnabled */ 7:
                    message.conceptsEnabled = reader.bool();
                    break;
                case /* bool caloriesEnabled */ 8:
                    message.caloriesEnabled = reader.bool();
                    break;
                case /* Mgmt.ItemSpecialRequestSettings itemSpecialRequestSettings */ 9:
                    message.itemSpecialRequestSettings = ItemSpecialRequestSettings.internalBinaryRead(reader, reader.uint32(), options, message.itemSpecialRequestSettings);
                    break;
                case /* Mgmt.DisclaimerConfigurationVM disclaimerConfiguration */ 10:
                    message.disclaimerConfiguration = DisclaimerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.disclaimerConfiguration);
                    break;
                case /* bool discountsEnabled */ 11:
                    message.discountsEnabled = reader.bool();
                    break;
                case /* Mgmt.AdaConfigurationVM adaConfiguration */ 12:
                    message.adaConfiguration = AdaConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.adaConfiguration);
                    break;
                case /* bool legalCopyEnabled */ 13:
                    message.legalCopyEnabled = reader.bool();
                    break;
                case /* Mgmt.LegalCopyVM legalCopy */ 14:
                    message.legalCopy = LegalCopyVM.internalBinaryRead(reader, reader.uint32(), options, message.legalCopy);
                    break;
                case /* Mgmt.BarcodeScannerConfigurationVM barcodeScannerConfiguration */ 15:
                    message.barcodeScannerConfiguration = BarcodeScannerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.barcodeScannerConfiguration);
                    break;
                case /* Mgmt.MenuBehaviorConfigurationVM menuBehaviorConfiguration */ 16:
                    message.menuBehaviorConfiguration = MenuBehaviorConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.menuBehaviorConfiguration);
                    break;
                case /* bool showCategoryHighlightedColor */ 17:
                    message.showCategoryHighlightedColor = reader.bool();
                    break;
                case /* Mgmt.OrderLimitsConfigVM orderLimitsConfig */ 18:
                    message.orderLimitsConfig = OrderLimitsConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.orderLimitsConfig);
                    break;
                case /* bool checkPosFlow */ 19:
                    message.checkPosFlow = reader.bool();
                    break;
                case /* bool calculateDefaultModifierPrice */ 20:
                    message.calculateDefaultModifierPrice = reader.bool();
                    break;
                case /* bool trackKioskUserBehavior */ 21:
                    message.trackKioskUserBehavior = reader.bool();
                    break;
                case /* bool loyaltyFeature */ 22:
                    message.loyaltyFeature = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.OrderTypeConfigVM orderTypes = 1; */
        if (message.orderTypes)
            OrderTypeConfigVM.internalBinaryWrite(message.orderTypes, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AgeVerificationSettingsVM ageVerificationSettings = 2; */
        if (message.ageVerificationSettings)
            AgeVerificationSettingsVM.internalBinaryWrite(message.ageVerificationSettings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.TimeoutSettingsVM timeouts = 3; */
        if (message.timeouts)
            TimeoutSettingsVM.internalBinaryWrite(message.timeouts, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.TipSettingsVM tips = 4; */
        if (message.tips)
            TipSettingsVM.internalBinaryWrite(message.tips, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizationVM localization = 5; */
        if (message.localization)
            LocalizationVM.internalBinaryWrite(message.localization, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string defaultMenuLayoutId = 6; */
        if (message.defaultMenuLayoutId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.defaultMenuLayoutId);
        /* bool conceptsEnabled = 7; */
        if (message.conceptsEnabled !== false)
            writer.tag(7, WireType.Varint).bool(message.conceptsEnabled);
        /* bool caloriesEnabled = 8; */
        if (message.caloriesEnabled !== false)
            writer.tag(8, WireType.Varint).bool(message.caloriesEnabled);
        /* Mgmt.ItemSpecialRequestSettings itemSpecialRequestSettings = 9; */
        if (message.itemSpecialRequestSettings)
            ItemSpecialRequestSettings.internalBinaryWrite(message.itemSpecialRequestSettings, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.DisclaimerConfigurationVM disclaimerConfiguration = 10; */
        if (message.disclaimerConfiguration)
            DisclaimerConfigurationVM.internalBinaryWrite(message.disclaimerConfiguration, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bool discountsEnabled = 11; */
        if (message.discountsEnabled !== false)
            writer.tag(11, WireType.Varint).bool(message.discountsEnabled);
        /* Mgmt.AdaConfigurationVM adaConfiguration = 12; */
        if (message.adaConfiguration)
            AdaConfigurationVM.internalBinaryWrite(message.adaConfiguration, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool legalCopyEnabled = 13; */
        if (message.legalCopyEnabled !== false)
            writer.tag(13, WireType.Varint).bool(message.legalCopyEnabled);
        /* Mgmt.LegalCopyVM legalCopy = 14; */
        if (message.legalCopy)
            LegalCopyVM.internalBinaryWrite(message.legalCopy, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.BarcodeScannerConfigurationVM barcodeScannerConfiguration = 15; */
        if (message.barcodeScannerConfiguration)
            BarcodeScannerConfigurationVM.internalBinaryWrite(message.barcodeScannerConfiguration, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.MenuBehaviorConfigurationVM menuBehaviorConfiguration = 16; */
        if (message.menuBehaviorConfiguration)
            MenuBehaviorConfigurationVM.internalBinaryWrite(message.menuBehaviorConfiguration, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bool showCategoryHighlightedColor = 17; */
        if (message.showCategoryHighlightedColor !== false)
            writer.tag(17, WireType.Varint).bool(message.showCategoryHighlightedColor);
        /* Mgmt.OrderLimitsConfigVM orderLimitsConfig = 18; */
        if (message.orderLimitsConfig)
            OrderLimitsConfigVM.internalBinaryWrite(message.orderLimitsConfig, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* bool checkPosFlow = 19; */
        if (message.checkPosFlow !== false)
            writer.tag(19, WireType.Varint).bool(message.checkPosFlow);
        /* bool calculateDefaultModifierPrice = 20; */
        if (message.calculateDefaultModifierPrice !== false)
            writer.tag(20, WireType.Varint).bool(message.calculateDefaultModifierPrice);
        /* bool trackKioskUserBehavior = 21; */
        if (message.trackKioskUserBehavior !== false)
            writer.tag(21, WireType.Varint).bool(message.trackKioskUserBehavior);
        /* bool loyaltyFeature = 22; */
        if (message.loyaltyFeature !== false)
            writer.tag(22, WireType.Varint).bool(message.loyaltyFeature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskConfigVM
 */
export const KioskConfigVM = new KioskConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderLimitsConfigVM$Type extends MessageType<OrderLimitsConfigVM> {
    constructor() {
        super("Mgmt.OrderLimitsConfigVM", [
            { no: 1, name: "quantityLimitPerItem", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "quantityLimitPerOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OrderLimitsConfigVM>): OrderLimitsConfigVM {
        const message = { quantityLimitPerItem: 0, quantityLimitPerOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderLimitsConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderLimitsConfigVM): OrderLimitsConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantityLimitPerItem */ 1:
                    message.quantityLimitPerItem = reader.int32();
                    break;
                case /* int32 quantityLimitPerOrder */ 2:
                    message.quantityLimitPerOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderLimitsConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantityLimitPerItem = 1; */
        if (message.quantityLimitPerItem !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantityLimitPerItem);
        /* int32 quantityLimitPerOrder = 2; */
        if (message.quantityLimitPerOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityLimitPerOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderLimitsConfigVM
 */
export const OrderLimitsConfigVM = new OrderLimitsConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaConfigurationVM$Type extends MessageType<AdaConfigurationVM> {
    constructor() {
        super("Mgmt.AdaConfigurationVM", [
            { no: 12, name: "adaModeEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AdaConfigurationVM>): AdaConfigurationVM {
        const message = { adaModeEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdaConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdaConfigurationVM): AdaConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool adaModeEnabled */ 12:
                    message.adaModeEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdaConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool adaModeEnabled = 12; */
        if (message.adaModeEnabled !== false)
            writer.tag(12, WireType.Varint).bool(message.adaModeEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AdaConfigurationVM
 */
export const AdaConfigurationVM = new AdaConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BarcodeScannerConfigurationVM$Type extends MessageType<BarcodeScannerConfigurationVM> {
    constructor() {
        super("Mgmt.BarcodeScannerConfigurationVM", [
            { no: 1, name: "model", kind: "enum", T: () => ["Mgmt.ExternalBarcodeScannerModel", ExternalBarcodeScannerModel] }
        ]);
    }
    create(value?: PartialMessage<BarcodeScannerConfigurationVM>): BarcodeScannerConfigurationVM {
        const message = { model: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BarcodeScannerConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BarcodeScannerConfigurationVM): BarcodeScannerConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.ExternalBarcodeScannerModel model */ 1:
                    message.model = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BarcodeScannerConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.ExternalBarcodeScannerModel model = 1; */
        if (message.model !== 0)
            writer.tag(1, WireType.Varint).int32(message.model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BarcodeScannerConfigurationVM
 */
export const BarcodeScannerConfigurationVM = new BarcodeScannerConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuBehaviorConfigurationVM$Type extends MessageType<MenuBehaviorConfigurationVM> {
    constructor() {
        super("Mgmt.MenuBehaviorConfigurationVM", [
            { no: 1, name: "negativeModifierBehavior", kind: "enum", T: () => ["Mgmt.NegativeModifierBehavior", NegativeModifierBehavior] }
        ]);
    }
    create(value?: PartialMessage<MenuBehaviorConfigurationVM>): MenuBehaviorConfigurationVM {
        const message = { negativeModifierBehavior: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MenuBehaviorConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuBehaviorConfigurationVM): MenuBehaviorConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.NegativeModifierBehavior negativeModifierBehavior */ 1:
                    message.negativeModifierBehavior = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuBehaviorConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.NegativeModifierBehavior negativeModifierBehavior = 1; */
        if (message.negativeModifierBehavior !== 0)
            writer.tag(1, WireType.Varint).int32(message.negativeModifierBehavior);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.MenuBehaviorConfigurationVM
 */
export const MenuBehaviorConfigurationVM = new MenuBehaviorConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisclaimerConfigurationVM$Type extends MessageType<DisclaimerConfigurationVM> {
    constructor() {
        super("Mgmt.DisclaimerConfigurationVM", [
            { no: 1, name: "disclaimerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "imageLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "localizedDisclaimerText", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<DisclaimerConfigurationVM>): DisclaimerConfigurationVM {
        const message = { disclaimerText: "", imageLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisclaimerConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisclaimerConfigurationVM): DisclaimerConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string disclaimerText */ 1:
                    message.disclaimerText = reader.string();
                    break;
                case /* string imageLink */ 2:
                    message.imageLink = reader.string();
                    break;
                case /* Mgmt.LocalizedStringVM localizedDisclaimerText */ 3:
                    message.localizedDisclaimerText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedDisclaimerText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisclaimerConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string disclaimerText = 1; */
        if (message.disclaimerText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.disclaimerText);
        /* string imageLink = 2; */
        if (message.imageLink !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageLink);
        /* Mgmt.LocalizedStringVM localizedDisclaimerText = 3; */
        if (message.localizedDisclaimerText)
            LocalizedStringVM.internalBinaryWrite(message.localizedDisclaimerText, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DisclaimerConfigurationVM
 */
export const DisclaimerConfigurationVM = new DisclaimerConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemSpecialRequestSettings$Type extends MessageType<ItemSpecialRequestSettings> {
    constructor() {
        super("Mgmt.ItemSpecialRequestSettings", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "characterLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ItemSpecialRequestSettings>): ItemSpecialRequestSettings {
        const message = { enabled: false, characterLimit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ItemSpecialRequestSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemSpecialRequestSettings): ItemSpecialRequestSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* int32 characterLimit */ 2:
                    message.characterLimit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemSpecialRequestSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* int32 characterLimit = 2; */
        if (message.characterLimit !== 0)
            writer.tag(2, WireType.Varint).int32(message.characterLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ItemSpecialRequestSettings
 */
export const ItemSpecialRequestSettings = new ItemSpecialRequestSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizationVM$Type extends MessageType<LocalizationVM> {
    constructor() {
        super("Mgmt.LocalizationVM", [
            { no: 1, name: "currency", kind: "message", T: () => CurrencyVM },
            { no: 2, name: "enabledLanguages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locale", kind: "message", T: () => LocaleVM },
            { no: 4, name: "defaultLanguage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocalizationVM>): LocalizationVM {
        const message = { enabledLanguages: [], defaultLanguage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocalizationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizationVM): LocalizationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.CurrencyVM currency */ 1:
                    message.currency = CurrencyVM.internalBinaryRead(reader, reader.uint32(), options, message.currency);
                    break;
                case /* repeated string enabledLanguages */ 2:
                    message.enabledLanguages.push(reader.string());
                    break;
                case /* Mgmt.LocaleVM locale */ 3:
                    message.locale = LocaleVM.internalBinaryRead(reader, reader.uint32(), options, message.locale);
                    break;
                case /* string defaultLanguage */ 4:
                    message.defaultLanguage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocalizationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.CurrencyVM currency = 1; */
        if (message.currency)
            CurrencyVM.internalBinaryWrite(message.currency, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string enabledLanguages = 2; */
        for (let i = 0; i < message.enabledLanguages.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.enabledLanguages[i]);
        /* Mgmt.LocaleVM locale = 3; */
        if (message.locale)
            LocaleVM.internalBinaryWrite(message.locale, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string defaultLanguage = 4; */
        if (message.defaultLanguage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.defaultLanguage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocalizationVM
 */
export const LocalizationVM = new LocalizationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LegalCopyVM$Type extends MessageType<LegalCopyVM> {
    constructor() {
        super("Mgmt.LegalCopyVM", [
            { no: 1, name: "legalButtonText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legalMessageTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "legalTextContent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "localizedLegalButtonText", kind: "message", T: () => LocalizedStringVM },
            { no: 5, name: "localizedLegalMessageTitle", kind: "message", T: () => LocalizedStringVM },
            { no: 6, name: "localizedLegalTextContent", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<LegalCopyVM>): LegalCopyVM {
        const message = { legalButtonText: "", legalMessageTitle: "", legalTextContent: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LegalCopyVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LegalCopyVM): LegalCopyVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string legalButtonText */ 1:
                    message.legalButtonText = reader.string();
                    break;
                case /* string legalMessageTitle */ 2:
                    message.legalMessageTitle = reader.string();
                    break;
                case /* string legalTextContent */ 3:
                    message.legalTextContent = reader.string();
                    break;
                case /* Mgmt.LocalizedStringVM localizedLegalButtonText */ 4:
                    message.localizedLegalButtonText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalButtonText);
                    break;
                case /* Mgmt.LocalizedStringVM localizedLegalMessageTitle */ 5:
                    message.localizedLegalMessageTitle = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalMessageTitle);
                    break;
                case /* Mgmt.LocalizedStringVM localizedLegalTextContent */ 6:
                    message.localizedLegalTextContent = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLegalTextContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LegalCopyVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string legalButtonText = 1; */
        if (message.legalButtonText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.legalButtonText);
        /* string legalMessageTitle = 2; */
        if (message.legalMessageTitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalMessageTitle);
        /* string legalTextContent = 3; */
        if (message.legalTextContent !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.legalTextContent);
        /* Mgmt.LocalizedStringVM localizedLegalButtonText = 4; */
        if (message.localizedLegalButtonText)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalButtonText, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedLegalMessageTitle = 5; */
        if (message.localizedLegalMessageTitle)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalMessageTitle, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedLegalTextContent = 6; */
        if (message.localizedLegalTextContent)
            LocalizedStringVM.internalBinaryWrite(message.localizedLegalTextContent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LegalCopyVM
 */
export const LegalCopyVM = new LegalCopyVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocaleVM$Type extends MessageType<LocaleVM> {
    constructor() {
        super("Mgmt.LocaleVM", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocaleVM>): LocaleVM {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocaleVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocaleVM): LocaleVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocaleVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocaleVM
 */
export const LocaleVM = new LocaleVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrencyVM$Type extends MessageType<CurrencyVM> {
    constructor() {
        super("Mgmt.CurrencyVM", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "divisor", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CurrencyVM>): CurrencyVM {
        const message = { code: "", divisor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrencyVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrencyVM): CurrencyVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int32 divisor */ 2:
                    message.divisor = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrencyVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int32 divisor = 2; */
        if (message.divisor !== 0)
            writer.tag(2, WireType.Varint).int32(message.divisor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CurrencyVM
 */
export const CurrencyVM = new CurrencyVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTypeConfigVM$Type extends MessageType<OrderTypeConfigVM> {
    constructor() {
        super("Mgmt.OrderTypeConfigVM", [
            { no: 1, name: "options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderTypeOptionVM },
            { no: 2, name: "tokenSettings", kind: "message", T: () => OrderTokenSettingsVM }
        ]);
    }
    create(value?: PartialMessage<OrderTypeConfigVM>): OrderTypeConfigVM {
        const message = { options: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderTypeConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTypeConfigVM): OrderTypeConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.OrderTypeOptionVM options */ 1:
                    message.options.push(OrderTypeOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Mgmt.OrderTokenSettingsVM tokenSettings */ 2:
                    message.tokenSettings = OrderTokenSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.tokenSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTypeConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.OrderTypeOptionVM options = 1; */
        for (let i = 0; i < message.options.length; i++)
            OrderTypeOptionVM.internalBinaryWrite(message.options[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.OrderTokenSettingsVM tokenSettings = 2; */
        if (message.tokenSettings)
            OrderTokenSettingsVM.internalBinaryWrite(message.tokenSettings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderTypeConfigVM
 */
export const OrderTypeConfigVM = new OrderTypeConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgeVerificationSettingsVM$Type extends MessageType<AgeVerificationSettingsVM> {
    constructor() {
        super("Mgmt.AgeVerificationSettingsVM", []);
    }
    create(value?: PartialMessage<AgeVerificationSettingsVM>): AgeVerificationSettingsVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgeVerificationSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgeVerificationSettingsVM): AgeVerificationSettingsVM {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AgeVerificationSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AgeVerificationSettingsVM
 */
export const AgeVerificationSettingsVM = new AgeVerificationSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoutSettingsVM$Type extends MessageType<TimeoutSettingsVM> {
    constructor() {
        super("Mgmt.TimeoutSettingsVM", [
            { no: 1, name: "idleTimeout", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "restartPromptTimer", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TimeoutSettingsVM>): TimeoutSettingsVM {
        const message = { idleTimeout: 0, restartPromptTimer: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeoutSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeoutSettingsVM): TimeoutSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 idleTimeout */ 1:
                    message.idleTimeout = reader.int32();
                    break;
                case /* int32 restartPromptTimer */ 2:
                    message.restartPromptTimer = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeoutSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 idleTimeout = 1; */
        if (message.idleTimeout !== 0)
            writer.tag(1, WireType.Varint).int32(message.idleTimeout);
        /* int32 restartPromptTimer = 2; */
        if (message.restartPromptTimer !== 0)
            writer.tag(2, WireType.Varint).int32(message.restartPromptTimer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TimeoutSettingsVM
 */
export const TimeoutSettingsVM = new TimeoutSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GratuityTendersVM$Type extends MessageType<GratuityTendersVM> {
    constructor() {
        super("Mgmt.GratuityTendersVM", [
            { no: 1, name: "gratuityTenders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GratuityTenderOptionVM }
        ]);
    }
    create(value?: PartialMessage<GratuityTendersVM>): GratuityTendersVM {
        const message = { gratuityTenders: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GratuityTendersVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GratuityTendersVM): GratuityTendersVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.GratuityTenderOptionVM gratuityTenders */ 1:
                    message.gratuityTenders.push(GratuityTenderOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GratuityTendersVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.GratuityTenderOptionVM gratuityTenders = 1; */
        for (let i = 0; i < message.gratuityTenders.length; i++)
            GratuityTenderOptionVM.internalBinaryWrite(message.gratuityTenders[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GratuityTendersVM
 */
export const GratuityTendersVM = new GratuityTendersVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GratuityTenderOptionVM$Type extends MessageType<GratuityTenderOptionVM> {
    constructor() {
        super("Mgmt.GratuityTenderOptionVM", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GratuityTenderOptionVM>): GratuityTenderOptionVM {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GratuityTenderOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GratuityTenderOptionVM): GratuityTenderOptionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GratuityTenderOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GratuityTenderOptionVM
 */
export const GratuityTenderOptionVM = new GratuityTenderOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipSettingsVM$Type extends MessageType<TipSettingsVM> {
    constructor() {
        super("Mgmt.TipSettingsVM", [
            { no: 1, name: "enableTips", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "showCalculatedTip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "customTipEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "tips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipVM },
            { no: 5, name: "tender", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipSettingsVM>): TipSettingsVM {
        const message = { enableTips: false, showCalculatedTip: false, customTipEnabled: false, tips: [], tender: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipSettingsVM): TipSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enableTips */ 1:
                    message.enableTips = reader.bool();
                    break;
                case /* bool showCalculatedTip */ 2:
                    message.showCalculatedTip = reader.bool();
                    break;
                case /* bool customTipEnabled */ 3:
                    message.customTipEnabled = reader.bool();
                    break;
                case /* repeated Mgmt.TipVM tips */ 4:
                    message.tips.push(TipVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string tender */ 5:
                    message.tender = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enableTips = 1; */
        if (message.enableTips !== false)
            writer.tag(1, WireType.Varint).bool(message.enableTips);
        /* bool showCalculatedTip = 2; */
        if (message.showCalculatedTip !== false)
            writer.tag(2, WireType.Varint).bool(message.showCalculatedTip);
        /* bool customTipEnabled = 3; */
        if (message.customTipEnabled !== false)
            writer.tag(3, WireType.Varint).bool(message.customTipEnabled);
        /* repeated Mgmt.TipVM tips = 4; */
        for (let i = 0; i < message.tips.length; i++)
            TipVM.internalBinaryWrite(message.tips[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string tender = 5; */
        if (message.tender !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tender);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TipSettingsVM
 */
export const TipSettingsVM = new TipSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipVM$Type extends MessageType<TipVM> {
    constructor() {
        super("Mgmt.TipVM", [
            { no: 1, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "isDefault", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TipVM>): TipVM {
        const message = { value: 0, isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipVM): TipVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 value */ 1:
                    message.value = reader.int32();
                    break;
                case /* bool isDefault */ 2:
                    message.isDefault = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 value = 1; */
        if (message.value !== 0)
            writer.tag(1, WireType.Varint).int32(message.value);
        /* bool isDefault = 2; */
        if (message.isDefault !== false)
            writer.tag(2, WireType.Varint).bool(message.isDefault);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TipVM
 */
export const TipVM = new TipVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTypeOptionVM$Type extends MessageType<OrderTypeOptionVM> {
    constructor() {
        super("Mgmt.OrderTypeOptionVM", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "externalDeliveryMode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "orderIdentity", kind: "message", T: () => OrderIdentityVM },
            { no: 5, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "localizedLabel", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<OrderTypeOptionVM>): OrderTypeOptionVM {
        const message = { id: "", label: "", externalDeliveryMode: "", enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderTypeOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTypeOptionVM): OrderTypeOptionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string externalDeliveryMode */ 3:
                    message.externalDeliveryMode = reader.string();
                    break;
                case /* Mgmt.OrderIdentityVM orderIdentity */ 4:
                    message.orderIdentity = OrderIdentityVM.internalBinaryRead(reader, reader.uint32(), options, message.orderIdentity);
                    break;
                case /* bool enabled */ 5:
                    message.enabled = reader.bool();
                    break;
                case /* Mgmt.LocalizedStringVM localizedLabel */ 6:
                    message.localizedLabel = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedLabel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTypeOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string externalDeliveryMode = 3; */
        if (message.externalDeliveryMode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalDeliveryMode);
        /* Mgmt.OrderIdentityVM orderIdentity = 4; */
        if (message.orderIdentity)
            OrderIdentityVM.internalBinaryWrite(message.orderIdentity, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool enabled = 5; */
        if (message.enabled !== false)
            writer.tag(5, WireType.Varint).bool(message.enabled);
        /* Mgmt.LocalizedStringVM localizedLabel = 6; */
        if (message.localizedLabel)
            LocalizedStringVM.internalBinaryWrite(message.localizedLabel, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderTypeOptionVM
 */
export const OrderTypeOptionVM = new OrderTypeOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderIdentityVM$Type extends MessageType<OrderIdentityVM> {
    constructor() {
        super("Mgmt.OrderIdentityVM", [
            { no: 1, name: "orderIdentityMode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "customerIdentityMode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "nameSettings", kind: "message", T: () => NameIdSettingsVM },
            { no: 6, name: "orderIdPrefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderIdentityVM>): OrderIdentityVM {
        const message = { orderIdentityMode: 0, customerIdentityMode: 0, orderIdPrefix: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderIdentityVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderIdentityVM): OrderIdentityVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 orderIdentityMode */ 1:
                    message.orderIdentityMode = reader.int32();
                    break;
                case /* int32 customerIdentityMode */ 2:
                    message.customerIdentityMode = reader.int32();
                    break;
                case /* Mgmt.NameIdSettingsVM nameSettings */ 5:
                    message.nameSettings = NameIdSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.nameSettings);
                    break;
                case /* string orderIdPrefix */ 6:
                    message.orderIdPrefix = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderIdentityVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 orderIdentityMode = 1; */
        if (message.orderIdentityMode !== 0)
            writer.tag(1, WireType.Varint).int32(message.orderIdentityMode);
        /* int32 customerIdentityMode = 2; */
        if (message.customerIdentityMode !== 0)
            writer.tag(2, WireType.Varint).int32(message.customerIdentityMode);
        /* Mgmt.NameIdSettingsVM nameSettings = 5; */
        if (message.nameSettings)
            NameIdSettingsVM.internalBinaryWrite(message.nameSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string orderIdPrefix = 6; */
        if (message.orderIdPrefix !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderIdPrefix);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderIdentityVM
 */
export const OrderIdentityVM = new OrderIdentityVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTokenSettingsVM$Type extends MessageType<OrderTokenSettingsVM> {
    constructor() {
        super("Mgmt.OrderTokenSettingsVM", [
            { no: 1, name: "orderNumberStart", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "allotment", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OrderTokenSettingsVM>): OrderTokenSettingsVM {
        const message = { orderNumberStart: 0, allotment: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderTokenSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTokenSettingsVM): OrderTokenSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 orderNumberStart */ 1:
                    message.orderNumberStart = reader.int32();
                    break;
                case /* int32 allotment */ 2:
                    message.allotment = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTokenSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 orderNumberStart = 1; */
        if (message.orderNumberStart !== 0)
            writer.tag(1, WireType.Varint).int32(message.orderNumberStart);
        /* int32 allotment = 2; */
        if (message.allotment !== 0)
            writer.tag(2, WireType.Varint).int32(message.allotment);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderTokenSettingsVM
 */
export const OrderTokenSettingsVM = new OrderTokenSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableTentIdSettingsVM$Type extends MessageType<TableTentIdSettingsVM> {
    constructor() {
        super("Mgmt.TableTentIdSettingsVM", [
            { no: 1, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TableTentIdSettingsVM>): TableTentIdSettingsVM {
        const message = { prefix: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TableTentIdSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableTentIdSettingsVM): TableTentIdSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prefix */ 1:
                    message.prefix = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableTentIdSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prefix = 1; */
        if (message.prefix !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prefix);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TableTentIdSettingsVM
 */
export const TableTentIdSettingsVM = new TableTentIdSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NameIdSettingsVM$Type extends MessageType<NameIdSettingsVM> {
    constructor() {
        super("Mgmt.NameIdSettingsVM", [
            { no: 1, name: "askBeforeOrder", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NameIdSettingsVM>): NameIdSettingsVM {
        const message = { askBeforeOrder: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NameIdSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NameIdSettingsVM): NameIdSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool askBeforeOrder */ 1:
                    message.askBeforeOrder = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NameIdSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool askBeforeOrder = 1; */
        if (message.askBeforeOrder !== false)
            writer.tag(1, WireType.Varint).bool(message.askBeforeOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.NameIdSettingsVM
 */
export const NameIdSettingsVM = new NameIdSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosOrderTypeVM$Type extends MessageType<PosOrderTypeVM> {
    constructor() {
        super("Mgmt.PosOrderTypeVM", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PosOrderTypeVM>): PosOrderTypeVM {
        const message = { id: "", label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosOrderTypeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosOrderTypeVM): PosOrderTypeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosOrderTypeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosOrderTypeVM
 */
export const PosOrderTypeVM = new PosOrderTypeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptVM$Type extends MessageType<ConceptVM> {
    constructor() {
        super("Mgmt.ConceptVM", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "menuLayoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "theme", kind: "message", T: () => ConceptThemeVM }
        ]);
    }
    create(value?: PartialMessage<ConceptVM>): ConceptVM {
        const message = { id: "", isActive: false, name: "", menuLayoutId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptVM): ConceptVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool isActive */ 2:
                    message.isActive = reader.bool();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string menuLayoutId */ 5:
                    message.menuLayoutId = reader.string();
                    break;
                case /* Mgmt.ConceptThemeVM theme */ 4:
                    message.theme = ConceptThemeVM.internalBinaryRead(reader, reader.uint32(), options, message.theme);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool isActive = 2; */
        if (message.isActive !== false)
            writer.tag(2, WireType.Varint).bool(message.isActive);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string menuLayoutId = 5; */
        if (message.menuLayoutId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.menuLayoutId);
        /* Mgmt.ConceptThemeVM theme = 4; */
        if (message.theme)
            ConceptThemeVM.internalBinaryWrite(message.theme, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptVM
 */
export const ConceptVM = new ConceptVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptThemeVM$Type extends MessageType<ConceptThemeVM> {
    constructor() {
        super("Mgmt.ConceptThemeVM", [
            { no: 1, name: "media", kind: "message", T: () => ConceptMediaVM },
            { no: 2, name: "colors", kind: "message", T: () => KioskColorsVM }
        ]);
    }
    create(value?: PartialMessage<ConceptThemeVM>): ConceptThemeVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptThemeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptThemeVM): ConceptThemeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.ConceptMediaVM media */ 1:
                    message.media = ConceptMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.media);
                    break;
                case /* Mgmt.KioskColorsVM colors */ 2:
                    message.colors = KioskColorsVM.internalBinaryRead(reader, reader.uint32(), options, message.colors);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptThemeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.ConceptMediaVM media = 1; */
        if (message.media)
            ConceptMediaVM.internalBinaryWrite(message.media, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskColorsVM colors = 2; */
        if (message.colors)
            KioskColorsVM.internalBinaryWrite(message.colors, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptThemeVM
 */
export const ConceptThemeVM = new ConceptThemeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptMediaVM$Type extends MessageType<ConceptMediaVM> {
    constructor() {
        super("Mgmt.ConceptMediaVM", [
            { no: 1, name: "categoryHeader", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConceptMediaVM>): ConceptMediaVM {
        const message = { categoryHeader: "", logo: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptMediaVM): ConceptMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string categoryHeader */ 1:
                    message.categoryHeader = reader.string();
                    break;
                case /* string logo */ 2:
                    message.logo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string categoryHeader = 1; */
        if (message.categoryHeader !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.categoryHeader);
        /* string logo = 2; */
        if (message.logo !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.logo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptMediaVM
 */
export const ConceptMediaVM = new ConceptMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptsResponse$Type extends MessageType<ConceptsResponse> {
    constructor() {
        super("Mgmt.ConceptsResponse", [
            { no: 1, name: "concepts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ConceptVM }
        ]);
    }
    create(value?: PartialMessage<ConceptsResponse>): ConceptsResponse {
        const message = { concepts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptsResponse): ConceptsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.ConceptVM concepts */ 1:
                    message.concepts.push(ConceptVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.ConceptVM concepts = 1; */
        for (let i = 0; i < message.concepts.length; i++)
            ConceptVM.internalBinaryWrite(message.concepts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptsResponse
 */
export const ConceptsResponse = new ConceptsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursMessageVM$Type extends MessageType<BusinessHoursMessageVM> {
    constructor() {
        super("Mgmt.BusinessHoursMessageVM", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "showScreensaver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursMessageVM>): BusinessHoursMessageVM {
        const message = { title: "", subtitle: "", message: "", showScreensaver: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursMessageVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursMessageVM): BusinessHoursMessageVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 2:
                    message.subtitle = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* bool showScreensaver */ 4:
                    message.showScreensaver = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursMessageVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 2; */
        if (message.subtitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subtitle);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* bool showScreensaver = 4; */
        if (message.showScreensaver !== false)
            writer.tag(4, WireType.Varint).bool(message.showScreensaver);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BusinessHoursMessageVM
 */
export const BusinessHoursMessageVM = new BusinessHoursMessageVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursConfigurationVM$Type extends MessageType<BusinessHoursConfigurationVM> {
    constructor() {
        super("Mgmt.BusinessHoursConfigurationVM", [
            { no: 1, name: "businessHours", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusinessHoursVM },
            { no: 2, name: "message", kind: "message", T: () => BusinessHoursMessageVM },
            { no: 3, name: "showMessage", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "posHoursEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursConfigurationVM>): BusinessHoursConfigurationVM {
        const message = { businessHours: [], showMessage: false, enabled: false, posHoursEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursConfigurationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursConfigurationVM): BusinessHoursConfigurationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.BusinessHoursVM businessHours */ 1:
                    message.businessHours.push(BusinessHoursVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Mgmt.BusinessHoursMessageVM message */ 2:
                    message.message = BusinessHoursMessageVM.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* bool showMessage */ 3:
                    message.showMessage = reader.bool();
                    break;
                case /* bool enabled */ 4:
                    message.enabled = reader.bool();
                    break;
                case /* bool posHoursEnabled */ 5:
                    message.posHoursEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursConfigurationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.BusinessHoursVM businessHours = 1; */
        for (let i = 0; i < message.businessHours.length; i++)
            BusinessHoursVM.internalBinaryWrite(message.businessHours[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.BusinessHoursMessageVM message = 2; */
        if (message.message)
            BusinessHoursMessageVM.internalBinaryWrite(message.message, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool showMessage = 3; */
        if (message.showMessage !== false)
            writer.tag(3, WireType.Varint).bool(message.showMessage);
        /* bool enabled = 4; */
        if (message.enabled !== false)
            writer.tag(4, WireType.Varint).bool(message.enabled);
        /* bool posHoursEnabled = 5; */
        if (message.posHoursEnabled !== false)
            writer.tag(5, WireType.Varint).bool(message.posHoursEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BusinessHoursConfigurationVM
 */
export const BusinessHoursConfigurationVM = new BusinessHoursConfigurationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursVM$Type extends MessageType<BusinessHoursVM> {
    constructor() {
        super("Mgmt.BusinessHoursVM", [
            { no: 1, name: "day", kind: "enum", T: () => ["Mgmt.DayOfWeekVM", DayOfWeekVM] },
            { no: 2, name: "hours", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HourRangeVM }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursVM>): BusinessHoursVM {
        const message = { day: 0, hours: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursVM): BusinessHoursVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.DayOfWeekVM day */ 1:
                    message.day = reader.int32();
                    break;
                case /* repeated Mgmt.HourRangeVM hours */ 2:
                    message.hours.push(HourRangeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.DayOfWeekVM day = 1; */
        if (message.day !== 0)
            writer.tag(1, WireType.Varint).int32(message.day);
        /* repeated Mgmt.HourRangeVM hours = 2; */
        for (let i = 0; i < message.hours.length; i++)
            HourRangeVM.internalBinaryWrite(message.hours[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BusinessHoursVM
 */
export const BusinessHoursVM = new BusinessHoursVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HourRangeVM$Type extends MessageType<HourRangeVM> {
    constructor() {
        super("Mgmt.HourRangeVM", [
            { no: 1, name: "startMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "endMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<HourRangeVM>): HourRangeVM {
        const message = { startMinutes: 0, endMinutes: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HourRangeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HourRangeVM): HourRangeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 startMinutes */ 1:
                    message.startMinutes = reader.int32();
                    break;
                case /* int32 endMinutes */ 2:
                    message.endMinutes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HourRangeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 startMinutes = 1; */
        if (message.startMinutes !== 0)
            writer.tag(1, WireType.Varint).int32(message.startMinutes);
        /* int32 endMinutes = 2; */
        if (message.endMinutes !== 0)
            writer.tag(2, WireType.Varint).int32(message.endMinutes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.HourRangeVM
 */
export const HourRangeVM = new HourRangeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncInterval$Type extends MessageType<SyncInterval> {
    constructor() {
        super("Mgmt.SyncInterval", [
            { no: 2, name: "startTime", kind: "message", T: () => Timestamp },
            { no: 3, name: "endTime", kind: "message", T: () => Timestamp },
            { no: 4, name: "intervalMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SyncInterval>): SyncInterval {
        const message = { intervalMinutes: 0, enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SyncInterval>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncInterval): SyncInterval {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp startTime */ 2:
                    message.startTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startTime);
                    break;
                case /* google.protobuf.Timestamp endTime */ 3:
                    message.endTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endTime);
                    break;
                case /* int32 intervalMinutes */ 4:
                    message.intervalMinutes = reader.int32();
                    break;
                case /* bool enabled */ 5:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncInterval, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp startTime = 2; */
        if (message.startTime)
            Timestamp.internalBinaryWrite(message.startTime, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp endTime = 3; */
        if (message.endTime)
            Timestamp.internalBinaryWrite(message.endTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 intervalMinutes = 4; */
        if (message.intervalMinutes !== 0)
            writer.tag(4, WireType.Varint).int32(message.intervalMinutes);
        /* bool enabled = 5; */
        if (message.enabled !== false)
            writer.tag(5, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.SyncInterval
 */
export const SyncInterval = new SyncInterval$Type();
