/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "appearance.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PhoneNumberVM } from "./common_pb";
import { AddressVM } from "./common_pb";
import { LocalizedStringVM } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.AppearanceVM
 */
export interface AppearanceVM {
    /**
     * @generated from protobuf field: Mgmt.KioskColorsVM colors = 1;
     */
    colors?: KioskColorsVM;
    /**
     * @generated from protobuf field: Mgmt.TemplateConfigVM template = 2;
     */
    template?: TemplateConfigVM;
    /**
     * @generated from protobuf field: Mgmt.ReceiptSettingsVM receipts = 3;
     */
    receipts?: ReceiptSettingsVM;
    /**
     * @generated from protobuf field: Mgmt.MenuColorsVM menuColors = 4;
     */
    menuColors?: MenuColorsVM;
    /**
     * @generated from protobuf field: Mgmt.KioskStyleOptionsVM kioskStyleOptions = 5;
     */
    kioskStyleOptions?: KioskStyleOptionsVM;
    /**
     * @generated from protobuf field: Mgmt.FontMediaVM fonts = 6;
     */
    fonts?: FontMediaVM;
}
/**
 * @generated from protobuf message Mgmt.KioskColorsVM
 */
export interface KioskColorsVM {
    /**
     * @generated from protobuf field: string primary = 1;
     */
    primary: string;
    /**
     * @generated from protobuf field: string onPrimary = 2;
     */
    onPrimary: string;
    /**
     * @generated from protobuf field: string secondary = 3;
     */
    secondary: string;
    /**
     * @generated from protobuf field: string onSecondary = 4;
     */
    onSecondary: string;
    /**
     * @generated from protobuf field: string tertiary = 5;
     */
    tertiary: string;
    /**
     * @generated from protobuf field: string onTertiary = 6;
     */
    onTertiary: string;
    /**
     * @generated from protobuf field: string surface = 7;
     */
    surface: string;
    /**
     * @generated from protobuf field: string onSurface = 8;
     */
    onSurface: string;
    /**
     * @generated from protobuf field: string background = 9;
     */
    background: string;
    /**
     * @generated from protobuf field: string onBackground = 10;
     */
    onBackground: string;
    /**
     * @generated from protobuf field: string error = 11;
     */
    error: string;
    /**
     * @generated from protobuf field: string onError = 12;
     */
    onError: string;
}
/**
 * @generated from protobuf message Mgmt.KioskStyleOptionsVM
 */
export interface KioskStyleOptionsVM {
    /**
     * @generated from protobuf field: Mgmt.KioskStyleOptionsVM.ButtonStyle buttonStyle = 1;
     */
    buttonStyle: KioskStyleOptionsVM_ButtonStyle;
    /**
     * @generated from protobuf field: Mgmt.KioskStyleOptionsVM.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode = 2;
     */
    categoryHeaderDisplayMode: KioskStyleOptionsVM_CategoryHeaderDisplayModeStyle;
    /**
     * @generated from protobuf field: bool showCategoryHighlightedColor = 3;
     */
    showCategoryHighlightedColor: boolean;
    /**
     * @generated from protobuf field: Mgmt.KioskStyleOptionsVM.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode = 4;
     */
    categoryHeaderLogoDisplayMode: KioskStyleOptionsVM_CategoryHeaderLogoDisplayMode;
    /**
     * @generated from protobuf field: bool displayModifierGroupRestrictions = 5;
     */
    displayModifierGroupRestrictions: boolean;
    /**
     * @generated from protobuf field: bool allowUserToCollapseOrExpandModifierGroups = 6;
     */
    allowUserToCollapseOrExpandModifierGroups: boolean;
    /**
     * @generated from protobuf field: bool showDiscountCodeButton = 7;
     */
    showDiscountCodeButton: boolean;
    /**
     * @generated from protobuf field: bool orderReviewShowDefaultModifiers = 8;
     */
    orderReviewShowDefaultModifiers: boolean;
    /**
     * @generated from protobuf field: bool showModifierGroupNamesOrderReview = 9;
     */
    showModifierGroupNamesOrderReview: boolean;
    /**
     * @generated from protobuf field: bool showLoyaltyPointsOnHeader = 10;
     */
    showLoyaltyPointsOnHeader: boolean;
    /**
     * @generated from protobuf field: bool showAcceptedPaymentOptionsOnScreenSaver = 11;
     */
    showAcceptedPaymentOptionsOnScreenSaver: boolean;
    /**
     * @generated from protobuf field: Mgmt.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions = 12;
     */
    kioskStyleAcceptedPaymentOptions?: KioskStyleAcceptedPaymentOptionsVM;
}
/**
 * @generated from protobuf enum Mgmt.KioskStyleOptionsVM.ButtonStyle
 */
export enum KioskStyleOptionsVM_ButtonStyle {
    /**
     * @generated from protobuf enum value: Rounded = 0;
     */
    Rounded = 0,
    /**
     * @generated from protobuf enum value: Rectangle = 1;
     */
    Rectangle = 1
}
/**
 * @generated from protobuf enum Mgmt.KioskStyleOptionsVM.CategoryHeaderDisplayModeStyle
 */
export enum KioskStyleOptionsVM_CategoryHeaderDisplayModeStyle {
    /**
     * @generated from protobuf enum value: NameBelowHeader = 0;
     */
    NameBelowHeader = 0,
    /**
     * @generated from protobuf enum value: NameInHeader = 1;
     */
    NameInHeader = 1
}
/**
 * @generated from protobuf enum Mgmt.KioskStyleOptionsVM.CategoryHeaderLogoDisplayMode
 */
export enum KioskStyleOptionsVM_CategoryHeaderLogoDisplayMode {
    /**
     * @generated from protobuf enum value: Show = 0;
     */
    Show = 0,
    /**
     * @generated from protobuf enum value: NotShow = 1;
     */
    NotShow = 1
}
/**
 * @generated from protobuf message Mgmt.KioskStyleAcceptedPaymentOptionsVM
 */
export interface KioskStyleAcceptedPaymentOptionsVM {
    /**
     * @generated from protobuf field: bool showCard = 1;
     */
    showCard: boolean;
    /**
     * @generated from protobuf field: bool showGooglePay = 2;
     */
    showGooglePay: boolean;
    /**
     * @generated from protobuf field: bool showApplePay = 3;
     */
    showApplePay: boolean;
    /**
     * @generated from protobuf field: bool showCash = 4;
     */
    showCash: boolean;
    /**
     * @generated from protobuf field: string acceptedPaymentText = 5;
     */
    acceptedPaymentText: string;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedAcceptedPaymentText = 6;
     */
    localizedAcceptedPaymentText?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Mgmt.FontMediaVM
 */
export interface FontMediaVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM kioskFont = 1;
     */
    kioskFont?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.MenuColorsVM
 */
export interface MenuColorsVM {
    /**
     * @generated from protobuf field: string modifierGroupPositive = 1;
     */
    modifierGroupPositive: string;
    /**
     * @generated from protobuf field: string modifierGroupNegative = 2;
     */
    modifierGroupNegative: string;
    /**
     * @generated from protobuf field: string itemHeaderBackground = 3;
     */
    itemHeaderBackground: string;
    /**
     * @generated from protobuf field: string itemHeaderText = 4;
     */
    itemHeaderText: string;
    /**
     * @generated from protobuf field: string checkoutHeaderBackground = 5;
     */
    checkoutHeaderBackground: string;
    /**
     * @generated from protobuf field: string checkoutHeaderText = 6;
     */
    checkoutHeaderText: string;
    /**
     * @generated from protobuf field: string logoBackground = 7;
     */
    logoBackground: string;
    /**
     * @generated from protobuf field: string cartPreviewBorderColor = 8;
     */
    cartPreviewBorderColor: string;
    /**
     * @generated from protobuf field: string cartPreviewFillColor = 9;
     */
    cartPreviewFillColor: string;
    /**
     * @generated from protobuf field: string categoryHeaderBackgroundColor = 10;
     */
    categoryHeaderBackgroundColor: string;
    /**
     * @generated from protobuf field: string categoryHeaderTextColor = 11;
     */
    categoryHeaderTextColor: string;
}
/**
 * @generated from protobuf message Mgmt.LoyaltyColorsVM
 */
export interface LoyaltyColorsVM {
    /**
     * @generated from protobuf field: string onLoyaltyAccent = 1;
     */
    onLoyaltyAccent: string;
    /**
     * @generated from protobuf field: string loyaltyAccent = 2;
     */
    loyaltyAccent: string;
    /**
     * @generated from protobuf field: string loyalty = 3;
     */
    loyalty: string;
    /**
     * @generated from protobuf field: string onLoyalty = 4;
     */
    onLoyalty: string;
    /**
     * @generated from protobuf field: string loyaltyBackground = 5;
     */
    loyaltyBackground: string;
}
/**
 * @generated from protobuf message Mgmt.TemplateConfigVM
 */
export interface TemplateConfigVM {
    /**
     * @generated from protobuf field: string templateId = 1;
     */
    templateId: string;
    /**
     * @generated from protobuf field: Mgmt.TemplateSettingsVM settings = 2;
     */
    settings?: TemplateSettingsVM;
    /**
     * @generated from protobuf field: Mgmt.ScreensaversVM screensavers = 3;
     */
    screensavers?: ScreensaversVM;
    /**
     * @generated from protobuf field: Mgmt.TemplateTextVM text = 4;
     */
    text?: TemplateTextVM;
    /**
     * @generated from protobuf field: Mgmt.TemplateImagesVM images = 5;
     */
    images?: TemplateImagesVM;
    /**
     * @generated from protobuf field: Mgmt.AnimationsVM animations = 6;
     */
    animations?: AnimationsVM;
}
/**
 * @generated from protobuf message Mgmt.TemplateSettingsVM
 */
export interface TemplateSettingsVM {
    /**
     * @generated from protobuf field: map<string, string> options = 1;
     */
    options: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Mgmt.TemplateTextVM
 */
export interface TemplateTextVM {
    /**
     * @generated from protobuf field: repeated Mgmt.TemplateLanguageTextVM default = 1;
     */
    default: TemplateLanguageTextVM[];
    /**
     * @generated from protobuf field: repeated Mgmt.TemplateLanguageTextVM overrides = 2;
     */
    overrides: TemplateLanguageTextVM[];
}
/**
 * @generated from protobuf message Mgmt.TemplateLanguageTextVM
 */
export interface TemplateLanguageTextVM {
    /**
     * @generated from protobuf field: string languageCode = 1;
     */
    languageCode: string;
    /**
     * @generated from protobuf field: map<string, string> strings = 2;
     */
    strings: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Mgmt.TemplateImagesVM
 */
export interface TemplateImagesVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultCategoryHeaderImage = 1;
     */
    defaultCategoryHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @deprecated
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultLoyaltyRewardImage = 2 [deprecated = true];
     */
    defaultLoyaltyRewardImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.LoyaltyMediaVM defaultLoyaltyMedia = 3;
     */
    defaultLoyaltyMedia?: LoyaltyMediaVM;
    /**
     * @generated from protobuf field: Mgmt.DiscountMediaVM discountMedia = 4;
     */
    discountMedia?: DiscountMediaVM;
    /**
     * @generated from protobuf field: Mgmt.OrderCheckoutMediaVM orderCheckoutMedia = 5;
     */
    orderCheckoutMedia?: OrderCheckoutMediaVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM headerLogoImage = 6;
     */
    headerLogoImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM itemDetailHeaderBackgroundImage = 7;
     */
    itemDetailHeaderBackgroundImage?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.AnimationsVM
 */
export interface AnimationsVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM loading = 1;
     */
    loading?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM orderTimeout = 2;
     */
    orderTimeout?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM scanQRCode = 3;
     */
    scanQRCode?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM calculatingOrder = 4;
     */
    calculatingOrder?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM payWithCard = 5;
     */
    payWithCard?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM paymentSuccess = 6;
     */
    paymentSuccess?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM paymentFail = 7;
     */
    paymentFail?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM storeIsClosed = 8;
     */
    storeIsClosed?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.LoyaltyMediaVM
 */
export interface LoyaltyMediaVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultProfileHeaderImage = 1;
     */
    defaultProfileHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultTopCornerImage = 7;
     */
    defaultTopCornerImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultRewardsBackgroundImage = 2;
     */
    defaultRewardsBackgroundImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultRewardImage = 6;
     */
    defaultRewardImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultProfileFooterImage = 3;
     */
    defaultProfileFooterImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultCheckoutHeaderImage = 8;
     */
    defaultCheckoutHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultSignInHeaderImage = 9;
     */
    defaultSignInHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultLoyaltyPerksImage = 10;
     */
    defaultLoyaltyPerksImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultLoyaltyInstructionsImage = 11;
     */
    defaultLoyaltyInstructionsImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM loyaltySignInButtonImage = 12;
     */
    loyaltySignInButtonImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM loyaltyWithPointsHeaderImage = 13;
     */
    loyaltyWithPointsHeaderImage?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.DiscountMediaVM
 */
export interface DiscountMediaVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM defaultDiscountImage = 1;
     */
    defaultDiscountImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM rewardHeaderImage = 2;
     */
    rewardHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM discountHeaderImage = 3;
     */
    discountHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM discountAndRewardHeaderImage = 4;
     */
    discountAndRewardHeaderImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM discountCodeInMenuScreenButtonImage = 5;
     */
    discountCodeInMenuScreenButtonImage?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM discountCodeOrderReviewScreenButtonImage = 6;
     */
    discountCodeOrderReviewScreenButtonImage?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.OrderCheckoutMediaVM
 */
export interface OrderCheckoutMediaVM {
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM confirmationHeaderImage = 1;
     */
    confirmationHeaderImage?: AppearanceMediaAssetVM;
}
/**
 * @generated from protobuf message Mgmt.ScreensaversVM
 */
export interface ScreensaversVM {
    /**
     * @generated from protobuf field: repeated Mgmt.AppearanceMediaAssetVM files = 1;
     */
    files: AppearanceMediaAssetVM[];
}
/**
 * @generated from protobuf message Mgmt.AppearanceMediaAssetVM
 */
export interface AppearanceMediaAssetVM {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string displayUrl = 2;
     */
    displayUrl: string;
    /**
     * @generated from protobuf field: string storagePath = 3;
     */
    storagePath: string;
}
/**
 * @generated from protobuf message Mgmt.ReceiptSettingsVM
 */
export interface ReceiptSettingsVM {
    /**
     * @generated from protobuf field: string headerText = 1;
     */
    headerText: string;
    /**
     * @generated from protobuf field: string footerText = 2;
     */
    footerText: string;
    /**
     * @generated from protobuf field: Mgmt.AppearanceMediaAssetVM logo = 3;
     */
    logo?: AppearanceMediaAssetVM;
    /**
     * @generated from protobuf field: Mgmt.AddressVM receiptAddress = 4;
     */
    receiptAddress?: AddressVM;
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM phone = 5;
     */
    phone?: PhoneNumberVM;
    /**
     * @generated from protobuf field: bool autoPrint = 6;
     */
    autoPrint: boolean;
    /**
     * @generated from protobuf field: optional string emailFrom = 7;
     */
    emailFrom?: string;
    /**
     * @generated from protobuf field: optional string emailFromName = 8;
     */
    emailFromName?: string;
    /**
     * @generated from protobuf field: optional bool enableEmailReceipt = 9;
     */
    enableEmailReceipt?: boolean;
    /**
     * @generated from protobuf field: optional string templateId = 10;
     */
    templateId?: string;
    /**
     * @generated from protobuf field: optional bool enableSmsReceipt = 11;
     */
    enableSmsReceipt?: boolean;
    /**
     * @generated from protobuf field: optional string smsApiKey = 12;
     */
    smsApiKey?: string;
    /**
     * @generated from protobuf field: optional string smsMessageFormat = 13;
     */
    smsMessageFormat?: string;
    /**
     * @generated from protobuf field: optional string emailSubject = 14;
     */
    emailSubject?: string;
    /**
     * @generated from protobuf field: Mgmt.ReceiptVisibilityOptions receiptVisibilityOptions = 15;
     */
    receiptVisibilityOptions?: ReceiptVisibilityOptions;
    /**
     * @generated from protobuf field: bool showQrCodeForReceiptUrl = 16;
     */
    showQrCodeForReceiptUrl: boolean;
    /**
     * @generated from protobuf field: optional string generatingQrCodeMsg = 17;
     */
    generatingQrCodeMsg?: string;
    /**
     * @generated from protobuf field: optional string qrReceiptTitle = 18;
     */
    qrReceiptTitle?: string;
    /**
     * @generated from protobuf field: optional string qrReceiptMsg = 19;
     */
    qrReceiptMsg?: string;
    /**
     * @generated from protobuf field: bool showQrCodeWhenPrintReceiptFails = 20;
     */
    showQrCodeWhenPrintReceiptFails: boolean;
    /**
     * @generated from protobuf field: optional string qrReceiptPrinterFailedMsg = 21;
     */
    qrReceiptPrinterFailedMsg?: string;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedHeaderText = 22;
     */
    localizedHeaderText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedFooterText = 23;
     */
    localizedFooterText?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedGeneratingQrCodeMsg = 24;
     */
    localizedGeneratingQrCodeMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedQrReceiptTitle = 25;
     */
    localizedQrReceiptTitle?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedQrReceiptMsg = 26;
     */
    localizedQrReceiptMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedQrReceiptPrinterFailedMsg = 27;
     */
    localizedQrReceiptPrinterFailedMsg?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedEmailSubject = 28;
     */
    localizedEmailSubject?: LocalizedStringVM;
    /**
     * @generated from protobuf field: Mgmt.LocalizedStringVM localizedMessageFormat = 29;
     */
    localizedMessageFormat?: LocalizedStringVM;
}
/**
 * @generated from protobuf message Mgmt.ReceiptVisibilityOptions
 */
export interface ReceiptVisibilityOptions {
    /**
     * @generated from protobuf field: bool modifierGroupNames = 1;
     */
    modifierGroupNames: boolean;
    /**
     * @generated from protobuf field: bool defaultModifiers = 2;
     */
    defaultModifiers: boolean;
    /**
     * @generated from protobuf field: bool freeModifiers = 3;
     */
    freeModifiers: boolean;
    /**
     * @generated from protobuf field: bool pricedModifiers = 4;
     */
    pricedModifiers: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AppearanceVM$Type extends MessageType<AppearanceVM> {
    constructor() {
        super("Mgmt.AppearanceVM", [
            { no: 1, name: "colors", kind: "message", T: () => KioskColorsVM },
            { no: 2, name: "template", kind: "message", T: () => TemplateConfigVM },
            { no: 3, name: "receipts", kind: "message", T: () => ReceiptSettingsVM },
            { no: 4, name: "menuColors", kind: "message", T: () => MenuColorsVM },
            { no: 5, name: "kioskStyleOptions", kind: "message", T: () => KioskStyleOptionsVM },
            { no: 6, name: "fonts", kind: "message", T: () => FontMediaVM }
        ]);
    }
    create(value?: PartialMessage<AppearanceVM>): AppearanceVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppearanceVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppearanceVM): AppearanceVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.KioskColorsVM colors */ 1:
                    message.colors = KioskColorsVM.internalBinaryRead(reader, reader.uint32(), options, message.colors);
                    break;
                case /* Mgmt.TemplateConfigVM template */ 2:
                    message.template = TemplateConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.template);
                    break;
                case /* Mgmt.ReceiptSettingsVM receipts */ 3:
                    message.receipts = ReceiptSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.receipts);
                    break;
                case /* Mgmt.MenuColorsVM menuColors */ 4:
                    message.menuColors = MenuColorsVM.internalBinaryRead(reader, reader.uint32(), options, message.menuColors);
                    break;
                case /* Mgmt.KioskStyleOptionsVM kioskStyleOptions */ 5:
                    message.kioskStyleOptions = KioskStyleOptionsVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskStyleOptions);
                    break;
                case /* Mgmt.FontMediaVM fonts */ 6:
                    message.fonts = FontMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.fonts);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppearanceVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.KioskColorsVM colors = 1; */
        if (message.colors)
            KioskColorsVM.internalBinaryWrite(message.colors, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.TemplateConfigVM template = 2; */
        if (message.template)
            TemplateConfigVM.internalBinaryWrite(message.template, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.ReceiptSettingsVM receipts = 3; */
        if (message.receipts)
            ReceiptSettingsVM.internalBinaryWrite(message.receipts, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.MenuColorsVM menuColors = 4; */
        if (message.menuColors)
            MenuColorsVM.internalBinaryWrite(message.menuColors, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskStyleOptionsVM kioskStyleOptions = 5; */
        if (message.kioskStyleOptions)
            KioskStyleOptionsVM.internalBinaryWrite(message.kioskStyleOptions, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.FontMediaVM fonts = 6; */
        if (message.fonts)
            FontMediaVM.internalBinaryWrite(message.fonts, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AppearanceVM
 */
export const AppearanceVM = new AppearanceVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskColorsVM$Type extends MessageType<KioskColorsVM> {
    constructor() {
        super("Mgmt.KioskColorsVM", [
            { no: 1, name: "primary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "onPrimary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "secondary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "onSecondary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tertiary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "onTertiary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "surface", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "onSurface", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "background", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "onBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "onError", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KioskColorsVM>): KioskColorsVM {
        const message = { primary: "", onPrimary: "", secondary: "", onSecondary: "", tertiary: "", onTertiary: "", surface: "", onSurface: "", background: "", onBackground: "", error: "", onError: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskColorsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskColorsVM): KioskColorsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string primary */ 1:
                    message.primary = reader.string();
                    break;
                case /* string onPrimary */ 2:
                    message.onPrimary = reader.string();
                    break;
                case /* string secondary */ 3:
                    message.secondary = reader.string();
                    break;
                case /* string onSecondary */ 4:
                    message.onSecondary = reader.string();
                    break;
                case /* string tertiary */ 5:
                    message.tertiary = reader.string();
                    break;
                case /* string onTertiary */ 6:
                    message.onTertiary = reader.string();
                    break;
                case /* string surface */ 7:
                    message.surface = reader.string();
                    break;
                case /* string onSurface */ 8:
                    message.onSurface = reader.string();
                    break;
                case /* string background */ 9:
                    message.background = reader.string();
                    break;
                case /* string onBackground */ 10:
                    message.onBackground = reader.string();
                    break;
                case /* string error */ 11:
                    message.error = reader.string();
                    break;
                case /* string onError */ 12:
                    message.onError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskColorsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string primary = 1; */
        if (message.primary !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.primary);
        /* string onPrimary = 2; */
        if (message.onPrimary !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.onPrimary);
        /* string secondary = 3; */
        if (message.secondary !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.secondary);
        /* string onSecondary = 4; */
        if (message.onSecondary !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.onSecondary);
        /* string tertiary = 5; */
        if (message.tertiary !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tertiary);
        /* string onTertiary = 6; */
        if (message.onTertiary !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.onTertiary);
        /* string surface = 7; */
        if (message.surface !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.surface);
        /* string onSurface = 8; */
        if (message.onSurface !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.onSurface);
        /* string background = 9; */
        if (message.background !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.background);
        /* string onBackground = 10; */
        if (message.onBackground !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.onBackground);
        /* string error = 11; */
        if (message.error !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.error);
        /* string onError = 12; */
        if (message.onError !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.onError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskColorsVM
 */
export const KioskColorsVM = new KioskColorsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskStyleOptionsVM$Type extends MessageType<KioskStyleOptionsVM> {
    constructor() {
        super("Mgmt.KioskStyleOptionsVM", [
            { no: 1, name: "buttonStyle", kind: "enum", T: () => ["Mgmt.KioskStyleOptionsVM.ButtonStyle", KioskStyleOptionsVM_ButtonStyle] },
            { no: 2, name: "categoryHeaderDisplayMode", kind: "enum", T: () => ["Mgmt.KioskStyleOptionsVM.CategoryHeaderDisplayModeStyle", KioskStyleOptionsVM_CategoryHeaderDisplayModeStyle] },
            { no: 3, name: "showCategoryHighlightedColor", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "categoryHeaderLogoDisplayMode", kind: "enum", T: () => ["Mgmt.KioskStyleOptionsVM.CategoryHeaderLogoDisplayMode", KioskStyleOptionsVM_CategoryHeaderLogoDisplayMode] },
            { no: 5, name: "displayModifierGroupRestrictions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "allowUserToCollapseOrExpandModifierGroups", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "showDiscountCodeButton", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "orderReviewShowDefaultModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "showModifierGroupNamesOrderReview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "showLoyaltyPointsOnHeader", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "showAcceptedPaymentOptionsOnScreenSaver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "kioskStyleAcceptedPaymentOptions", kind: "message", T: () => KioskStyleAcceptedPaymentOptionsVM }
        ]);
    }
    create(value?: PartialMessage<KioskStyleOptionsVM>): KioskStyleOptionsVM {
        const message = { buttonStyle: 0, categoryHeaderDisplayMode: 0, showCategoryHighlightedColor: false, categoryHeaderLogoDisplayMode: 0, displayModifierGroupRestrictions: false, allowUserToCollapseOrExpandModifierGroups: false, showDiscountCodeButton: false, orderReviewShowDefaultModifiers: false, showModifierGroupNamesOrderReview: false, showLoyaltyPointsOnHeader: false, showAcceptedPaymentOptionsOnScreenSaver: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskStyleOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskStyleOptionsVM): KioskStyleOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.KioskStyleOptionsVM.ButtonStyle buttonStyle */ 1:
                    message.buttonStyle = reader.int32();
                    break;
                case /* Mgmt.KioskStyleOptionsVM.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode */ 2:
                    message.categoryHeaderDisplayMode = reader.int32();
                    break;
                case /* bool showCategoryHighlightedColor */ 3:
                    message.showCategoryHighlightedColor = reader.bool();
                    break;
                case /* Mgmt.KioskStyleOptionsVM.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode */ 4:
                    message.categoryHeaderLogoDisplayMode = reader.int32();
                    break;
                case /* bool displayModifierGroupRestrictions */ 5:
                    message.displayModifierGroupRestrictions = reader.bool();
                    break;
                case /* bool allowUserToCollapseOrExpandModifierGroups */ 6:
                    message.allowUserToCollapseOrExpandModifierGroups = reader.bool();
                    break;
                case /* bool showDiscountCodeButton */ 7:
                    message.showDiscountCodeButton = reader.bool();
                    break;
                case /* bool orderReviewShowDefaultModifiers */ 8:
                    message.orderReviewShowDefaultModifiers = reader.bool();
                    break;
                case /* bool showModifierGroupNamesOrderReview */ 9:
                    message.showModifierGroupNamesOrderReview = reader.bool();
                    break;
                case /* bool showLoyaltyPointsOnHeader */ 10:
                    message.showLoyaltyPointsOnHeader = reader.bool();
                    break;
                case /* bool showAcceptedPaymentOptionsOnScreenSaver */ 11:
                    message.showAcceptedPaymentOptionsOnScreenSaver = reader.bool();
                    break;
                case /* Mgmt.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions */ 12:
                    message.kioskStyleAcceptedPaymentOptions = KioskStyleAcceptedPaymentOptionsVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskStyleAcceptedPaymentOptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskStyleOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.KioskStyleOptionsVM.ButtonStyle buttonStyle = 1; */
        if (message.buttonStyle !== 0)
            writer.tag(1, WireType.Varint).int32(message.buttonStyle);
        /* Mgmt.KioskStyleOptionsVM.CategoryHeaderDisplayModeStyle categoryHeaderDisplayMode = 2; */
        if (message.categoryHeaderDisplayMode !== 0)
            writer.tag(2, WireType.Varint).int32(message.categoryHeaderDisplayMode);
        /* bool showCategoryHighlightedColor = 3; */
        if (message.showCategoryHighlightedColor !== false)
            writer.tag(3, WireType.Varint).bool(message.showCategoryHighlightedColor);
        /* Mgmt.KioskStyleOptionsVM.CategoryHeaderLogoDisplayMode categoryHeaderLogoDisplayMode = 4; */
        if (message.categoryHeaderLogoDisplayMode !== 0)
            writer.tag(4, WireType.Varint).int32(message.categoryHeaderLogoDisplayMode);
        /* bool displayModifierGroupRestrictions = 5; */
        if (message.displayModifierGroupRestrictions !== false)
            writer.tag(5, WireType.Varint).bool(message.displayModifierGroupRestrictions);
        /* bool allowUserToCollapseOrExpandModifierGroups = 6; */
        if (message.allowUserToCollapseOrExpandModifierGroups !== false)
            writer.tag(6, WireType.Varint).bool(message.allowUserToCollapseOrExpandModifierGroups);
        /* bool showDiscountCodeButton = 7; */
        if (message.showDiscountCodeButton !== false)
            writer.tag(7, WireType.Varint).bool(message.showDiscountCodeButton);
        /* bool orderReviewShowDefaultModifiers = 8; */
        if (message.orderReviewShowDefaultModifiers !== false)
            writer.tag(8, WireType.Varint).bool(message.orderReviewShowDefaultModifiers);
        /* bool showModifierGroupNamesOrderReview = 9; */
        if (message.showModifierGroupNamesOrderReview !== false)
            writer.tag(9, WireType.Varint).bool(message.showModifierGroupNamesOrderReview);
        /* bool showLoyaltyPointsOnHeader = 10; */
        if (message.showLoyaltyPointsOnHeader !== false)
            writer.tag(10, WireType.Varint).bool(message.showLoyaltyPointsOnHeader);
        /* bool showAcceptedPaymentOptionsOnScreenSaver = 11; */
        if (message.showAcceptedPaymentOptionsOnScreenSaver !== false)
            writer.tag(11, WireType.Varint).bool(message.showAcceptedPaymentOptionsOnScreenSaver);
        /* Mgmt.KioskStyleAcceptedPaymentOptionsVM kioskStyleAcceptedPaymentOptions = 12; */
        if (message.kioskStyleAcceptedPaymentOptions)
            KioskStyleAcceptedPaymentOptionsVM.internalBinaryWrite(message.kioskStyleAcceptedPaymentOptions, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskStyleOptionsVM
 */
export const KioskStyleOptionsVM = new KioskStyleOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskStyleAcceptedPaymentOptionsVM$Type extends MessageType<KioskStyleAcceptedPaymentOptionsVM> {
    constructor() {
        super("Mgmt.KioskStyleAcceptedPaymentOptionsVM", [
            { no: 1, name: "showCard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "showGooglePay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "showApplePay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "showCash", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "acceptedPaymentText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "localizedAcceptedPaymentText", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<KioskStyleAcceptedPaymentOptionsVM>): KioskStyleAcceptedPaymentOptionsVM {
        const message = { showCard: false, showGooglePay: false, showApplePay: false, showCash: false, acceptedPaymentText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskStyleAcceptedPaymentOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskStyleAcceptedPaymentOptionsVM): KioskStyleAcceptedPaymentOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool showCard */ 1:
                    message.showCard = reader.bool();
                    break;
                case /* bool showGooglePay */ 2:
                    message.showGooglePay = reader.bool();
                    break;
                case /* bool showApplePay */ 3:
                    message.showApplePay = reader.bool();
                    break;
                case /* bool showCash */ 4:
                    message.showCash = reader.bool();
                    break;
                case /* string acceptedPaymentText */ 5:
                    message.acceptedPaymentText = reader.string();
                    break;
                case /* Mgmt.LocalizedStringVM localizedAcceptedPaymentText */ 6:
                    message.localizedAcceptedPaymentText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedAcceptedPaymentText);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskStyleAcceptedPaymentOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool showCard = 1; */
        if (message.showCard !== false)
            writer.tag(1, WireType.Varint).bool(message.showCard);
        /* bool showGooglePay = 2; */
        if (message.showGooglePay !== false)
            writer.tag(2, WireType.Varint).bool(message.showGooglePay);
        /* bool showApplePay = 3; */
        if (message.showApplePay !== false)
            writer.tag(3, WireType.Varint).bool(message.showApplePay);
        /* bool showCash = 4; */
        if (message.showCash !== false)
            writer.tag(4, WireType.Varint).bool(message.showCash);
        /* string acceptedPaymentText = 5; */
        if (message.acceptedPaymentText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.acceptedPaymentText);
        /* Mgmt.LocalizedStringVM localizedAcceptedPaymentText = 6; */
        if (message.localizedAcceptedPaymentText)
            LocalizedStringVM.internalBinaryWrite(message.localizedAcceptedPaymentText, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskStyleAcceptedPaymentOptionsVM
 */
export const KioskStyleAcceptedPaymentOptionsVM = new KioskStyleAcceptedPaymentOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FontMediaVM$Type extends MessageType<FontMediaVM> {
    constructor() {
        super("Mgmt.FontMediaVM", [
            { no: 1, name: "kioskFont", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<FontMediaVM>): FontMediaVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FontMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FontMediaVM): FontMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM kioskFont */ 1:
                    message.kioskFont = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskFont);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FontMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM kioskFont = 1; */
        if (message.kioskFont)
            AppearanceMediaAssetVM.internalBinaryWrite(message.kioskFont, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FontMediaVM
 */
export const FontMediaVM = new FontMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MenuColorsVM$Type extends MessageType<MenuColorsVM> {
    constructor() {
        super("Mgmt.MenuColorsVM", [
            { no: 1, name: "modifierGroupPositive", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modifierGroupNegative", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "itemHeaderBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "itemHeaderText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "checkoutHeaderBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "checkoutHeaderText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "logoBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "cartPreviewBorderColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "cartPreviewFillColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "categoryHeaderBackgroundColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "categoryHeaderTextColor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MenuColorsVM>): MenuColorsVM {
        const message = { modifierGroupPositive: "", modifierGroupNegative: "", itemHeaderBackground: "", itemHeaderText: "", checkoutHeaderBackground: "", checkoutHeaderText: "", logoBackground: "", cartPreviewBorderColor: "", cartPreviewFillColor: "", categoryHeaderBackgroundColor: "", categoryHeaderTextColor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MenuColorsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MenuColorsVM): MenuColorsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modifierGroupPositive */ 1:
                    message.modifierGroupPositive = reader.string();
                    break;
                case /* string modifierGroupNegative */ 2:
                    message.modifierGroupNegative = reader.string();
                    break;
                case /* string itemHeaderBackground */ 3:
                    message.itemHeaderBackground = reader.string();
                    break;
                case /* string itemHeaderText */ 4:
                    message.itemHeaderText = reader.string();
                    break;
                case /* string checkoutHeaderBackground */ 5:
                    message.checkoutHeaderBackground = reader.string();
                    break;
                case /* string checkoutHeaderText */ 6:
                    message.checkoutHeaderText = reader.string();
                    break;
                case /* string logoBackground */ 7:
                    message.logoBackground = reader.string();
                    break;
                case /* string cartPreviewBorderColor */ 8:
                    message.cartPreviewBorderColor = reader.string();
                    break;
                case /* string cartPreviewFillColor */ 9:
                    message.cartPreviewFillColor = reader.string();
                    break;
                case /* string categoryHeaderBackgroundColor */ 10:
                    message.categoryHeaderBackgroundColor = reader.string();
                    break;
                case /* string categoryHeaderTextColor */ 11:
                    message.categoryHeaderTextColor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MenuColorsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modifierGroupPositive = 1; */
        if (message.modifierGroupPositive !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modifierGroupPositive);
        /* string modifierGroupNegative = 2; */
        if (message.modifierGroupNegative !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.modifierGroupNegative);
        /* string itemHeaderBackground = 3; */
        if (message.itemHeaderBackground !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.itemHeaderBackground);
        /* string itemHeaderText = 4; */
        if (message.itemHeaderText !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.itemHeaderText);
        /* string checkoutHeaderBackground = 5; */
        if (message.checkoutHeaderBackground !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.checkoutHeaderBackground);
        /* string checkoutHeaderText = 6; */
        if (message.checkoutHeaderText !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.checkoutHeaderText);
        /* string logoBackground = 7; */
        if (message.logoBackground !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.logoBackground);
        /* string cartPreviewBorderColor = 8; */
        if (message.cartPreviewBorderColor !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cartPreviewBorderColor);
        /* string cartPreviewFillColor = 9; */
        if (message.cartPreviewFillColor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cartPreviewFillColor);
        /* string categoryHeaderBackgroundColor = 10; */
        if (message.categoryHeaderBackgroundColor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.categoryHeaderBackgroundColor);
        /* string categoryHeaderTextColor = 11; */
        if (message.categoryHeaderTextColor !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.categoryHeaderTextColor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.MenuColorsVM
 */
export const MenuColorsVM = new MenuColorsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyColorsVM$Type extends MessageType<LoyaltyColorsVM> {
    constructor() {
        super("Mgmt.LoyaltyColorsVM", [
            { no: 1, name: "onLoyaltyAccent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "loyaltyAccent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "loyalty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "onLoyalty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "loyaltyBackground", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyColorsVM>): LoyaltyColorsVM {
        const message = { onLoyaltyAccent: "", loyaltyAccent: "", loyalty: "", onLoyalty: "", loyaltyBackground: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoyaltyColorsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyColorsVM): LoyaltyColorsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string onLoyaltyAccent */ 1:
                    message.onLoyaltyAccent = reader.string();
                    break;
                case /* string loyaltyAccent */ 2:
                    message.loyaltyAccent = reader.string();
                    break;
                case /* string loyalty */ 3:
                    message.loyalty = reader.string();
                    break;
                case /* string onLoyalty */ 4:
                    message.onLoyalty = reader.string();
                    break;
                case /* string loyaltyBackground */ 5:
                    message.loyaltyBackground = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyColorsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string onLoyaltyAccent = 1; */
        if (message.onLoyaltyAccent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.onLoyaltyAccent);
        /* string loyaltyAccent = 2; */
        if (message.loyaltyAccent !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.loyaltyAccent);
        /* string loyalty = 3; */
        if (message.loyalty !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.loyalty);
        /* string onLoyalty = 4; */
        if (message.onLoyalty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.onLoyalty);
        /* string loyaltyBackground = 5; */
        if (message.loyaltyBackground !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.loyaltyBackground);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LoyaltyColorsVM
 */
export const LoyaltyColorsVM = new LoyaltyColorsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateConfigVM$Type extends MessageType<TemplateConfigVM> {
    constructor() {
        super("Mgmt.TemplateConfigVM", [
            { no: 1, name: "templateId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => TemplateSettingsVM },
            { no: 3, name: "screensavers", kind: "message", T: () => ScreensaversVM },
            { no: 4, name: "text", kind: "message", T: () => TemplateTextVM },
            { no: 5, name: "images", kind: "message", T: () => TemplateImagesVM },
            { no: 6, name: "animations", kind: "message", T: () => AnimationsVM }
        ]);
    }
    create(value?: PartialMessage<TemplateConfigVM>): TemplateConfigVM {
        const message = { templateId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateConfigVM): TemplateConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string templateId */ 1:
                    message.templateId = reader.string();
                    break;
                case /* Mgmt.TemplateSettingsVM settings */ 2:
                    message.settings = TemplateSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                case /* Mgmt.ScreensaversVM screensavers */ 3:
                    message.screensavers = ScreensaversVM.internalBinaryRead(reader, reader.uint32(), options, message.screensavers);
                    break;
                case /* Mgmt.TemplateTextVM text */ 4:
                    message.text = TemplateTextVM.internalBinaryRead(reader, reader.uint32(), options, message.text);
                    break;
                case /* Mgmt.TemplateImagesVM images */ 5:
                    message.images = TemplateImagesVM.internalBinaryRead(reader, reader.uint32(), options, message.images);
                    break;
                case /* Mgmt.AnimationsVM animations */ 6:
                    message.animations = AnimationsVM.internalBinaryRead(reader, reader.uint32(), options, message.animations);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TemplateConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string templateId = 1; */
        if (message.templateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.templateId);
        /* Mgmt.TemplateSettingsVM settings = 2; */
        if (message.settings)
            TemplateSettingsVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.ScreensaversVM screensavers = 3; */
        if (message.screensavers)
            ScreensaversVM.internalBinaryWrite(message.screensavers, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.TemplateTextVM text = 4; */
        if (message.text)
            TemplateTextVM.internalBinaryWrite(message.text, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.TemplateImagesVM images = 5; */
        if (message.images)
            TemplateImagesVM.internalBinaryWrite(message.images, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AnimationsVM animations = 6; */
        if (message.animations)
            AnimationsVM.internalBinaryWrite(message.animations, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TemplateConfigVM
 */
export const TemplateConfigVM = new TemplateConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateSettingsVM$Type extends MessageType<TemplateSettingsVM> {
    constructor() {
        super("Mgmt.TemplateSettingsVM", [
            { no: 1, name: "options", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<TemplateSettingsVM>): TemplateSettingsVM {
        const message = { options: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateSettingsVM): TemplateSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> options */ 1:
                    this.binaryReadMap1(message.options, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: TemplateSettingsVM["options"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof TemplateSettingsVM["options"] | undefined, val: TemplateSettingsVM["options"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.TemplateSettingsVM.options");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: TemplateSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> options = 1; */
        for (let k of Object.keys(message.options))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.options[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TemplateSettingsVM
 */
export const TemplateSettingsVM = new TemplateSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateTextVM$Type extends MessageType<TemplateTextVM> {
    constructor() {
        super("Mgmt.TemplateTextVM", [
            { no: 1, name: "default", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TemplateLanguageTextVM },
            { no: 2, name: "overrides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TemplateLanguageTextVM }
        ]);
    }
    create(value?: PartialMessage<TemplateTextVM>): TemplateTextVM {
        const message = { default: [], overrides: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateTextVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateTextVM): TemplateTextVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.TemplateLanguageTextVM default */ 1:
                    message.default.push(TemplateLanguageTextVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Mgmt.TemplateLanguageTextVM overrides */ 2:
                    message.overrides.push(TemplateLanguageTextVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TemplateTextVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.TemplateLanguageTextVM default = 1; */
        for (let i = 0; i < message.default.length; i++)
            TemplateLanguageTextVM.internalBinaryWrite(message.default[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.TemplateLanguageTextVM overrides = 2; */
        for (let i = 0; i < message.overrides.length; i++)
            TemplateLanguageTextVM.internalBinaryWrite(message.overrides[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TemplateTextVM
 */
export const TemplateTextVM = new TemplateTextVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateLanguageTextVM$Type extends MessageType<TemplateLanguageTextVM> {
    constructor() {
        super("Mgmt.TemplateLanguageTextVM", [
            { no: 1, name: "languageCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "strings", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<TemplateLanguageTextVM>): TemplateLanguageTextVM {
        const message = { languageCode: "", strings: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateLanguageTextVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateLanguageTextVM): TemplateLanguageTextVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string languageCode */ 1:
                    message.languageCode = reader.string();
                    break;
                case /* map<string, string> strings */ 2:
                    this.binaryReadMap2(message.strings, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: TemplateLanguageTextVM["strings"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof TemplateLanguageTextVM["strings"] | undefined, val: TemplateLanguageTextVM["strings"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.TemplateLanguageTextVM.strings");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: TemplateLanguageTextVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string languageCode = 1; */
        if (message.languageCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageCode);
        /* map<string, string> strings = 2; */
        for (let k of Object.keys(message.strings))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.strings[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TemplateLanguageTextVM
 */
export const TemplateLanguageTextVM = new TemplateLanguageTextVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateImagesVM$Type extends MessageType<TemplateImagesVM> {
    constructor() {
        super("Mgmt.TemplateImagesVM", [
            { no: 1, name: "defaultCategoryHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 2, name: "defaultLoyaltyRewardImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 3, name: "defaultLoyaltyMedia", kind: "message", T: () => LoyaltyMediaVM },
            { no: 4, name: "discountMedia", kind: "message", T: () => DiscountMediaVM },
            { no: 5, name: "orderCheckoutMedia", kind: "message", T: () => OrderCheckoutMediaVM },
            { no: 6, name: "headerLogoImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 7, name: "itemDetailHeaderBackgroundImage", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<TemplateImagesVM>): TemplateImagesVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateImagesVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateImagesVM): TemplateImagesVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM defaultCategoryHeaderImage */ 1:
                    message.defaultCategoryHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultCategoryHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyRewardImage = 2 [deprecated = true];*/ 2:
                    message.defaultLoyaltyRewardImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultLoyaltyRewardImage);
                    break;
                case /* Mgmt.LoyaltyMediaVM defaultLoyaltyMedia */ 3:
                    message.defaultLoyaltyMedia = LoyaltyMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultLoyaltyMedia);
                    break;
                case /* Mgmt.DiscountMediaVM discountMedia */ 4:
                    message.discountMedia = DiscountMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.discountMedia);
                    break;
                case /* Mgmt.OrderCheckoutMediaVM orderCheckoutMedia */ 5:
                    message.orderCheckoutMedia = OrderCheckoutMediaVM.internalBinaryRead(reader, reader.uint32(), options, message.orderCheckoutMedia);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM headerLogoImage */ 6:
                    message.headerLogoImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.headerLogoImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM itemDetailHeaderBackgroundImage */ 7:
                    message.itemDetailHeaderBackgroundImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.itemDetailHeaderBackgroundImage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TemplateImagesVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM defaultCategoryHeaderImage = 1; */
        if (message.defaultCategoryHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultCategoryHeaderImage, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyRewardImage = 2 [deprecated = true]; */
        if (message.defaultLoyaltyRewardImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultLoyaltyRewardImage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LoyaltyMediaVM defaultLoyaltyMedia = 3; */
        if (message.defaultLoyaltyMedia)
            LoyaltyMediaVM.internalBinaryWrite(message.defaultLoyaltyMedia, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.DiscountMediaVM discountMedia = 4; */
        if (message.discountMedia)
            DiscountMediaVM.internalBinaryWrite(message.discountMedia, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.OrderCheckoutMediaVM orderCheckoutMedia = 5; */
        if (message.orderCheckoutMedia)
            OrderCheckoutMediaVM.internalBinaryWrite(message.orderCheckoutMedia, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM headerLogoImage = 6; */
        if (message.headerLogoImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.headerLogoImage, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM itemDetailHeaderBackgroundImage = 7; */
        if (message.itemDetailHeaderBackgroundImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.itemDetailHeaderBackgroundImage, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TemplateImagesVM
 */
export const TemplateImagesVM = new TemplateImagesVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimationsVM$Type extends MessageType<AnimationsVM> {
    constructor() {
        super("Mgmt.AnimationsVM", [
            { no: 1, name: "loading", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 2, name: "orderTimeout", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 3, name: "scanQRCode", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 4, name: "calculatingOrder", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 5, name: "payWithCard", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 6, name: "paymentSuccess", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 7, name: "paymentFail", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 8, name: "storeIsClosed", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<AnimationsVM>): AnimationsVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnimationsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnimationsVM): AnimationsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM loading */ 1:
                    message.loading = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.loading);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM orderTimeout */ 2:
                    message.orderTimeout = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.orderTimeout);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM scanQRCode */ 3:
                    message.scanQRCode = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.scanQRCode);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM calculatingOrder */ 4:
                    message.calculatingOrder = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.calculatingOrder);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM payWithCard */ 5:
                    message.payWithCard = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.payWithCard);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM paymentSuccess */ 6:
                    message.paymentSuccess = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.paymentSuccess);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM paymentFail */ 7:
                    message.paymentFail = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.paymentFail);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM storeIsClosed */ 8:
                    message.storeIsClosed = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.storeIsClosed);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnimationsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM loading = 1; */
        if (message.loading)
            AppearanceMediaAssetVM.internalBinaryWrite(message.loading, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM orderTimeout = 2; */
        if (message.orderTimeout)
            AppearanceMediaAssetVM.internalBinaryWrite(message.orderTimeout, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM scanQRCode = 3; */
        if (message.scanQRCode)
            AppearanceMediaAssetVM.internalBinaryWrite(message.scanQRCode, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM calculatingOrder = 4; */
        if (message.calculatingOrder)
            AppearanceMediaAssetVM.internalBinaryWrite(message.calculatingOrder, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM payWithCard = 5; */
        if (message.payWithCard)
            AppearanceMediaAssetVM.internalBinaryWrite(message.payWithCard, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM paymentSuccess = 6; */
        if (message.paymentSuccess)
            AppearanceMediaAssetVM.internalBinaryWrite(message.paymentSuccess, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM paymentFail = 7; */
        if (message.paymentFail)
            AppearanceMediaAssetVM.internalBinaryWrite(message.paymentFail, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM storeIsClosed = 8; */
        if (message.storeIsClosed)
            AppearanceMediaAssetVM.internalBinaryWrite(message.storeIsClosed, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AnimationsVM
 */
export const AnimationsVM = new AnimationsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyMediaVM$Type extends MessageType<LoyaltyMediaVM> {
    constructor() {
        super("Mgmt.LoyaltyMediaVM", [
            { no: 1, name: "defaultProfileHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 7, name: "defaultTopCornerImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 2, name: "defaultRewardsBackgroundImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 6, name: "defaultRewardImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 3, name: "defaultProfileFooterImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 8, name: "defaultCheckoutHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 9, name: "defaultSignInHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 10, name: "defaultLoyaltyPerksImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 11, name: "defaultLoyaltyInstructionsImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 12, name: "loyaltySignInButtonImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 13, name: "loyaltyWithPointsHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<LoyaltyMediaVM>): LoyaltyMediaVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoyaltyMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyMediaVM): LoyaltyMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM defaultProfileHeaderImage */ 1:
                    message.defaultProfileHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultProfileHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultTopCornerImage */ 7:
                    message.defaultTopCornerImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultTopCornerImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultRewardsBackgroundImage */ 2:
                    message.defaultRewardsBackgroundImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultRewardsBackgroundImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultRewardImage */ 6:
                    message.defaultRewardImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultRewardImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultProfileFooterImage */ 3:
                    message.defaultProfileFooterImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultProfileFooterImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultCheckoutHeaderImage */ 8:
                    message.defaultCheckoutHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultCheckoutHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultSignInHeaderImage */ 9:
                    message.defaultSignInHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultSignInHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyPerksImage */ 10:
                    message.defaultLoyaltyPerksImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultLoyaltyPerksImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyInstructionsImage */ 11:
                    message.defaultLoyaltyInstructionsImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultLoyaltyInstructionsImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM loyaltySignInButtonImage */ 12:
                    message.loyaltySignInButtonImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.loyaltySignInButtonImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM loyaltyWithPointsHeaderImage */ 13:
                    message.loyaltyWithPointsHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.loyaltyWithPointsHeaderImage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM defaultProfileHeaderImage = 1; */
        if (message.defaultProfileHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultProfileHeaderImage, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultTopCornerImage = 7; */
        if (message.defaultTopCornerImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultTopCornerImage, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultRewardsBackgroundImage = 2; */
        if (message.defaultRewardsBackgroundImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultRewardsBackgroundImage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultRewardImage = 6; */
        if (message.defaultRewardImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultRewardImage, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultProfileFooterImage = 3; */
        if (message.defaultProfileFooterImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultProfileFooterImage, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultCheckoutHeaderImage = 8; */
        if (message.defaultCheckoutHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultCheckoutHeaderImage, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultSignInHeaderImage = 9; */
        if (message.defaultSignInHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultSignInHeaderImage, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyPerksImage = 10; */
        if (message.defaultLoyaltyPerksImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultLoyaltyPerksImage, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM defaultLoyaltyInstructionsImage = 11; */
        if (message.defaultLoyaltyInstructionsImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultLoyaltyInstructionsImage, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM loyaltySignInButtonImage = 12; */
        if (message.loyaltySignInButtonImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.loyaltySignInButtonImage, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM loyaltyWithPointsHeaderImage = 13; */
        if (message.loyaltyWithPointsHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.loyaltyWithPointsHeaderImage, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LoyaltyMediaVM
 */
export const LoyaltyMediaVM = new LoyaltyMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountMediaVM$Type extends MessageType<DiscountMediaVM> {
    constructor() {
        super("Mgmt.DiscountMediaVM", [
            { no: 1, name: "defaultDiscountImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 2, name: "rewardHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 3, name: "discountHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 4, name: "discountAndRewardHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 5, name: "discountCodeInMenuScreenButtonImage", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 6, name: "discountCodeOrderReviewScreenButtonImage", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<DiscountMediaVM>): DiscountMediaVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiscountMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountMediaVM): DiscountMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM defaultDiscountImage */ 1:
                    message.defaultDiscountImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.defaultDiscountImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM rewardHeaderImage */ 2:
                    message.rewardHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.rewardHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM discountHeaderImage */ 3:
                    message.discountHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.discountHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM discountAndRewardHeaderImage */ 4:
                    message.discountAndRewardHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.discountAndRewardHeaderImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM discountCodeInMenuScreenButtonImage */ 5:
                    message.discountCodeInMenuScreenButtonImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.discountCodeInMenuScreenButtonImage);
                    break;
                case /* Mgmt.AppearanceMediaAssetVM discountCodeOrderReviewScreenButtonImage */ 6:
                    message.discountCodeOrderReviewScreenButtonImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.discountCodeOrderReviewScreenButtonImage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM defaultDiscountImage = 1; */
        if (message.defaultDiscountImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.defaultDiscountImage, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM rewardHeaderImage = 2; */
        if (message.rewardHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.rewardHeaderImage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM discountHeaderImage = 3; */
        if (message.discountHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.discountHeaderImage, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM discountAndRewardHeaderImage = 4; */
        if (message.discountAndRewardHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.discountAndRewardHeaderImage, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM discountCodeInMenuScreenButtonImage = 5; */
        if (message.discountCodeInMenuScreenButtonImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.discountCodeInMenuScreenButtonImage, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AppearanceMediaAssetVM discountCodeOrderReviewScreenButtonImage = 6; */
        if (message.discountCodeOrderReviewScreenButtonImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.discountCodeOrderReviewScreenButtonImage, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DiscountMediaVM
 */
export const DiscountMediaVM = new DiscountMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderCheckoutMediaVM$Type extends MessageType<OrderCheckoutMediaVM> {
    constructor() {
        super("Mgmt.OrderCheckoutMediaVM", [
            { no: 1, name: "confirmationHeaderImage", kind: "message", T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<OrderCheckoutMediaVM>): OrderCheckoutMediaVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderCheckoutMediaVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderCheckoutMediaVM): OrderCheckoutMediaVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AppearanceMediaAssetVM confirmationHeaderImage */ 1:
                    message.confirmationHeaderImage = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.confirmationHeaderImage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderCheckoutMediaVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AppearanceMediaAssetVM confirmationHeaderImage = 1; */
        if (message.confirmationHeaderImage)
            AppearanceMediaAssetVM.internalBinaryWrite(message.confirmationHeaderImage, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderCheckoutMediaVM
 */
export const OrderCheckoutMediaVM = new OrderCheckoutMediaVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScreensaversVM$Type extends MessageType<ScreensaversVM> {
    constructor() {
        super("Mgmt.ScreensaversVM", [
            { no: 1, name: "files", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppearanceMediaAssetVM }
        ]);
    }
    create(value?: PartialMessage<ScreensaversVM>): ScreensaversVM {
        const message = { files: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ScreensaversVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScreensaversVM): ScreensaversVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.AppearanceMediaAssetVM files */ 1:
                    message.files.push(AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScreensaversVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.AppearanceMediaAssetVM files = 1; */
        for (let i = 0; i < message.files.length; i++)
            AppearanceMediaAssetVM.internalBinaryWrite(message.files[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ScreensaversVM
 */
export const ScreensaversVM = new ScreensaversVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppearanceMediaAssetVM$Type extends MessageType<AppearanceMediaAssetVM> {
    constructor() {
        super("Mgmt.AppearanceMediaAssetVM", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "storagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AppearanceMediaAssetVM>): AppearanceMediaAssetVM {
        const message = { name: "", displayUrl: "", storagePath: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppearanceMediaAssetVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppearanceMediaAssetVM): AppearanceMediaAssetVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string displayUrl */ 2:
                    message.displayUrl = reader.string();
                    break;
                case /* string storagePath */ 3:
                    message.storagePath = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppearanceMediaAssetVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string displayUrl = 2; */
        if (message.displayUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayUrl);
        /* string storagePath = 3; */
        if (message.storagePath !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.storagePath);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AppearanceMediaAssetVM
 */
export const AppearanceMediaAssetVM = new AppearanceMediaAssetVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptSettingsVM$Type extends MessageType<ReceiptSettingsVM> {
    constructor() {
        super("Mgmt.ReceiptSettingsVM", [
            { no: 1, name: "headerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "footerText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "logo", kind: "message", T: () => AppearanceMediaAssetVM },
            { no: 4, name: "receiptAddress", kind: "message", T: () => AddressVM },
            { no: 5, name: "phone", kind: "message", T: () => PhoneNumberVM },
            { no: 6, name: "autoPrint", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "emailFrom", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "emailFromName", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "enableEmailReceipt", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "templateId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "enableSmsReceipt", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "smsApiKey", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "smsMessageFormat", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "emailSubject", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "receiptVisibilityOptions", kind: "message", T: () => ReceiptVisibilityOptions },
            { no: 16, name: "showQrCodeForReceiptUrl", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "generatingQrCodeMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "qrReceiptTitle", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "qrReceiptMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "showQrCodeWhenPrintReceiptFails", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "qrReceiptPrinterFailedMsg", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "localizedHeaderText", kind: "message", T: () => LocalizedStringVM },
            { no: 23, name: "localizedFooterText", kind: "message", T: () => LocalizedStringVM },
            { no: 24, name: "localizedGeneratingQrCodeMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 25, name: "localizedQrReceiptTitle", kind: "message", T: () => LocalizedStringVM },
            { no: 26, name: "localizedQrReceiptMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 27, name: "localizedQrReceiptPrinterFailedMsg", kind: "message", T: () => LocalizedStringVM },
            { no: 28, name: "localizedEmailSubject", kind: "message", T: () => LocalizedStringVM },
            { no: 29, name: "localizedMessageFormat", kind: "message", T: () => LocalizedStringVM }
        ]);
    }
    create(value?: PartialMessage<ReceiptSettingsVM>): ReceiptSettingsVM {
        const message = { headerText: "", footerText: "", autoPrint: false, showQrCodeForReceiptUrl: false, showQrCodeWhenPrintReceiptFails: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReceiptSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReceiptSettingsVM): ReceiptSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string headerText */ 1:
                    message.headerText = reader.string();
                    break;
                case /* string footerText */ 2:
                    message.footerText = reader.string();
                    break;
                case /* Mgmt.AppearanceMediaAssetVM logo */ 3:
                    message.logo = AppearanceMediaAssetVM.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* Mgmt.AddressVM receiptAddress */ 4:
                    message.receiptAddress = AddressVM.internalBinaryRead(reader, reader.uint32(), options, message.receiptAddress);
                    break;
                case /* Mgmt.PhoneNumberVM phone */ 5:
                    message.phone = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.phone);
                    break;
                case /* bool autoPrint */ 6:
                    message.autoPrint = reader.bool();
                    break;
                case /* optional string emailFrom */ 7:
                    message.emailFrom = reader.string();
                    break;
                case /* optional string emailFromName */ 8:
                    message.emailFromName = reader.string();
                    break;
                case /* optional bool enableEmailReceipt */ 9:
                    message.enableEmailReceipt = reader.bool();
                    break;
                case /* optional string templateId */ 10:
                    message.templateId = reader.string();
                    break;
                case /* optional bool enableSmsReceipt */ 11:
                    message.enableSmsReceipt = reader.bool();
                    break;
                case /* optional string smsApiKey */ 12:
                    message.smsApiKey = reader.string();
                    break;
                case /* optional string smsMessageFormat */ 13:
                    message.smsMessageFormat = reader.string();
                    break;
                case /* optional string emailSubject */ 14:
                    message.emailSubject = reader.string();
                    break;
                case /* Mgmt.ReceiptVisibilityOptions receiptVisibilityOptions */ 15:
                    message.receiptVisibilityOptions = ReceiptVisibilityOptions.internalBinaryRead(reader, reader.uint32(), options, message.receiptVisibilityOptions);
                    break;
                case /* bool showQrCodeForReceiptUrl */ 16:
                    message.showQrCodeForReceiptUrl = reader.bool();
                    break;
                case /* optional string generatingQrCodeMsg */ 17:
                    message.generatingQrCodeMsg = reader.string();
                    break;
                case /* optional string qrReceiptTitle */ 18:
                    message.qrReceiptTitle = reader.string();
                    break;
                case /* optional string qrReceiptMsg */ 19:
                    message.qrReceiptMsg = reader.string();
                    break;
                case /* bool showQrCodeWhenPrintReceiptFails */ 20:
                    message.showQrCodeWhenPrintReceiptFails = reader.bool();
                    break;
                case /* optional string qrReceiptPrinterFailedMsg */ 21:
                    message.qrReceiptPrinterFailedMsg = reader.string();
                    break;
                case /* Mgmt.LocalizedStringVM localizedHeaderText */ 22:
                    message.localizedHeaderText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedHeaderText);
                    break;
                case /* Mgmt.LocalizedStringVM localizedFooterText */ 23:
                    message.localizedFooterText = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedFooterText);
                    break;
                case /* Mgmt.LocalizedStringVM localizedGeneratingQrCodeMsg */ 24:
                    message.localizedGeneratingQrCodeMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedGeneratingQrCodeMsg);
                    break;
                case /* Mgmt.LocalizedStringVM localizedQrReceiptTitle */ 25:
                    message.localizedQrReceiptTitle = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptTitle);
                    break;
                case /* Mgmt.LocalizedStringVM localizedQrReceiptMsg */ 26:
                    message.localizedQrReceiptMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptMsg);
                    break;
                case /* Mgmt.LocalizedStringVM localizedQrReceiptPrinterFailedMsg */ 27:
                    message.localizedQrReceiptPrinterFailedMsg = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedQrReceiptPrinterFailedMsg);
                    break;
                case /* Mgmt.LocalizedStringVM localizedEmailSubject */ 28:
                    message.localizedEmailSubject = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedEmailSubject);
                    break;
                case /* Mgmt.LocalizedStringVM localizedMessageFormat */ 29:
                    message.localizedMessageFormat = LocalizedStringVM.internalBinaryRead(reader, reader.uint32(), options, message.localizedMessageFormat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReceiptSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string headerText = 1; */
        if (message.headerText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.headerText);
        /* string footerText = 2; */
        if (message.footerText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.footerText);
        /* Mgmt.AppearanceMediaAssetVM logo = 3; */
        if (message.logo)
            AppearanceMediaAssetVM.internalBinaryWrite(message.logo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.AddressVM receiptAddress = 4; */
        if (message.receiptAddress)
            AddressVM.internalBinaryWrite(message.receiptAddress, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PhoneNumberVM phone = 5; */
        if (message.phone)
            PhoneNumberVM.internalBinaryWrite(message.phone, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool autoPrint = 6; */
        if (message.autoPrint !== false)
            writer.tag(6, WireType.Varint).bool(message.autoPrint);
        /* optional string emailFrom = 7; */
        if (message.emailFrom !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.emailFrom);
        /* optional string emailFromName = 8; */
        if (message.emailFromName !== undefined)
            writer.tag(8, WireType.LengthDelimited).string(message.emailFromName);
        /* optional bool enableEmailReceipt = 9; */
        if (message.enableEmailReceipt !== undefined)
            writer.tag(9, WireType.Varint).bool(message.enableEmailReceipt);
        /* optional string templateId = 10; */
        if (message.templateId !== undefined)
            writer.tag(10, WireType.LengthDelimited).string(message.templateId);
        /* optional bool enableSmsReceipt = 11; */
        if (message.enableSmsReceipt !== undefined)
            writer.tag(11, WireType.Varint).bool(message.enableSmsReceipt);
        /* optional string smsApiKey = 12; */
        if (message.smsApiKey !== undefined)
            writer.tag(12, WireType.LengthDelimited).string(message.smsApiKey);
        /* optional string smsMessageFormat = 13; */
        if (message.smsMessageFormat !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.smsMessageFormat);
        /* optional string emailSubject = 14; */
        if (message.emailSubject !== undefined)
            writer.tag(14, WireType.LengthDelimited).string(message.emailSubject);
        /* Mgmt.ReceiptVisibilityOptions receiptVisibilityOptions = 15; */
        if (message.receiptVisibilityOptions)
            ReceiptVisibilityOptions.internalBinaryWrite(message.receiptVisibilityOptions, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bool showQrCodeForReceiptUrl = 16; */
        if (message.showQrCodeForReceiptUrl !== false)
            writer.tag(16, WireType.Varint).bool(message.showQrCodeForReceiptUrl);
        /* optional string generatingQrCodeMsg = 17; */
        if (message.generatingQrCodeMsg !== undefined)
            writer.tag(17, WireType.LengthDelimited).string(message.generatingQrCodeMsg);
        /* optional string qrReceiptTitle = 18; */
        if (message.qrReceiptTitle !== undefined)
            writer.tag(18, WireType.LengthDelimited).string(message.qrReceiptTitle);
        /* optional string qrReceiptMsg = 19; */
        if (message.qrReceiptMsg !== undefined)
            writer.tag(19, WireType.LengthDelimited).string(message.qrReceiptMsg);
        /* bool showQrCodeWhenPrintReceiptFails = 20; */
        if (message.showQrCodeWhenPrintReceiptFails !== false)
            writer.tag(20, WireType.Varint).bool(message.showQrCodeWhenPrintReceiptFails);
        /* optional string qrReceiptPrinterFailedMsg = 21; */
        if (message.qrReceiptPrinterFailedMsg !== undefined)
            writer.tag(21, WireType.LengthDelimited).string(message.qrReceiptPrinterFailedMsg);
        /* Mgmt.LocalizedStringVM localizedHeaderText = 22; */
        if (message.localizedHeaderText)
            LocalizedStringVM.internalBinaryWrite(message.localizedHeaderText, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedFooterText = 23; */
        if (message.localizedFooterText)
            LocalizedStringVM.internalBinaryWrite(message.localizedFooterText, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedGeneratingQrCodeMsg = 24; */
        if (message.localizedGeneratingQrCodeMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedGeneratingQrCodeMsg, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedQrReceiptTitle = 25; */
        if (message.localizedQrReceiptTitle)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptTitle, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedQrReceiptMsg = 26; */
        if (message.localizedQrReceiptMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptMsg, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedQrReceiptPrinterFailedMsg = 27; */
        if (message.localizedQrReceiptPrinterFailedMsg)
            LocalizedStringVM.internalBinaryWrite(message.localizedQrReceiptPrinterFailedMsg, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedEmailSubject = 28; */
        if (message.localizedEmailSubject)
            LocalizedStringVM.internalBinaryWrite(message.localizedEmailSubject, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocalizedStringVM localizedMessageFormat = 29; */
        if (message.localizedMessageFormat)
            LocalizedStringVM.internalBinaryWrite(message.localizedMessageFormat, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ReceiptSettingsVM
 */
export const ReceiptSettingsVM = new ReceiptSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptVisibilityOptions$Type extends MessageType<ReceiptVisibilityOptions> {
    constructor() {
        super("Mgmt.ReceiptVisibilityOptions", [
            { no: 1, name: "modifierGroupNames", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "defaultModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "freeModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "pricedModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ReceiptVisibilityOptions>): ReceiptVisibilityOptions {
        const message = { modifierGroupNames: false, defaultModifiers: false, freeModifiers: false, pricedModifiers: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReceiptVisibilityOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReceiptVisibilityOptions): ReceiptVisibilityOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool modifierGroupNames */ 1:
                    message.modifierGroupNames = reader.bool();
                    break;
                case /* bool defaultModifiers */ 2:
                    message.defaultModifiers = reader.bool();
                    break;
                case /* bool freeModifiers */ 3:
                    message.freeModifiers = reader.bool();
                    break;
                case /* bool pricedModifiers */ 4:
                    message.pricedModifiers = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReceiptVisibilityOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool modifierGroupNames = 1; */
        if (message.modifierGroupNames !== false)
            writer.tag(1, WireType.Varint).bool(message.modifierGroupNames);
        /* bool defaultModifiers = 2; */
        if (message.defaultModifiers !== false)
            writer.tag(2, WireType.Varint).bool(message.defaultModifiers);
        /* bool freeModifiers = 3; */
        if (message.freeModifiers !== false)
            writer.tag(3, WireType.Varint).bool(message.freeModifiers);
        /* bool pricedModifiers = 4; */
        if (message.pricedModifiers !== false)
            writer.tag(4, WireType.Varint).bool(message.pricedModifiers);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ReceiptVisibilityOptions
 */
export const ReceiptVisibilityOptions = new ReceiptVisibilityOptions$Type();
