/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "locations.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PosType } from "./system_pb";
import { AddressVM } from "./common_pb";
import { ImageVM } from "./common_pb";
import { PhoneNumberVM } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.LocationsVM
 */
export interface LocationsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.LocationBaseVM locations = 1;
     */
    locations: LocationBaseVM[];
}
/**
 * @generated from protobuf message Mgmt.LocationDetailVM
 */
export interface LocationDetailVM {
    /**
     * @generated from protobuf field: Mgmt.LocationBaseVM locationBase = 1;
     */
    locationBase?: LocationBaseVM;
    /**
     * @generated from protobuf field: Mgmt.OwnerInfoVM ownerInfo = 2;
     */
    ownerInfo?: OwnerInfoVM;
    /**
     * @generated from protobuf field: Mgmt.ContactInfoVM contactInfo = 3;
     */
    contactInfo?: ContactInfoVM;
    /**
     * @generated from protobuf field: Mgmt.StoreLocationVM storeLocation = 4;
     */
    storeLocation?: StoreLocationVM;
    /**
     * @generated from protobuf field: string logoUrl = 5;
     */
    logoUrl: string;
    /**
     * @generated from protobuf field: string homeScreenLogoUrl = 6;
     */
    homeScreenLogoUrl: string;
}
/**
 * @generated from protobuf message Mgmt.LocationBaseVM
 */
export interface LocationBaseVM {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string companyId = 2;
     */
    companyId: string;
    /**
     * @generated from protobuf field: string timezone = 3;
     */
    timezone: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: Mgmt.LocationInheritanceVM locationInheritance = 5;
     */
    locationInheritance?: LocationInheritanceVM;
    /**
     * @generated from protobuf field: string catalogId = 6;
     */
    catalogId: string;
    /**
     * @generated from protobuf field: string posConnectorConfigurationId = 7;
     */
    posConnectorConfigurationId: string;
    /**
     * @generated from protobuf field: string menuId = 8;
     */
    menuId: string;
}
/**
 * @generated from protobuf message Mgmt.OwnerInfoVM
 */
export interface OwnerInfoVM {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string ownerName = 3;
     */
    ownerName: string;
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM ownerContactNo = 4;
     */
    ownerContactNo?: PhoneNumberVM;
    /**
     * @generated from protobuf field: Mgmt.ImageVM ownerProfilePic = 5;
     */
    ownerProfilePic?: ImageVM;
}
/**
 * @generated from protobuf message Mgmt.ContactInfoVM
 */
export interface ContactInfoVM {
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM contactNo = 1;
     */
    contactNo?: PhoneNumberVM;
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM customerSupportNo = 2;
     */
    customerSupportNo?: PhoneNumberVM;
    /**
     * @generated from protobuf field: string locationEmail = 3;
     */
    locationEmail: string;
    /**
     * @generated from protobuf field: string customerSupportEmail = 4;
     */
    customerSupportEmail: string;
}
/**
 * @generated from protobuf message Mgmt.StoreLocationVM
 */
export interface StoreLocationVM {
    /**
     * @generated from protobuf field: Mgmt.AddressVM address = 1;
     */
    address?: AddressVM;
    /**
     * @generated from protobuf field: Mgmt.LocationCoordinatesVM locationCoordinates = 2;
     */
    locationCoordinates?: LocationCoordinatesVM;
    /**
     * @generated from protobuf field: string countryName = 3;
     */
    countryName: string;
    /**
     * @generated from protobuf field: string timezoneId = 4;
     */
    timezoneId: string;
    /**
     * @generated from protobuf field: string region = 5;
     */
    region: string;
}
/**
 * @generated from protobuf message Mgmt.LocationInheritanceVM
 */
export interface LocationInheritanceVM {
    /**
     * @generated from protobuf field: string parentLocationId = 1;
     */
    parentLocationId: string;
    /**
     * @generated from protobuf field: bool inheritMenu = 2;
     */
    inheritMenu: boolean;
    /**
     * @generated from protobuf field: bool inheritTheme = 3;
     */
    inheritTheme: boolean;
    /**
     * @generated from protobuf field: bool inheritKioskSettings = 4;
     */
    inheritKioskSettings: boolean;
}
/**
 * @generated from protobuf message Mgmt.LocationCoordinatesVM
 */
export interface LocationCoordinatesVM {
    /**
     * @generated from protobuf field: string latitude = 1;
     */
    latitude: string;
    /**
     * @generated from protobuf field: string longitude = 2;
     */
    longitude: string;
}
/**
 * @generated from protobuf message Mgmt.PosIntegrationVM
 */
export interface PosIntegrationVM {
    /**
     * @generated from protobuf field: Mgmt.PosIntegrationConfigVM config = 1;
     */
    config?: PosIntegrationConfigVM;
    /**
     * @generated from protobuf field: repeated Mgmt.PosType allowedDefinitions = 2;
     */
    allowedDefinitions: PosType[];
    /**
     * @generated from protobuf field: Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig = 3;
     */
    posSyncIntegrationConfig?: PosSyncIntegrationConfigVM;
    /**
     * @generated from protobuf field: repeated Mgmt.PosSyncMetaRecordConfigVM posSyncMetaRecordConfig = 4;
     */
    posSyncMetaRecordConfig: PosSyncMetaRecordConfigVM[];
}
/**
 * @generated from protobuf message Mgmt.PosIntegrationMenuSettingsVM
 */
export interface PosIntegrationMenuSettingsVM {
    /**
     * @generated from protobuf field: bool supportsFreeModifiers = 1;
     */
    supportsFreeModifiers: boolean;
    /**
     * @generated from protobuf field: bool supportsNegativeModifierBehavior = 2;
     */
    supportsNegativeModifierBehavior: boolean;
}
/**
 * @generated from protobuf message Mgmt.PosIntegrationConfigVM
 */
export interface PosIntegrationConfigVM {
    /**
     * @generated from protobuf field: string posIntegrationId = 1;
     */
    posIntegrationId: string;
    /**
     * @generated from protobuf field: string fields = 2;
     */
    fields: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
}
/**
 * @generated from protobuf message Mgmt.PosSyncMetaRecordConfigVM
 */
export interface PosSyncMetaRecordConfigVM {
    /**
     * @generated from protobuf field: string posIntegrationId = 1;
     */
    posIntegrationId: string;
    /**
     * @generated from protobuf field: string posSyncSetting = 2;
     */
    posSyncSetting: string;
    /**
     * @generated from protobuf field: string kioskPushSetting = 3;
     */
    kioskPushSetting: string;
}
/**
 * @generated from protobuf message Mgmt.LocationSetupStatusVM
 */
export interface LocationSetupStatusVM {
    /**
     * @generated from protobuf field: Mgmt.PosChecksVM Pos = 1 [json_name = "Pos"];
     */
    pos?: PosChecksVM;
    /**
     * @generated from protobuf field: Mgmt.PaymentChecksVM Payment = 2 [json_name = "Payment"];
     */
    payment?: PaymentChecksVM;
    /**
     * @generated from protobuf field: Mgmt.KioskChecksVM Kiosk = 3 [json_name = "Kiosk"];
     */
    kiosk?: KioskChecksVM;
    /**
     * @generated from protobuf field: Mgmt.LocationChecksVM Location = 4 [json_name = "Location"];
     */
    location?: LocationChecksVM;
}
/**
 * @generated from protobuf message Mgmt.PosChecksVM
 */
export interface PosChecksVM {
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"];
     */
    configured?: StatusCheckVM;
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM MenuSynced = 2 [json_name = "MenuSynced"];
     */
    menuSynced?: StatusCheckVM;
}
/**
 * @generated from protobuf message Mgmt.PaymentChecksVM
 */
export interface PaymentChecksVM {
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"];
     */
    configured?: StatusCheckVM;
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM TenderMapping = 2 [json_name = "TenderMapping"];
     */
    tenderMapping?: StatusCheckVM;
}
/**
 * @generated from protobuf message Mgmt.KioskChecksVM
 */
export interface KioskChecksVM {
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Created = 1 [json_name = "Created"];
     */
    created?: StatusCheckVM;
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Connected = 2 [json_name = "Connected"];
     */
    connected?: StatusCheckVM;
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Payment = 3 [json_name = "Payment"];
     */
    payment?: StatusCheckVM;
}
/**
 * @generated from protobuf message Mgmt.LocationChecksVM
 */
export interface LocationChecksVM {
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM OrderTypes = 1 [json_name = "OrderTypes"];
     */
    orderTypes?: StatusCheckVM;
    /**
     * @generated from protobuf field: Mgmt.StatusCheckVM Staff = 2 [json_name = "Staff"];
     */
    staff?: StatusCheckVM;
}
/**
 * @generated from protobuf message Mgmt.StatusCheckVM
 */
export interface StatusCheckVM {
    /**
     * @generated from protobuf field: Mgmt.SetupStatusEnum Status = 1 [json_name = "Status"];
     */
    status: SetupStatusEnum;
    /**
     * @generated from protobuf field: string Message = 2 [json_name = "Message"];
     */
    message: string;
}
/**
 * @generated from protobuf message Mgmt.StaffVM
 */
export interface StaffVM {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string accessCode = 2;
     */
    accessCode: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
}
/**
 * @generated from protobuf message Mgmt.LocationStaffVM
 */
export interface LocationStaffVM {
    /**
     * @generated from protobuf field: repeated Mgmt.StaffVM staff = 1;
     */
    staff: StaffVM[];
}
/**
 * @generated from protobuf message Mgmt.TransformConfigVM
 */
export interface TransformConfigVM {
    /**
     * @generated from protobuf field: string menuWorkflowName = 2;
     */
    menuWorkflowName: string;
    /**
     * @generated from protobuf field: string menuWorkflowJson = 3;
     */
    menuWorkflowJson: string;
    /**
     * @generated from protobuf field: string orderWorkflowName = 4;
     */
    orderWorkflowName: string;
    /**
     * @generated from protobuf field: string orderWorkflowJson = 5;
     */
    orderWorkflowJson: string;
}
/**
 * @generated from protobuf message Mgmt.PosSyncIntegrationConfigVM
 */
export interface PosSyncIntegrationConfigVM {
    /**
     * @generated from protobuf field: string posIntegrationId = 1;
     */
    posIntegrationId: string;
    /**
     * @generated from protobuf field: Mgmt.PosSyncSettingVM posSyncSetting = 2;
     */
    posSyncSetting?: PosSyncSettingVM;
    /**
     * @generated from protobuf field: Mgmt.KioskPushSettingVM kioskPushSetting = 3;
     */
    kioskPushSetting?: KioskPushSettingVM;
}
/**
 * @generated from protobuf message Mgmt.PosSyncSettingVM
 */
export interface PosSyncSettingVM {
    /**
     * @generated from protobuf field: bool menuItemOverrides = 1;
     */
    menuItemOverrides: boolean;
    /**
     * @generated from protobuf field: bool menuCategoryOverrides = 2;
     */
    menuCategoryOverrides: boolean;
    /**
     * @generated from protobuf field: bool modifierGroupOverrides = 3;
     */
    modifierGroupOverrides: boolean;
    /**
     * @generated from protobuf field: bool modifierOverrides = 4;
     */
    modifierOverrides: boolean;
}
/**
 * @generated from protobuf message Mgmt.KioskPushSettingVM
 */
export interface KioskPushSettingVM {
    /**
     * @generated from protobuf field: bool kioskPriceUpdate = 1;
     */
    kioskPriceUpdate: boolean;
    /**
     * @generated from protobuf field: bool kioskFullMenuUpdate = 2;
     */
    kioskFullMenuUpdate: boolean;
    /**
     * @generated from protobuf field: bool kioskNoUpdate = 3;
     */
    kioskNoUpdate: boolean;
}
/**
 * @generated from protobuf enum Mgmt.SetupStatusEnum
 */
export enum SetupStatusEnum {
    /**
     * @generated from protobuf enum value: NotApplicable = 0;
     */
    NotApplicable = 0,
    /**
     * @generated from protobuf enum value: Green = 1;
     */
    Green = 1,
    /**
     * @generated from protobuf enum value: Yellow = 2;
     */
    Yellow = 2,
    /**
     * @generated from protobuf enum value: Red = 3;
     */
    Red = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class LocationsVM$Type extends MessageType<LocationsVM> {
    constructor() {
        super("Mgmt.LocationsVM", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationBaseVM }
        ]);
    }
    create(value?: PartialMessage<LocationsVM>): LocationsVM {
        const message = { locations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationsVM): LocationsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.LocationBaseVM locations */ 1:
                    message.locations.push(LocationBaseVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.LocationBaseVM locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            LocationBaseVM.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationsVM
 */
export const LocationsVM = new LocationsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationDetailVM$Type extends MessageType<LocationDetailVM> {
    constructor() {
        super("Mgmt.LocationDetailVM", [
            { no: 1, name: "locationBase", kind: "message", T: () => LocationBaseVM },
            { no: 2, name: "ownerInfo", kind: "message", T: () => OwnerInfoVM },
            { no: 3, name: "contactInfo", kind: "message", T: () => ContactInfoVM },
            { no: 4, name: "storeLocation", kind: "message", T: () => StoreLocationVM },
            { no: 5, name: "logoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "homeScreenLogoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationDetailVM>): LocationDetailVM {
        const message = { logoUrl: "", homeScreenLogoUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationDetailVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationDetailVM): LocationDetailVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.LocationBaseVM locationBase */ 1:
                    message.locationBase = LocationBaseVM.internalBinaryRead(reader, reader.uint32(), options, message.locationBase);
                    break;
                case /* Mgmt.OwnerInfoVM ownerInfo */ 2:
                    message.ownerInfo = OwnerInfoVM.internalBinaryRead(reader, reader.uint32(), options, message.ownerInfo);
                    break;
                case /* Mgmt.ContactInfoVM contactInfo */ 3:
                    message.contactInfo = ContactInfoVM.internalBinaryRead(reader, reader.uint32(), options, message.contactInfo);
                    break;
                case /* Mgmt.StoreLocationVM storeLocation */ 4:
                    message.storeLocation = StoreLocationVM.internalBinaryRead(reader, reader.uint32(), options, message.storeLocation);
                    break;
                case /* string logoUrl */ 5:
                    message.logoUrl = reader.string();
                    break;
                case /* string homeScreenLogoUrl */ 6:
                    message.homeScreenLogoUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationDetailVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.LocationBaseVM locationBase = 1; */
        if (message.locationBase)
            LocationBaseVM.internalBinaryWrite(message.locationBase, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.OwnerInfoVM ownerInfo = 2; */
        if (message.ownerInfo)
            OwnerInfoVM.internalBinaryWrite(message.ownerInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.ContactInfoVM contactInfo = 3; */
        if (message.contactInfo)
            ContactInfoVM.internalBinaryWrite(message.contactInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StoreLocationVM storeLocation = 4; */
        if (message.storeLocation)
            StoreLocationVM.internalBinaryWrite(message.storeLocation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string logoUrl = 5; */
        if (message.logoUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.logoUrl);
        /* string homeScreenLogoUrl = 6; */
        if (message.homeScreenLogoUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.homeScreenLogoUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationDetailVM
 */
export const LocationDetailVM = new LocationDetailVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationBaseVM$Type extends MessageType<LocationBaseVM> {
    constructor() {
        super("Mgmt.LocationBaseVM", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "companyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locationInheritance", kind: "message", T: () => LocationInheritanceVM },
            { no: 6, name: "catalogId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "posConnectorConfigurationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "menuId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationBaseVM>): LocationBaseVM {
        const message = { locationId: "", companyId: "", timezone: "", name: "", catalogId: "", posConnectorConfigurationId: "", menuId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationBaseVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationBaseVM): LocationBaseVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string companyId */ 2:
                    message.companyId = reader.string();
                    break;
                case /* string timezone */ 3:
                    message.timezone = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* Mgmt.LocationInheritanceVM locationInheritance */ 5:
                    message.locationInheritance = LocationInheritanceVM.internalBinaryRead(reader, reader.uint32(), options, message.locationInheritance);
                    break;
                case /* string catalogId */ 6:
                    message.catalogId = reader.string();
                    break;
                case /* string posConnectorConfigurationId */ 7:
                    message.posConnectorConfigurationId = reader.string();
                    break;
                case /* string menuId */ 8:
                    message.menuId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationBaseVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string companyId = 2; */
        if (message.companyId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.companyId);
        /* string timezone = 3; */
        if (message.timezone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timezone);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* Mgmt.LocationInheritanceVM locationInheritance = 5; */
        if (message.locationInheritance)
            LocationInheritanceVM.internalBinaryWrite(message.locationInheritance, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string catalogId = 6; */
        if (message.catalogId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.catalogId);
        /* string posConnectorConfigurationId = 7; */
        if (message.posConnectorConfigurationId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.posConnectorConfigurationId);
        /* string menuId = 8; */
        if (message.menuId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.menuId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationBaseVM
 */
export const LocationBaseVM = new LocationBaseVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OwnerInfoVM$Type extends MessageType<OwnerInfoVM> {
    constructor() {
        super("Mgmt.OwnerInfoVM", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ownerName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ownerContactNo", kind: "message", T: () => PhoneNumberVM },
            { no: 5, name: "ownerProfilePic", kind: "message", T: () => ImageVM }
        ]);
    }
    create(value?: PartialMessage<OwnerInfoVM>): OwnerInfoVM {
        const message = { email: "", ownerName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OwnerInfoVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OwnerInfoVM): OwnerInfoVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string ownerName */ 3:
                    message.ownerName = reader.string();
                    break;
                case /* Mgmt.PhoneNumberVM ownerContactNo */ 4:
                    message.ownerContactNo = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.ownerContactNo);
                    break;
                case /* Mgmt.ImageVM ownerProfilePic */ 5:
                    message.ownerProfilePic = ImageVM.internalBinaryRead(reader, reader.uint32(), options, message.ownerProfilePic);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OwnerInfoVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string ownerName = 3; */
        if (message.ownerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ownerName);
        /* Mgmt.PhoneNumberVM ownerContactNo = 4; */
        if (message.ownerContactNo)
            PhoneNumberVM.internalBinaryWrite(message.ownerContactNo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.ImageVM ownerProfilePic = 5; */
        if (message.ownerProfilePic)
            ImageVM.internalBinaryWrite(message.ownerProfilePic, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OwnerInfoVM
 */
export const OwnerInfoVM = new OwnerInfoVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContactInfoVM$Type extends MessageType<ContactInfoVM> {
    constructor() {
        super("Mgmt.ContactInfoVM", [
            { no: 1, name: "contactNo", kind: "message", T: () => PhoneNumberVM },
            { no: 2, name: "customerSupportNo", kind: "message", T: () => PhoneNumberVM },
            { no: 3, name: "locationEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customerSupportEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContactInfoVM>): ContactInfoVM {
        const message = { locationEmail: "", customerSupportEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContactInfoVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContactInfoVM): ContactInfoVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.PhoneNumberVM contactNo */ 1:
                    message.contactNo = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.contactNo);
                    break;
                case /* Mgmt.PhoneNumberVM customerSupportNo */ 2:
                    message.customerSupportNo = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.customerSupportNo);
                    break;
                case /* string locationEmail */ 3:
                    message.locationEmail = reader.string();
                    break;
                case /* string customerSupportEmail */ 4:
                    message.customerSupportEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContactInfoVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.PhoneNumberVM contactNo = 1; */
        if (message.contactNo)
            PhoneNumberVM.internalBinaryWrite(message.contactNo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PhoneNumberVM customerSupportNo = 2; */
        if (message.customerSupportNo)
            PhoneNumberVM.internalBinaryWrite(message.customerSupportNo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string locationEmail = 3; */
        if (message.locationEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.locationEmail);
        /* string customerSupportEmail = 4; */
        if (message.customerSupportEmail !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerSupportEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ContactInfoVM
 */
export const ContactInfoVM = new ContactInfoVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreLocationVM$Type extends MessageType<StoreLocationVM> {
    constructor() {
        super("Mgmt.StoreLocationVM", [
            { no: 1, name: "address", kind: "message", T: () => AddressVM },
            { no: 2, name: "locationCoordinates", kind: "message", T: () => LocationCoordinatesVM },
            { no: 3, name: "countryName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timezoneId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StoreLocationVM>): StoreLocationVM {
        const message = { countryName: "", timezoneId: "", region: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StoreLocationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreLocationVM): StoreLocationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.AddressVM address */ 1:
                    message.address = AddressVM.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* Mgmt.LocationCoordinatesVM locationCoordinates */ 2:
                    message.locationCoordinates = LocationCoordinatesVM.internalBinaryRead(reader, reader.uint32(), options, message.locationCoordinates);
                    break;
                case /* string countryName */ 3:
                    message.countryName = reader.string();
                    break;
                case /* string timezoneId */ 4:
                    message.timezoneId = reader.string();
                    break;
                case /* string region */ 5:
                    message.region = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StoreLocationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.AddressVM address = 1; */
        if (message.address)
            AddressVM.internalBinaryWrite(message.address, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocationCoordinatesVM locationCoordinates = 2; */
        if (message.locationCoordinates)
            LocationCoordinatesVM.internalBinaryWrite(message.locationCoordinates, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string countryName = 3; */
        if (message.countryName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.countryName);
        /* string timezoneId = 4; */
        if (message.timezoneId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timezoneId);
        /* string region = 5; */
        if (message.region !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.region);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.StoreLocationVM
 */
export const StoreLocationVM = new StoreLocationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationInheritanceVM$Type extends MessageType<LocationInheritanceVM> {
    constructor() {
        super("Mgmt.LocationInheritanceVM", [
            { no: 1, name: "parentLocationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inheritMenu", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "inheritTheme", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "inheritKioskSettings", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LocationInheritanceVM>): LocationInheritanceVM {
        const message = { parentLocationId: "", inheritMenu: false, inheritTheme: false, inheritKioskSettings: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationInheritanceVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationInheritanceVM): LocationInheritanceVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parentLocationId */ 1:
                    message.parentLocationId = reader.string();
                    break;
                case /* bool inheritMenu */ 2:
                    message.inheritMenu = reader.bool();
                    break;
                case /* bool inheritTheme */ 3:
                    message.inheritTheme = reader.bool();
                    break;
                case /* bool inheritKioskSettings */ 4:
                    message.inheritKioskSettings = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationInheritanceVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parentLocationId = 1; */
        if (message.parentLocationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parentLocationId);
        /* bool inheritMenu = 2; */
        if (message.inheritMenu !== false)
            writer.tag(2, WireType.Varint).bool(message.inheritMenu);
        /* bool inheritTheme = 3; */
        if (message.inheritTheme !== false)
            writer.tag(3, WireType.Varint).bool(message.inheritTheme);
        /* bool inheritKioskSettings = 4; */
        if (message.inheritKioskSettings !== false)
            writer.tag(4, WireType.Varint).bool(message.inheritKioskSettings);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationInheritanceVM
 */
export const LocationInheritanceVM = new LocationInheritanceVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationCoordinatesVM$Type extends MessageType<LocationCoordinatesVM> {
    constructor() {
        super("Mgmt.LocationCoordinatesVM", [
            { no: 1, name: "latitude", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationCoordinatesVM>): LocationCoordinatesVM {
        const message = { latitude: "", longitude: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationCoordinatesVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationCoordinatesVM): LocationCoordinatesVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string latitude */ 1:
                    message.latitude = reader.string();
                    break;
                case /* string longitude */ 2:
                    message.longitude = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationCoordinatesVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string latitude = 1; */
        if (message.latitude !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.latitude);
        /* string longitude = 2; */
        if (message.longitude !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationCoordinatesVM
 */
export const LocationCoordinatesVM = new LocationCoordinatesVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosIntegrationVM$Type extends MessageType<PosIntegrationVM> {
    constructor() {
        super("Mgmt.PosIntegrationVM", [
            { no: 1, name: "config", kind: "message", T: () => PosIntegrationConfigVM },
            { no: 2, name: "allowedDefinitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PosType },
            { no: 3, name: "posSyncIntegrationConfig", kind: "message", T: () => PosSyncIntegrationConfigVM },
            { no: 4, name: "posSyncMetaRecordConfig", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PosSyncMetaRecordConfigVM }
        ]);
    }
    create(value?: PartialMessage<PosIntegrationVM>): PosIntegrationVM {
        const message = { allowedDefinitions: [], posSyncMetaRecordConfig: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosIntegrationVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosIntegrationVM): PosIntegrationVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.PosIntegrationConfigVM config */ 1:
                    message.config = PosIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                case /* repeated Mgmt.PosType allowedDefinitions */ 2:
                    message.allowedDefinitions.push(PosType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig */ 3:
                    message.posSyncIntegrationConfig = PosSyncIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncIntegrationConfig);
                    break;
                case /* repeated Mgmt.PosSyncMetaRecordConfigVM posSyncMetaRecordConfig */ 4:
                    message.posSyncMetaRecordConfig.push(PosSyncMetaRecordConfigVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosIntegrationVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.PosIntegrationConfigVM config = 1; */
        if (message.config)
            PosIntegrationConfigVM.internalBinaryWrite(message.config, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.PosType allowedDefinitions = 2; */
        for (let i = 0; i < message.allowedDefinitions.length; i++)
            PosType.internalBinaryWrite(message.allowedDefinitions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PosSyncIntegrationConfigVM posSyncIntegrationConfig = 3; */
        if (message.posSyncIntegrationConfig)
            PosSyncIntegrationConfigVM.internalBinaryWrite(message.posSyncIntegrationConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.PosSyncMetaRecordConfigVM posSyncMetaRecordConfig = 4; */
        for (let i = 0; i < message.posSyncMetaRecordConfig.length; i++)
            PosSyncMetaRecordConfigVM.internalBinaryWrite(message.posSyncMetaRecordConfig[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosIntegrationVM
 */
export const PosIntegrationVM = new PosIntegrationVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosIntegrationMenuSettingsVM$Type extends MessageType<PosIntegrationMenuSettingsVM> {
    constructor() {
        super("Mgmt.PosIntegrationMenuSettingsVM", [
            { no: 1, name: "supportsFreeModifiers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "supportsNegativeModifierBehavior", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PosIntegrationMenuSettingsVM>): PosIntegrationMenuSettingsVM {
        const message = { supportsFreeModifiers: false, supportsNegativeModifierBehavior: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosIntegrationMenuSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosIntegrationMenuSettingsVM): PosIntegrationMenuSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool supportsFreeModifiers */ 1:
                    message.supportsFreeModifiers = reader.bool();
                    break;
                case /* bool supportsNegativeModifierBehavior */ 2:
                    message.supportsNegativeModifierBehavior = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosIntegrationMenuSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool supportsFreeModifiers = 1; */
        if (message.supportsFreeModifiers !== false)
            writer.tag(1, WireType.Varint).bool(message.supportsFreeModifiers);
        /* bool supportsNegativeModifierBehavior = 2; */
        if (message.supportsNegativeModifierBehavior !== false)
            writer.tag(2, WireType.Varint).bool(message.supportsNegativeModifierBehavior);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosIntegrationMenuSettingsVM
 */
export const PosIntegrationMenuSettingsVM = new PosIntegrationMenuSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosIntegrationConfigVM$Type extends MessageType<PosIntegrationConfigVM> {
    constructor() {
        super("Mgmt.PosIntegrationConfigVM", [
            { no: 1, name: "posIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fields", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PosIntegrationConfigVM>): PosIntegrationConfigVM {
        const message = { posIntegrationId: "", fields: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosIntegrationConfigVM): PosIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string posIntegrationId */ 1:
                    message.posIntegrationId = reader.string();
                    break;
                case /* string fields */ 2:
                    message.fields = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string posIntegrationId = 1; */
        if (message.posIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.posIntegrationId);
        /* string fields = 2; */
        if (message.fields !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fields);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosIntegrationConfigVM
 */
export const PosIntegrationConfigVM = new PosIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncMetaRecordConfigVM$Type extends MessageType<PosSyncMetaRecordConfigVM> {
    constructor() {
        super("Mgmt.PosSyncMetaRecordConfigVM", [
            { no: 1, name: "posIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "posSyncSetting", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kioskPushSetting", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PosSyncMetaRecordConfigVM>): PosSyncMetaRecordConfigVM {
        const message = { posIntegrationId: "", posSyncSetting: "", kioskPushSetting: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncMetaRecordConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncMetaRecordConfigVM): PosSyncMetaRecordConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string posIntegrationId */ 1:
                    message.posIntegrationId = reader.string();
                    break;
                case /* string posSyncSetting */ 2:
                    message.posSyncSetting = reader.string();
                    break;
                case /* string kioskPushSetting */ 3:
                    message.kioskPushSetting = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncMetaRecordConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string posIntegrationId = 1; */
        if (message.posIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.posIntegrationId);
        /* string posSyncSetting = 2; */
        if (message.posSyncSetting !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.posSyncSetting);
        /* string kioskPushSetting = 3; */
        if (message.kioskPushSetting !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kioskPushSetting);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncMetaRecordConfigVM
 */
export const PosSyncMetaRecordConfigVM = new PosSyncMetaRecordConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationSetupStatusVM$Type extends MessageType<LocationSetupStatusVM> {
    constructor() {
        super("Mgmt.LocationSetupStatusVM", [
            { no: 1, name: "Pos", kind: "message", jsonName: "Pos", T: () => PosChecksVM },
            { no: 2, name: "Payment", kind: "message", jsonName: "Payment", T: () => PaymentChecksVM },
            { no: 3, name: "Kiosk", kind: "message", jsonName: "Kiosk", T: () => KioskChecksVM },
            { no: 4, name: "Location", kind: "message", jsonName: "Location", T: () => LocationChecksVM }
        ]);
    }
    create(value?: PartialMessage<LocationSetupStatusVM>): LocationSetupStatusVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationSetupStatusVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationSetupStatusVM): LocationSetupStatusVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.PosChecksVM Pos = 1 [json_name = "Pos"];*/ 1:
                    message.pos = PosChecksVM.internalBinaryRead(reader, reader.uint32(), options, message.pos);
                    break;
                case /* Mgmt.PaymentChecksVM Payment = 2 [json_name = "Payment"];*/ 2:
                    message.payment = PaymentChecksVM.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                case /* Mgmt.KioskChecksVM Kiosk = 3 [json_name = "Kiosk"];*/ 3:
                    message.kiosk = KioskChecksVM.internalBinaryRead(reader, reader.uint32(), options, message.kiosk);
                    break;
                case /* Mgmt.LocationChecksVM Location = 4 [json_name = "Location"];*/ 4:
                    message.location = LocationChecksVM.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationSetupStatusVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.PosChecksVM Pos = 1 [json_name = "Pos"]; */
        if (message.pos)
            PosChecksVM.internalBinaryWrite(message.pos, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PaymentChecksVM Payment = 2 [json_name = "Payment"]; */
        if (message.payment)
            PaymentChecksVM.internalBinaryWrite(message.payment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskChecksVM Kiosk = 3 [json_name = "Kiosk"]; */
        if (message.kiosk)
            KioskChecksVM.internalBinaryWrite(message.kiosk, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.LocationChecksVM Location = 4 [json_name = "Location"]; */
        if (message.location)
            LocationChecksVM.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationSetupStatusVM
 */
export const LocationSetupStatusVM = new LocationSetupStatusVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosChecksVM$Type extends MessageType<PosChecksVM> {
    constructor() {
        super("Mgmt.PosChecksVM", [
            { no: 1, name: "Configured", kind: "message", jsonName: "Configured", T: () => StatusCheckVM },
            { no: 2, name: "MenuSynced", kind: "message", jsonName: "MenuSynced", T: () => StatusCheckVM }
        ]);
    }
    create(value?: PartialMessage<PosChecksVM>): PosChecksVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosChecksVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosChecksVM): PosChecksVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"];*/ 1:
                    message.configured = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.configured);
                    break;
                case /* Mgmt.StatusCheckVM MenuSynced = 2 [json_name = "MenuSynced"];*/ 2:
                    message.menuSynced = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.menuSynced);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosChecksVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"]; */
        if (message.configured)
            StatusCheckVM.internalBinaryWrite(message.configured, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StatusCheckVM MenuSynced = 2 [json_name = "MenuSynced"]; */
        if (message.menuSynced)
            StatusCheckVM.internalBinaryWrite(message.menuSynced, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosChecksVM
 */
export const PosChecksVM = new PosChecksVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentChecksVM$Type extends MessageType<PaymentChecksVM> {
    constructor() {
        super("Mgmt.PaymentChecksVM", [
            { no: 1, name: "Configured", kind: "message", jsonName: "Configured", T: () => StatusCheckVM },
            { no: 2, name: "TenderMapping", kind: "message", jsonName: "TenderMapping", T: () => StatusCheckVM }
        ]);
    }
    create(value?: PartialMessage<PaymentChecksVM>): PaymentChecksVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentChecksVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentChecksVM): PaymentChecksVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"];*/ 1:
                    message.configured = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.configured);
                    break;
                case /* Mgmt.StatusCheckVM TenderMapping = 2 [json_name = "TenderMapping"];*/ 2:
                    message.tenderMapping = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.tenderMapping);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentChecksVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.StatusCheckVM Configured = 1 [json_name = "Configured"]; */
        if (message.configured)
            StatusCheckVM.internalBinaryWrite(message.configured, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StatusCheckVM TenderMapping = 2 [json_name = "TenderMapping"]; */
        if (message.tenderMapping)
            StatusCheckVM.internalBinaryWrite(message.tenderMapping, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentChecksVM
 */
export const PaymentChecksVM = new PaymentChecksVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskChecksVM$Type extends MessageType<KioskChecksVM> {
    constructor() {
        super("Mgmt.KioskChecksVM", [
            { no: 1, name: "Created", kind: "message", jsonName: "Created", T: () => StatusCheckVM },
            { no: 2, name: "Connected", kind: "message", jsonName: "Connected", T: () => StatusCheckVM },
            { no: 3, name: "Payment", kind: "message", jsonName: "Payment", T: () => StatusCheckVM }
        ]);
    }
    create(value?: PartialMessage<KioskChecksVM>): KioskChecksVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskChecksVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskChecksVM): KioskChecksVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.StatusCheckVM Created = 1 [json_name = "Created"];*/ 1:
                    message.created = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* Mgmt.StatusCheckVM Connected = 2 [json_name = "Connected"];*/ 2:
                    message.connected = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.connected);
                    break;
                case /* Mgmt.StatusCheckVM Payment = 3 [json_name = "Payment"];*/ 3:
                    message.payment = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskChecksVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.StatusCheckVM Created = 1 [json_name = "Created"]; */
        if (message.created)
            StatusCheckVM.internalBinaryWrite(message.created, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StatusCheckVM Connected = 2 [json_name = "Connected"]; */
        if (message.connected)
            StatusCheckVM.internalBinaryWrite(message.connected, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StatusCheckVM Payment = 3 [json_name = "Payment"]; */
        if (message.payment)
            StatusCheckVM.internalBinaryWrite(message.payment, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskChecksVM
 */
export const KioskChecksVM = new KioskChecksVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationChecksVM$Type extends MessageType<LocationChecksVM> {
    constructor() {
        super("Mgmt.LocationChecksVM", [
            { no: 1, name: "OrderTypes", kind: "message", jsonName: "OrderTypes", T: () => StatusCheckVM },
            { no: 2, name: "Staff", kind: "message", jsonName: "Staff", T: () => StatusCheckVM }
        ]);
    }
    create(value?: PartialMessage<LocationChecksVM>): LocationChecksVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationChecksVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationChecksVM): LocationChecksVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.StatusCheckVM OrderTypes = 1 [json_name = "OrderTypes"];*/ 1:
                    message.orderTypes = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.orderTypes);
                    break;
                case /* Mgmt.StatusCheckVM Staff = 2 [json_name = "Staff"];*/ 2:
                    message.staff = StatusCheckVM.internalBinaryRead(reader, reader.uint32(), options, message.staff);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationChecksVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.StatusCheckVM OrderTypes = 1 [json_name = "OrderTypes"]; */
        if (message.orderTypes)
            StatusCheckVM.internalBinaryWrite(message.orderTypes, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.StatusCheckVM Staff = 2 [json_name = "Staff"]; */
        if (message.staff)
            StatusCheckVM.internalBinaryWrite(message.staff, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationChecksVM
 */
export const LocationChecksVM = new LocationChecksVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusCheckVM$Type extends MessageType<StatusCheckVM> {
    constructor() {
        super("Mgmt.StatusCheckVM", [
            { no: 1, name: "Status", kind: "enum", jsonName: "Status", T: () => ["Mgmt.SetupStatusEnum", SetupStatusEnum] },
            { no: 2, name: "Message", kind: "scalar", jsonName: "Message", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StatusCheckVM>): StatusCheckVM {
        const message = { status: 0, message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StatusCheckVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusCheckVM): StatusCheckVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.SetupStatusEnum Status = 1 [json_name = "Status"];*/ 1:
                    message.status = reader.int32();
                    break;
                case /* string Message = 2 [json_name = "Message"];*/ 2:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StatusCheckVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.SetupStatusEnum Status = 1 [json_name = "Status"]; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* string Message = 2 [json_name = "Message"]; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.StatusCheckVM
 */
export const StatusCheckVM = new StatusCheckVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffVM$Type extends MessageType<StaffVM> {
    constructor() {
        super("Mgmt.StaffVM", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StaffVM>): StaffVM {
        const message = { name: "", accessCode: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StaffVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StaffVM): StaffVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string accessCode */ 2:
                    message.accessCode = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StaffVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string accessCode = 2; */
        if (message.accessCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessCode);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.StaffVM
 */
export const StaffVM = new StaffVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationStaffVM$Type extends MessageType<LocationStaffVM> {
    constructor() {
        super("Mgmt.LocationStaffVM", [
            { no: 1, name: "staff", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StaffVM }
        ]);
    }
    create(value?: PartialMessage<LocationStaffVM>): LocationStaffVM {
        const message = { staff: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationStaffVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationStaffVM): LocationStaffVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.StaffVM staff */ 1:
                    message.staff.push(StaffVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationStaffVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.StaffVM staff = 1; */
        for (let i = 0; i < message.staff.length; i++)
            StaffVM.internalBinaryWrite(message.staff[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationStaffVM
 */
export const LocationStaffVM = new LocationStaffVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransformConfigVM$Type extends MessageType<TransformConfigVM> {
    constructor() {
        super("Mgmt.TransformConfigVM", [
            { no: 2, name: "menuWorkflowName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "menuWorkflowJson", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "orderWorkflowName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "orderWorkflowJson", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransformConfigVM>): TransformConfigVM {
        const message = { menuWorkflowName: "", menuWorkflowJson: "", orderWorkflowName: "", orderWorkflowJson: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransformConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransformConfigVM): TransformConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string menuWorkflowName */ 2:
                    message.menuWorkflowName = reader.string();
                    break;
                case /* string menuWorkflowJson */ 3:
                    message.menuWorkflowJson = reader.string();
                    break;
                case /* string orderWorkflowName */ 4:
                    message.orderWorkflowName = reader.string();
                    break;
                case /* string orderWorkflowJson */ 5:
                    message.orderWorkflowJson = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransformConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string menuWorkflowName = 2; */
        if (message.menuWorkflowName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.menuWorkflowName);
        /* string menuWorkflowJson = 3; */
        if (message.menuWorkflowJson !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.menuWorkflowJson);
        /* string orderWorkflowName = 4; */
        if (message.orderWorkflowName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.orderWorkflowName);
        /* string orderWorkflowJson = 5; */
        if (message.orderWorkflowJson !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.orderWorkflowJson);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TransformConfigVM
 */
export const TransformConfigVM = new TransformConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncIntegrationConfigVM$Type extends MessageType<PosSyncIntegrationConfigVM> {
    constructor() {
        super("Mgmt.PosSyncIntegrationConfigVM", [
            { no: 1, name: "posIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "posSyncSetting", kind: "message", T: () => PosSyncSettingVM },
            { no: 3, name: "kioskPushSetting", kind: "message", T: () => KioskPushSettingVM }
        ]);
    }
    create(value?: PartialMessage<PosSyncIntegrationConfigVM>): PosSyncIntegrationConfigVM {
        const message = { posIntegrationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncIntegrationConfigVM): PosSyncIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string posIntegrationId */ 1:
                    message.posIntegrationId = reader.string();
                    break;
                case /* Mgmt.PosSyncSettingVM posSyncSetting */ 2:
                    message.posSyncSetting = PosSyncSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncSetting);
                    break;
                case /* Mgmt.KioskPushSettingVM kioskPushSetting */ 3:
                    message.kioskPushSetting = KioskPushSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskPushSetting);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string posIntegrationId = 1; */
        if (message.posIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.posIntegrationId);
        /* Mgmt.PosSyncSettingVM posSyncSetting = 2; */
        if (message.posSyncSetting)
            PosSyncSettingVM.internalBinaryWrite(message.posSyncSetting, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskPushSettingVM kioskPushSetting = 3; */
        if (message.kioskPushSetting)
            KioskPushSettingVM.internalBinaryWrite(message.kioskPushSetting, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncIntegrationConfigVM
 */
export const PosSyncIntegrationConfigVM = new PosSyncIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncSettingVM$Type extends MessageType<PosSyncSettingVM> {
    constructor() {
        super("Mgmt.PosSyncSettingVM", [
            { no: 1, name: "menuItemOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "menuCategoryOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "modifierGroupOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "modifierOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PosSyncSettingVM>): PosSyncSettingVM {
        const message = { menuItemOverrides: false, menuCategoryOverrides: false, modifierGroupOverrides: false, modifierOverrides: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncSettingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncSettingVM): PosSyncSettingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool menuItemOverrides */ 1:
                    message.menuItemOverrides = reader.bool();
                    break;
                case /* bool menuCategoryOverrides */ 2:
                    message.menuCategoryOverrides = reader.bool();
                    break;
                case /* bool modifierGroupOverrides */ 3:
                    message.modifierGroupOverrides = reader.bool();
                    break;
                case /* bool modifierOverrides */ 4:
                    message.modifierOverrides = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncSettingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool menuItemOverrides = 1; */
        if (message.menuItemOverrides !== false)
            writer.tag(1, WireType.Varint).bool(message.menuItemOverrides);
        /* bool menuCategoryOverrides = 2; */
        if (message.menuCategoryOverrides !== false)
            writer.tag(2, WireType.Varint).bool(message.menuCategoryOverrides);
        /* bool modifierGroupOverrides = 3; */
        if (message.modifierGroupOverrides !== false)
            writer.tag(3, WireType.Varint).bool(message.modifierGroupOverrides);
        /* bool modifierOverrides = 4; */
        if (message.modifierOverrides !== false)
            writer.tag(4, WireType.Varint).bool(message.modifierOverrides);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncSettingVM
 */
export const PosSyncSettingVM = new PosSyncSettingVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskPushSettingVM$Type extends MessageType<KioskPushSettingVM> {
    constructor() {
        super("Mgmt.KioskPushSettingVM", [
            { no: 1, name: "kioskPriceUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "kioskFullMenuUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "kioskNoUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KioskPushSettingVM>): KioskPushSettingVM {
        const message = { kioskPriceUpdate: false, kioskFullMenuUpdate: false, kioskNoUpdate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskPushSettingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskPushSettingVM): KioskPushSettingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool kioskPriceUpdate */ 1:
                    message.kioskPriceUpdate = reader.bool();
                    break;
                case /* bool kioskFullMenuUpdate */ 2:
                    message.kioskFullMenuUpdate = reader.bool();
                    break;
                case /* bool kioskNoUpdate */ 3:
                    message.kioskNoUpdate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskPushSettingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool kioskPriceUpdate = 1; */
        if (message.kioskPriceUpdate !== false)
            writer.tag(1, WireType.Varint).bool(message.kioskPriceUpdate);
        /* bool kioskFullMenuUpdate = 2; */
        if (message.kioskFullMenuUpdate !== false)
            writer.tag(2, WireType.Varint).bool(message.kioskFullMenuUpdate);
        /* bool kioskNoUpdate = 3; */
        if (message.kioskNoUpdate !== false)
            writer.tag(3, WireType.Varint).bool(message.kioskNoUpdate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskPushSettingVM
 */
export const KioskPushSettingVM = new KioskPushSettingVM$Type();
