/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "common.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Mgmt.TenderOptionVM
 */
export interface TenderOptionVM {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message Mgmt.LocationRequest
 */
export interface LocationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
}
/**
 * @generated from protobuf message Mgmt.CompanyRequest
 */
export interface CompanyRequest {
    /**
     * @generated from protobuf field: string companyId = 1;
     */
    companyId: string;
}
/**
 * @generated from protobuf message Mgmt.EmptyResponse
 */
export interface EmptyResponse {
}
/**
 * @generated from protobuf message Mgmt.EmptyRequest
 */
export interface EmptyRequest {
}
/**
 * @generated from protobuf message Mgmt.FormFieldTypeVM
 */
export interface FormFieldTypeVM {
    /**
     * @generated from protobuf field: string defaultValue = 1;
     */
    defaultValue: string;
    /**
     * @generated from protobuf field: int32 minLength = 2;
     */
    minLength: number;
    /**
     * @generated from protobuf field: int32 maxLength = 3;
     */
    maxLength: number;
    /**
     * @generated from protobuf field: int32 minValue = 4;
     */
    minValue: number;
    /**
     * @generated from protobuf field: int32 maxValue = 5;
     */
    maxValue: number;
    /**
     * @generated from protobuf field: string formFieldValueType = 6;
     */
    formFieldValueType: string;
    /**
     * @generated from protobuf field: bool required = 7;
     */
    required: boolean;
    /**
     * @generated from protobuf field: string label = 8;
     */
    label: string;
}
/**
 * @generated from protobuf message Mgmt.FormValues
 */
export interface FormValues {
    /**
     * @generated from protobuf field: map<string, string> values = 1;
     */
    values: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Mgmt.ImageVM
 */
export interface ImageVM {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message Mgmt.AddressVM
 */
export interface AddressVM {
    /**
     * @generated from protobuf field: string address1 = 1;
     */
    address1: string;
    /**
     * @generated from protobuf field: string address2 = 2;
     */
    address2: string;
    /**
     * @generated from protobuf field: string countryISO = 3;
     */
    countryISO: string;
    /**
     * @generated from protobuf field: string state = 4;
     */
    state: string;
    /**
     * @generated from protobuf field: string city = 5;
     */
    city: string;
    /**
     * @generated from protobuf field: string zipCode = 6;
     */
    zipCode: string;
}
/**
 * @generated from protobuf message Mgmt.PhoneNumberVM
 */
export interface PhoneNumberVM {
    /**
     * @generated from protobuf field: string countryCode = 1;
     */
    countryCode: string;
    /**
     * @generated from protobuf field: string number = 2;
     */
    number: string;
}
/**
 * @generated from protobuf message Mgmt.SectionsTestVM
 */
export interface SectionsTestVM {
    /**
     * @generated from protobuf field: repeated string sections = 1;
     */
    sections: string[];
}
/**
 * @generated from protobuf message Mgmt.OrderTypeTestVM
 */
export interface OrderTypeTestVM {
    /**
     * @generated from protobuf field: repeated string orderTypes = 1;
     */
    orderTypes: string[];
}
/**
 * @generated from protobuf message Mgmt.TestVM
 */
export interface TestVM {
    /**
     * @generated from protobuf oneof: test
     */
    test: {
        oneofKind: "orderTypeTest";
        /**
         * @generated from protobuf field: Mgmt.OrderTypeTestVM orderTypeTest = 1;
         */
        orderTypeTest: OrderTypeTestVM;
    } | {
        oneofKind: "sectionsTest";
        /**
         * @generated from protobuf field: Mgmt.SectionsTestVM sectionsTest = 2;
         */
        sectionsTest: SectionsTestVM;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message Mgmt.ConditionsVM
 */
export interface ConditionsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.TestVM Tests = 1 [json_name = "Tests"];
     */
    tests: TestVM[];
}
/**
 * @generated from protobuf message Mgmt.FieldVM
 */
export interface FieldVM {
    /**
     * @generated from protobuf field: string defaultValue = 1;
     */
    defaultValue: string;
    /**
     * @generated from protobuf field: int32 minLength = 2;
     */
    minLength: number;
    /**
     * @generated from protobuf field: int32 maxLength = 3;
     */
    maxLength: number;
    /**
     * @generated from protobuf field: int32 minValue = 4;
     */
    minValue: number;
    /**
     * @generated from protobuf field: int32 maxValue = 5;
     */
    maxValue: number;
    /**
     * @generated from protobuf field: bool required = 7;
     */
    required: boolean;
    /**
     * @generated from protobuf field: string label = 8;
     */
    label: string;
}
/**
 * @generated from protobuf message Mgmt.FieldsVM
 */
export interface FieldsVM {
    /**
     * @generated from protobuf field: string defaultValue = 1;
     */
    defaultValue: string;
    /**
     * @generated from protobuf field: int32 minLength = 2;
     */
    minLength: number;
    /**
     * @generated from protobuf field: int32 maxLength = 3;
     */
    maxLength: number;
    /**
     * @generated from protobuf field: int32 minValue = 4;
     */
    minValue: number;
    /**
     * @generated from protobuf field: int32 maxValue = 5;
     */
    maxValue: number;
    /**
     * @generated from protobuf field: string formFieldValueType = 6;
     */
    formFieldValueType: string;
    /**
     * @generated from protobuf field: bool required = 7;
     */
    required: boolean;
    /**
     * @generated from protobuf field: string label = 8;
     */
    label: string;
}
/**
 * @generated from protobuf message Mgmt.DeviceLocationRequest
 */
export interface DeviceLocationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string deviceId = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message Mgmt.DeviceLocationResponse
 */
export interface DeviceLocationResponse {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message Mgmt.StringOverride
 */
export interface StringOverride {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: Mgmt.OverrideAction action = 2;
     */
    action: OverrideAction;
}
/**
 * @generated from protobuf message Mgmt.StringListOverride
 */
export interface StringListOverride {
    /**
     * @generated from protobuf field: repeated string value = 1;
     */
    value: string[];
    /**
     * @generated from protobuf field: Mgmt.OverrideAction action = 2;
     */
    action: OverrideAction;
}
/**
 * @generated from protobuf message Mgmt.IntOverride
 */
export interface IntOverride {
    /**
     * @generated from protobuf field: int32 value = 1;
     */
    value: number;
    /**
     * @generated from protobuf field: Mgmt.OverrideAction action = 2;
     */
    action: OverrideAction;
}
/**
 * @generated from protobuf message Mgmt.BoolOverride
 */
export interface BoolOverride {
    /**
     * @generated from protobuf field: bool value = 1;
     */
    value: boolean;
    /**
     * @generated from protobuf field: Mgmt.OverrideAction action = 2;
     */
    action: OverrideAction;
}
/**
 * @generated from protobuf message Mgmt.LocalizedStringVM
 */
export interface LocalizedStringVM {
    /**
     * @generated from protobuf field: map<string, string> translations = 1;
     */
    translations: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf enum Mgmt.OverrideAction
 */
export enum OverrideAction {
    /**
     * @generated from protobuf enum value: revert = 0;
     */
    revert = 0,
    /**
     * @generated from protobuf enum value: set = 1;
     */
    set = 1
}
/**
 * @generated from protobuf enum Mgmt.DayOfWeekVM
 */
export enum DayOfWeekVM {
    /**
     * @generated from protobuf enum value: SUNDAY = 0;
     */
    SUNDAY = 0,
    /**
     * @generated from protobuf enum value: MONDAY = 1;
     */
    MONDAY = 1,
    /**
     * @generated from protobuf enum value: TUESDAY = 2;
     */
    TUESDAY = 2,
    /**
     * @generated from protobuf enum value: WEDNESDAY = 3;
     */
    WEDNESDAY = 3,
    /**
     * @generated from protobuf enum value: THURSDAY = 4;
     */
    THURSDAY = 4,
    /**
     * @generated from protobuf enum value: FRIDAY = 5;
     */
    FRIDAY = 5,
    /**
     * @generated from protobuf enum value: SATURDAY = 6;
     */
    SATURDAY = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class TenderOptionVM$Type extends MessageType<TenderOptionVM> {
    constructor() {
        super("Mgmt.TenderOptionVM", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TenderOptionVM>): TenderOptionVM {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TenderOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TenderOptionVM): TenderOptionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TenderOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TenderOptionVM
 */
export const TenderOptionVM = new TenderOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationRequest$Type extends MessageType<LocationRequest> {
    constructor() {
        super("Mgmt.LocationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationRequest>): LocationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationRequest): LocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationRequest
 */
export const LocationRequest = new LocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompanyRequest$Type extends MessageType<CompanyRequest> {
    constructor() {
        super("Mgmt.CompanyRequest", [
            { no: 1, name: "companyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompanyRequest>): CompanyRequest {
        const message = { companyId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompanyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompanyRequest): CompanyRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string companyId */ 1:
                    message.companyId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompanyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string companyId = 1; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CompanyRequest
 */
export const CompanyRequest = new CompanyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmptyResponse$Type extends MessageType<EmptyResponse> {
    constructor() {
        super("Mgmt.EmptyResponse", []);
    }
    create(value?: PartialMessage<EmptyResponse>): EmptyResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmptyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmptyResponse): EmptyResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmptyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.EmptyResponse
 */
export const EmptyResponse = new EmptyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmptyRequest$Type extends MessageType<EmptyRequest> {
    constructor() {
        super("Mgmt.EmptyRequest", []);
    }
    create(value?: PartialMessage<EmptyRequest>): EmptyRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmptyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmptyRequest): EmptyRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmptyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.EmptyRequest
 */
export const EmptyRequest = new EmptyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormFieldTypeVM$Type extends MessageType<FormFieldTypeVM> {
    constructor() {
        super("Mgmt.FormFieldTypeVM", [
            { no: 1, name: "defaultValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "minLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "maxLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "minValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "maxValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "formFieldValueType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FormFieldTypeVM>): FormFieldTypeVM {
        const message = { defaultValue: "", minLength: 0, maxLength: 0, minValue: 0, maxValue: 0, formFieldValueType: "", required: false, label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormFieldTypeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormFieldTypeVM): FormFieldTypeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultValue */ 1:
                    message.defaultValue = reader.string();
                    break;
                case /* int32 minLength */ 2:
                    message.minLength = reader.int32();
                    break;
                case /* int32 maxLength */ 3:
                    message.maxLength = reader.int32();
                    break;
                case /* int32 minValue */ 4:
                    message.minValue = reader.int32();
                    break;
                case /* int32 maxValue */ 5:
                    message.maxValue = reader.int32();
                    break;
                case /* string formFieldValueType */ 6:
                    message.formFieldValueType = reader.string();
                    break;
                case /* bool required */ 7:
                    message.required = reader.bool();
                    break;
                case /* string label */ 8:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormFieldTypeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultValue = 1; */
        if (message.defaultValue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultValue);
        /* int32 minLength = 2; */
        if (message.minLength !== 0)
            writer.tag(2, WireType.Varint).int32(message.minLength);
        /* int32 maxLength = 3; */
        if (message.maxLength !== 0)
            writer.tag(3, WireType.Varint).int32(message.maxLength);
        /* int32 minValue = 4; */
        if (message.minValue !== 0)
            writer.tag(4, WireType.Varint).int32(message.minValue);
        /* int32 maxValue = 5; */
        if (message.maxValue !== 0)
            writer.tag(5, WireType.Varint).int32(message.maxValue);
        /* string formFieldValueType = 6; */
        if (message.formFieldValueType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.formFieldValueType);
        /* bool required = 7; */
        if (message.required !== false)
            writer.tag(7, WireType.Varint).bool(message.required);
        /* string label = 8; */
        if (message.label !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FormFieldTypeVM
 */
export const FormFieldTypeVM = new FormFieldTypeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormValues$Type extends MessageType<FormValues> {
    constructor() {
        super("Mgmt.FormValues", [
            { no: 1, name: "values", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<FormValues>): FormValues {
        const message = { values: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormValues): FormValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> values */ 1:
                    this.binaryReadMap1(message.values, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: FormValues["values"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FormValues["values"] | undefined, val: FormValues["values"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.FormValues.values");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: FormValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> values = 1; */
        for (let k of Object.keys(message.values))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.values[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FormValues
 */
export const FormValues = new FormValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageVM$Type extends MessageType<ImageVM> {
    constructor() {
        super("Mgmt.ImageVM", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ImageVM>): ImageVM {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImageVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImageVM): ImageVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImageVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ImageVM
 */
export const ImageVM = new ImageVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddressVM$Type extends MessageType<AddressVM> {
    constructor() {
        super("Mgmt.AddressVM", [
            { no: 1, name: "address1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "countryISO", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "zipCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddressVM>): AddressVM {
        const message = { address1: "", address2: "", countryISO: "", state: "", city: "", zipCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddressVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddressVM): AddressVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address1 */ 1:
                    message.address1 = reader.string();
                    break;
                case /* string address2 */ 2:
                    message.address2 = reader.string();
                    break;
                case /* string countryISO */ 3:
                    message.countryISO = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string city */ 5:
                    message.city = reader.string();
                    break;
                case /* string zipCode */ 6:
                    message.zipCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddressVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address1 = 1; */
        if (message.address1 !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address1);
        /* string address2 = 2; */
        if (message.address2 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address2);
        /* string countryISO = 3; */
        if (message.countryISO !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.countryISO);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string city = 5; */
        if (message.city !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.city);
        /* string zipCode = 6; */
        if (message.zipCode !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.zipCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddressVM
 */
export const AddressVM = new AddressVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhoneNumberVM$Type extends MessageType<PhoneNumberVM> {
    constructor() {
        super("Mgmt.PhoneNumberVM", [
            { no: 1, name: "countryCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PhoneNumberVM>): PhoneNumberVM {
        const message = { countryCode: "", number: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PhoneNumberVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhoneNumberVM): PhoneNumberVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string countryCode */ 1:
                    message.countryCode = reader.string();
                    break;
                case /* string number */ 2:
                    message.number = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhoneNumberVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string countryCode = 1; */
        if (message.countryCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.countryCode);
        /* string number = 2; */
        if (message.number !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PhoneNumberVM
 */
export const PhoneNumberVM = new PhoneNumberVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SectionsTestVM$Type extends MessageType<SectionsTestVM> {
    constructor() {
        super("Mgmt.SectionsTestVM", [
            { no: 1, name: "sections", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SectionsTestVM>): SectionsTestVM {
        const message = { sections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SectionsTestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionsTestVM): SectionsTestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string sections */ 1:
                    message.sections.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SectionsTestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string sections = 1; */
        for (let i = 0; i < message.sections.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.sections[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.SectionsTestVM
 */
export const SectionsTestVM = new SectionsTestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTypeTestVM$Type extends MessageType<OrderTypeTestVM> {
    constructor() {
        super("Mgmt.OrderTypeTestVM", [
            { no: 1, name: "orderTypes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OrderTypeTestVM>): OrderTypeTestVM {
        const message = { orderTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderTypeTestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTypeTestVM): OrderTypeTestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string orderTypes */ 1:
                    message.orderTypes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTypeTestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string orderTypes = 1; */
        for (let i = 0; i < message.orderTypes.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.orderTypes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderTypeTestVM
 */
export const OrderTypeTestVM = new OrderTypeTestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestVM$Type extends MessageType<TestVM> {
    constructor() {
        super("Mgmt.TestVM", [
            { no: 1, name: "orderTypeTest", kind: "message", oneof: "test", T: () => OrderTypeTestVM },
            { no: 2, name: "sectionsTest", kind: "message", oneof: "test", T: () => SectionsTestVM }
        ]);
    }
    create(value?: PartialMessage<TestVM>): TestVM {
        const message = { test: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TestVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestVM): TestVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.OrderTypeTestVM orderTypeTest */ 1:
                    message.test = {
                        oneofKind: "orderTypeTest",
                        orderTypeTest: OrderTypeTestVM.internalBinaryRead(reader, reader.uint32(), options, (message.test as any).orderTypeTest)
                    };
                    break;
                case /* Mgmt.SectionsTestVM sectionsTest */ 2:
                    message.test = {
                        oneofKind: "sectionsTest",
                        sectionsTest: SectionsTestVM.internalBinaryRead(reader, reader.uint32(), options, (message.test as any).sectionsTest)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TestVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.OrderTypeTestVM orderTypeTest = 1; */
        if (message.test.oneofKind === "orderTypeTest")
            OrderTypeTestVM.internalBinaryWrite(message.test.orderTypeTest, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.SectionsTestVM sectionsTest = 2; */
        if (message.test.oneofKind === "sectionsTest")
            SectionsTestVM.internalBinaryWrite(message.test.sectionsTest, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TestVM
 */
export const TestVM = new TestVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConditionsVM$Type extends MessageType<ConditionsVM> {
    constructor() {
        super("Mgmt.ConditionsVM", [
            { no: 1, name: "Tests", kind: "message", jsonName: "Tests", repeat: 1 /*RepeatType.PACKED*/, T: () => TestVM }
        ]);
    }
    create(value?: PartialMessage<ConditionsVM>): ConditionsVM {
        const message = { tests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConditionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConditionsVM): ConditionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.TestVM Tests = 1 [json_name = "Tests"];*/ 1:
                    message.tests.push(TestVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConditionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.TestVM Tests = 1 [json_name = "Tests"]; */
        for (let i = 0; i < message.tests.length; i++)
            TestVM.internalBinaryWrite(message.tests[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConditionsVM
 */
export const ConditionsVM = new ConditionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldVM$Type extends MessageType<FieldVM> {
    constructor() {
        super("Mgmt.FieldVM", [
            { no: 1, name: "defaultValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "minLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "maxLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "minValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "maxValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FieldVM>): FieldVM {
        const message = { defaultValue: "", minLength: 0, maxLength: 0, minValue: 0, maxValue: 0, required: false, label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FieldVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldVM): FieldVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultValue */ 1:
                    message.defaultValue = reader.string();
                    break;
                case /* int32 minLength */ 2:
                    message.minLength = reader.int32();
                    break;
                case /* int32 maxLength */ 3:
                    message.maxLength = reader.int32();
                    break;
                case /* int32 minValue */ 4:
                    message.minValue = reader.int32();
                    break;
                case /* int32 maxValue */ 5:
                    message.maxValue = reader.int32();
                    break;
                case /* bool required */ 7:
                    message.required = reader.bool();
                    break;
                case /* string label */ 8:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultValue = 1; */
        if (message.defaultValue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultValue);
        /* int32 minLength = 2; */
        if (message.minLength !== 0)
            writer.tag(2, WireType.Varint).int32(message.minLength);
        /* int32 maxLength = 3; */
        if (message.maxLength !== 0)
            writer.tag(3, WireType.Varint).int32(message.maxLength);
        /* int32 minValue = 4; */
        if (message.minValue !== 0)
            writer.tag(4, WireType.Varint).int32(message.minValue);
        /* int32 maxValue = 5; */
        if (message.maxValue !== 0)
            writer.tag(5, WireType.Varint).int32(message.maxValue);
        /* bool required = 7; */
        if (message.required !== false)
            writer.tag(7, WireType.Varint).bool(message.required);
        /* string label = 8; */
        if (message.label !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FieldVM
 */
export const FieldVM = new FieldVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldsVM$Type extends MessageType<FieldsVM> {
    constructor() {
        super("Mgmt.FieldsVM", [
            { no: 1, name: "defaultValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "minLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "maxLength", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "minValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "maxValue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "formFieldValueType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FieldsVM>): FieldsVM {
        const message = { defaultValue: "", minLength: 0, maxLength: 0, minValue: 0, maxValue: 0, formFieldValueType: "", required: false, label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FieldsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldsVM): FieldsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultValue */ 1:
                    message.defaultValue = reader.string();
                    break;
                case /* int32 minLength */ 2:
                    message.minLength = reader.int32();
                    break;
                case /* int32 maxLength */ 3:
                    message.maxLength = reader.int32();
                    break;
                case /* int32 minValue */ 4:
                    message.minValue = reader.int32();
                    break;
                case /* int32 maxValue */ 5:
                    message.maxValue = reader.int32();
                    break;
                case /* string formFieldValueType */ 6:
                    message.formFieldValueType = reader.string();
                    break;
                case /* bool required */ 7:
                    message.required = reader.bool();
                    break;
                case /* string label */ 8:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultValue = 1; */
        if (message.defaultValue !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultValue);
        /* int32 minLength = 2; */
        if (message.minLength !== 0)
            writer.tag(2, WireType.Varint).int32(message.minLength);
        /* int32 maxLength = 3; */
        if (message.maxLength !== 0)
            writer.tag(3, WireType.Varint).int32(message.maxLength);
        /* int32 minValue = 4; */
        if (message.minValue !== 0)
            writer.tag(4, WireType.Varint).int32(message.minValue);
        /* int32 maxValue = 5; */
        if (message.maxValue !== 0)
            writer.tag(5, WireType.Varint).int32(message.maxValue);
        /* string formFieldValueType = 6; */
        if (message.formFieldValueType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.formFieldValueType);
        /* bool required = 7; */
        if (message.required !== false)
            writer.tag(7, WireType.Varint).bool(message.required);
        /* string label = 8; */
        if (message.label !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FieldsVM
 */
export const FieldsVM = new FieldsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceLocationRequest$Type extends MessageType<DeviceLocationRequest> {
    constructor() {
        super("Mgmt.DeviceLocationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceLocationRequest>): DeviceLocationRequest {
        const message = { locationId: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceLocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceLocationRequest): DeviceLocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string deviceId */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceLocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string deviceId = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DeviceLocationRequest
 */
export const DeviceLocationRequest = new DeviceLocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceLocationResponse$Type extends MessageType<DeviceLocationResponse> {
    constructor() {
        super("Mgmt.DeviceLocationResponse", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceLocationResponse>): DeviceLocationResponse {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeviceLocationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceLocationResponse): DeviceLocationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceLocationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DeviceLocationResponse
 */
export const DeviceLocationResponse = new DeviceLocationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringOverride$Type extends MessageType<StringOverride> {
    constructor() {
        super("Mgmt.StringOverride", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "action", kind: "enum", T: () => ["Mgmt.OverrideAction", OverrideAction] }
        ]);
    }
    create(value?: PartialMessage<StringOverride>): StringOverride {
        const message = { value: "", action: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StringOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StringOverride): StringOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* Mgmt.OverrideAction action */ 2:
                    message.action = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StringOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* Mgmt.OverrideAction action = 2; */
        if (message.action !== 0)
            writer.tag(2, WireType.Varint).int32(message.action);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.StringOverride
 */
export const StringOverride = new StringOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringListOverride$Type extends MessageType<StringListOverride> {
    constructor() {
        super("Mgmt.StringListOverride", [
            { no: 1, name: "value", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "action", kind: "enum", T: () => ["Mgmt.OverrideAction", OverrideAction] }
        ]);
    }
    create(value?: PartialMessage<StringListOverride>): StringListOverride {
        const message = { value: [], action: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StringListOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StringListOverride): StringListOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string value */ 1:
                    message.value.push(reader.string());
                    break;
                case /* Mgmt.OverrideAction action */ 2:
                    message.action = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StringListOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string value = 1; */
        for (let i = 0; i < message.value.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.value[i]);
        /* Mgmt.OverrideAction action = 2; */
        if (message.action !== 0)
            writer.tag(2, WireType.Varint).int32(message.action);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.StringListOverride
 */
export const StringListOverride = new StringListOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntOverride$Type extends MessageType<IntOverride> {
    constructor() {
        super("Mgmt.IntOverride", [
            { no: 1, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "action", kind: "enum", T: () => ["Mgmt.OverrideAction", OverrideAction] }
        ]);
    }
    create(value?: PartialMessage<IntOverride>): IntOverride {
        const message = { value: 0, action: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IntOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntOverride): IntOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 value */ 1:
                    message.value = reader.int32();
                    break;
                case /* Mgmt.OverrideAction action */ 2:
                    message.action = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 value = 1; */
        if (message.value !== 0)
            writer.tag(1, WireType.Varint).int32(message.value);
        /* Mgmt.OverrideAction action = 2; */
        if (message.action !== 0)
            writer.tag(2, WireType.Varint).int32(message.action);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.IntOverride
 */
export const IntOverride = new IntOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoolOverride$Type extends MessageType<BoolOverride> {
    constructor() {
        super("Mgmt.BoolOverride", [
            { no: 1, name: "value", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "action", kind: "enum", T: () => ["Mgmt.OverrideAction", OverrideAction] }
        ]);
    }
    create(value?: PartialMessage<BoolOverride>): BoolOverride {
        const message = { value: false, action: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BoolOverride>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BoolOverride): BoolOverride {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool value */ 1:
                    message.value = reader.bool();
                    break;
                case /* Mgmt.OverrideAction action */ 2:
                    message.action = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BoolOverride, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool value = 1; */
        if (message.value !== false)
            writer.tag(1, WireType.Varint).bool(message.value);
        /* Mgmt.OverrideAction action = 2; */
        if (message.action !== 0)
            writer.tag(2, WireType.Varint).int32(message.action);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BoolOverride
 */
export const BoolOverride = new BoolOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizedStringVM$Type extends MessageType<LocalizedStringVM> {
    constructor() {
        super("Mgmt.LocalizedStringVM", [
            { no: 1, name: "translations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<LocalizedStringVM>): LocalizedStringVM {
        const message = { translations: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocalizedStringVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizedStringVM): LocalizedStringVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> translations */ 1:
                    this.binaryReadMap1(message.translations, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LocalizedStringVM["translations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LocalizedStringVM["translations"] | undefined, val: LocalizedStringVM["translations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.LocalizedStringVM.translations");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: LocalizedStringVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> translations = 1; */
        for (let k of Object.keys(message.translations))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.translations[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocalizedStringVM
 */
export const LocalizedStringVM = new LocalizedStringVM$Type();
