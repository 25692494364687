/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskConfigService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ConceptsResponse } from "./kioskConfig_pb";
import { EmptyResponse } from "./common_pb";
import { GratuityTendersVM } from "./kioskConfig_pb";
import { KioskConfigVM } from "./kioskConfig_pb";
import { LocationRequest } from "./common_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { SyncInterval } from "./kioskConfig_pb";
import { LegalCopyVM } from "./kioskConfig_pb";
import { DisclaimerConfigurationVM } from "./kioskConfig_pb";
import { OrderLimitsConfigVM } from "./kioskConfig_pb";
import { TipSettingsVM } from "./kioskConfig_pb";
import { BusinessHoursConfigurationVM } from "./kioskConfig_pb";
import { TimeoutSettingsVM } from "./kioskConfig_pb";
import { AgeVerificationSettingsVM } from "./kioskConfig_pb";
import { OrderTokenSettingsVM } from "./kioskConfig_pb";
import { PosOrderTypeVM } from "./kioskConfig_pb";
import { OrderTypeOptionVM } from "./kioskConfig_pb";
import { LocalizationVM } from "./kioskConfig_pb";
import { ConceptVM } from "./kioskConfig_pb";
import { BarcodeScannerConfigurationVM } from "./kioskConfig_pb";
import { MenuBehaviorConfigurationVM } from "./kioskConfig_pb";
/**
 * @generated from protobuf message Mgmt.FeatureFlagRequest
 */
export interface FeatureFlagRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: bool isEnabled = 2;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.UpdateMenuBehaviorConfigurationRequest
 */
export interface UpdateMenuBehaviorConfigurationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.MenuBehaviorConfigurationVM menuModifierBehavior = 2;
     */
    menuModifierBehavior?: MenuBehaviorConfigurationVM;
}
/**
 * @generated from protobuf message Mgmt.ConceptFeatureFlagRequest
 */
export interface ConceptFeatureFlagRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: bool isEnabled = 2;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.BarcodeScannerConfigurationRequest
 */
export interface BarcodeScannerConfigurationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.BarcodeScannerConfigurationVM config = 2;
     */
    config?: BarcodeScannerConfigurationVM;
}
/**
 * @generated from protobuf message Mgmt.AdaConfigurationRequest
 */
export interface AdaConfigurationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: bool adaModeEnabled = 2;
     */
    adaModeEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.LegalCopyFeatureFlagRequest
 */
export interface LegalCopyFeatureFlagRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: bool isEnabled = 2;
     */
    isEnabled: boolean;
}
/**
 * @generated from protobuf message Mgmt.RemoveConceptRequest
 */
export interface RemoveConceptRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string locationId = 2;
     */
    locationId: string;
}
/**
 * @generated from protobuf message Mgmt.ReorderConceptsRequest
 */
export interface ReorderConceptsRequest {
    /**
     * @generated from protobuf field: repeated string ordered_ids = 1;
     */
    orderedIds: string[];
    /**
     * @generated from protobuf field: string locationId = 2;
     */
    locationId: string;
}
/**
 * @generated from protobuf message Mgmt.ConceptRequest
 */
export interface ConceptRequest {
    /**
     * @generated from protobuf field: Mgmt.ConceptVM concept = 1;
     */
    concept?: ConceptVM;
    /**
     * @generated from protobuf field: string locationId = 2;
     */
    locationId: string;
}
/**
 * @generated from protobuf message Mgmt.LocalizationRequest
 */
export interface LocalizationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.LocalizationVM localization = 2;
     */
    localization?: LocalizationVM;
}
/**
 * @generated from protobuf message Mgmt.OrderTypesResponse
 */
export interface OrderTypesResponse {
    /**
     * @generated from protobuf field: repeated Mgmt.OrderTypeOptionVM options = 1;
     */
    options: OrderTypeOptionVM[];
    /**
     * @generated from protobuf field: repeated Mgmt.PosOrderTypeVM posOrderTypes = 2;
     */
    posOrderTypes: PosOrderTypeVM[];
}
/**
 * @generated from protobuf message Mgmt.UpsertOrderTypesRequest
 */
export interface UpsertOrderTypesRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: repeated Mgmt.OrderTypeOptionVM orderTypeOptions = 2;
     */
    orderTypeOptions: OrderTypeOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.UpsertOrderTokenRequest
 */
export interface UpsertOrderTokenRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.OrderTokenSettingsVM tokenSettings = 2;
     */
    tokenSettings?: OrderTokenSettingsVM;
}
/**
 * @generated from protobuf message Mgmt.AgeVerificationSettingsRequest
 */
export interface AgeVerificationSettingsRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.AgeVerificationSettingsVM settings = 2;
     */
    settings?: AgeVerificationSettingsVM;
}
/**
 * @generated from protobuf message Mgmt.TimeoutSettingsRequest
 */
export interface TimeoutSettingsRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.TimeoutSettingsVM settings = 2;
     */
    settings?: TimeoutSettingsVM;
}
/**
 * @generated from protobuf message Mgmt.BusinessHoursRequest
 */
export interface BusinessHoursRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.BusinessHoursConfigurationVM configuration = 2;
     */
    configuration?: BusinessHoursConfigurationVM;
}
/**
 * @generated from protobuf message Mgmt.TipSettingsRequest
 */
export interface TipSettingsRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.TipSettingsVM settings = 2;
     */
    settings?: TipSettingsVM;
}
/**
 * @generated from protobuf message Mgmt.OrderLimitsConfigRequest
 */
export interface OrderLimitsConfigRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.OrderLimitsConfigVM settings = 2;
     */
    settings?: OrderLimitsConfigVM;
}
/**
 * @generated from protobuf message Mgmt.DisclaimerConfigurationRequest
 */
export interface DisclaimerConfigurationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.DisclaimerConfigurationVM settings = 2;
     */
    settings?: DisclaimerConfigurationVM;
}
/**
 * @generated from protobuf message Mgmt.LegalCopyConfigurationRequest
 */
export interface LegalCopyConfigurationRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.LegalCopyVM settings = 2;
     */
    settings?: LegalCopyVM;
}
/**
 * @generated from protobuf message Mgmt.POSAutoAppliedFlagRes
 */
export interface POSAutoAppliedFlagRes {
    /**
     * @generated from protobuf field: bool posAutoAppliedDiscount = 1;
     */
    posAutoAppliedDiscount: boolean;
}
/**
 * @generated from protobuf message Mgmt.CheckPOSFlagRes
 */
export interface CheckPOSFlagRes {
    /**
     * @generated from protobuf field: bool checkPosFlow = 1;
     */
    checkPosFlow: boolean;
}
/**
 * @generated from protobuf message Mgmt.CalculateDefaultModiiferPriceFlagRes
 */
export interface CalculateDefaultModiiferPriceFlagRes {
    /**
     * @generated from protobuf field: bool calculateDefaultModifierPrice = 1;
     */
    calculateDefaultModifierPrice: boolean;
}
/**
 * @generated from protobuf message Mgmt.GetItem86SyncIntervalRes
 */
export interface GetItem86SyncIntervalRes {
    /**
     * @generated from protobuf field: Mgmt.SyncInterval intervel = 1;
     */
    intervel?: SyncInterval;
}
/**
 * @generated from protobuf message Mgmt.UpdateItem86SyncIntervalReq
 */
export interface UpdateItem86SyncIntervalReq {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.SyncInterval intervel = 2;
     */
    intervel?: SyncInterval;
}
// @generated message type with reflection information, may provide speed optimized methods
class FeatureFlagRequest$Type extends MessageType<FeatureFlagRequest> {
    constructor() {
        super("Mgmt.FeatureFlagRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FeatureFlagRequest>): FeatureFlagRequest {
        const message = { locationId: "", isEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FeatureFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeatureFlagRequest): FeatureFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* bool isEnabled */ 2:
                    message.isEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeatureFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* bool isEnabled = 2; */
        if (message.isEnabled !== false)
            writer.tag(2, WireType.Varint).bool(message.isEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.FeatureFlagRequest
 */
export const FeatureFlagRequest = new FeatureFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMenuBehaviorConfigurationRequest$Type extends MessageType<UpdateMenuBehaviorConfigurationRequest> {
    constructor() {
        super("Mgmt.UpdateMenuBehaviorConfigurationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "menuModifierBehavior", kind: "message", T: () => MenuBehaviorConfigurationVM }
        ]);
    }
    create(value?: PartialMessage<UpdateMenuBehaviorConfigurationRequest>): UpdateMenuBehaviorConfigurationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMenuBehaviorConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMenuBehaviorConfigurationRequest): UpdateMenuBehaviorConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.MenuBehaviorConfigurationVM menuModifierBehavior */ 2:
                    message.menuModifierBehavior = MenuBehaviorConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.menuModifierBehavior);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMenuBehaviorConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.MenuBehaviorConfigurationVM menuModifierBehavior = 2; */
        if (message.menuModifierBehavior)
            MenuBehaviorConfigurationVM.internalBinaryWrite(message.menuModifierBehavior, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateMenuBehaviorConfigurationRequest
 */
export const UpdateMenuBehaviorConfigurationRequest = new UpdateMenuBehaviorConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptFeatureFlagRequest$Type extends MessageType<ConceptFeatureFlagRequest> {
    constructor() {
        super("Mgmt.ConceptFeatureFlagRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ConceptFeatureFlagRequest>): ConceptFeatureFlagRequest {
        const message = { locationId: "", isEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptFeatureFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptFeatureFlagRequest): ConceptFeatureFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* bool isEnabled */ 2:
                    message.isEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptFeatureFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* bool isEnabled = 2; */
        if (message.isEnabled !== false)
            writer.tag(2, WireType.Varint).bool(message.isEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptFeatureFlagRequest
 */
export const ConceptFeatureFlagRequest = new ConceptFeatureFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BarcodeScannerConfigurationRequest$Type extends MessageType<BarcodeScannerConfigurationRequest> {
    constructor() {
        super("Mgmt.BarcodeScannerConfigurationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "config", kind: "message", T: () => BarcodeScannerConfigurationVM }
        ]);
    }
    create(value?: PartialMessage<BarcodeScannerConfigurationRequest>): BarcodeScannerConfigurationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BarcodeScannerConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BarcodeScannerConfigurationRequest): BarcodeScannerConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.BarcodeScannerConfigurationVM config */ 2:
                    message.config = BarcodeScannerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BarcodeScannerConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.BarcodeScannerConfigurationVM config = 2; */
        if (message.config)
            BarcodeScannerConfigurationVM.internalBinaryWrite(message.config, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BarcodeScannerConfigurationRequest
 */
export const BarcodeScannerConfigurationRequest = new BarcodeScannerConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaConfigurationRequest$Type extends MessageType<AdaConfigurationRequest> {
    constructor() {
        super("Mgmt.AdaConfigurationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "adaModeEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AdaConfigurationRequest>): AdaConfigurationRequest {
        const message = { locationId: "", adaModeEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdaConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdaConfigurationRequest): AdaConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* bool adaModeEnabled */ 2:
                    message.adaModeEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdaConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* bool adaModeEnabled = 2; */
        if (message.adaModeEnabled !== false)
            writer.tag(2, WireType.Varint).bool(message.adaModeEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AdaConfigurationRequest
 */
export const AdaConfigurationRequest = new AdaConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LegalCopyFeatureFlagRequest$Type extends MessageType<LegalCopyFeatureFlagRequest> {
    constructor() {
        super("Mgmt.LegalCopyFeatureFlagRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LegalCopyFeatureFlagRequest>): LegalCopyFeatureFlagRequest {
        const message = { locationId: "", isEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LegalCopyFeatureFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LegalCopyFeatureFlagRequest): LegalCopyFeatureFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* bool isEnabled */ 2:
                    message.isEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LegalCopyFeatureFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* bool isEnabled = 2; */
        if (message.isEnabled !== false)
            writer.tag(2, WireType.Varint).bool(message.isEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LegalCopyFeatureFlagRequest
 */
export const LegalCopyFeatureFlagRequest = new LegalCopyFeatureFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveConceptRequest$Type extends MessageType<RemoveConceptRequest> {
    constructor() {
        super("Mgmt.RemoveConceptRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveConceptRequest>): RemoveConceptRequest {
        const message = { id: "", locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveConceptRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveConceptRequest): RemoveConceptRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string locationId */ 2:
                    message.locationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveConceptRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string locationId = 2; */
        if (message.locationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RemoveConceptRequest
 */
export const RemoveConceptRequest = new RemoveConceptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReorderConceptsRequest$Type extends MessageType<ReorderConceptsRequest> {
    constructor() {
        super("Mgmt.ReorderConceptsRequest", [
            { no: 1, name: "ordered_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReorderConceptsRequest>): ReorderConceptsRequest {
        const message = { orderedIds: [], locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReorderConceptsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReorderConceptsRequest): ReorderConceptsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ordered_ids */ 1:
                    message.orderedIds.push(reader.string());
                    break;
                case /* string locationId */ 2:
                    message.locationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReorderConceptsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ordered_ids = 1; */
        for (let i = 0; i < message.orderedIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.orderedIds[i]);
        /* string locationId = 2; */
        if (message.locationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ReorderConceptsRequest
 */
export const ReorderConceptsRequest = new ReorderConceptsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConceptRequest$Type extends MessageType<ConceptRequest> {
    constructor() {
        super("Mgmt.ConceptRequest", [
            { no: 1, name: "concept", kind: "message", T: () => ConceptVM },
            { no: 2, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConceptRequest>): ConceptRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConceptRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConceptRequest): ConceptRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.ConceptVM concept */ 1:
                    message.concept = ConceptVM.internalBinaryRead(reader, reader.uint32(), options, message.concept);
                    break;
                case /* string locationId */ 2:
                    message.locationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConceptRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.ConceptVM concept = 1; */
        if (message.concept)
            ConceptVM.internalBinaryWrite(message.concept, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string locationId = 2; */
        if (message.locationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.ConceptRequest
 */
export const ConceptRequest = new ConceptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizationRequest$Type extends MessageType<LocalizationRequest> {
    constructor() {
        super("Mgmt.LocalizationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "localization", kind: "message", T: () => LocalizationVM }
        ]);
    }
    create(value?: PartialMessage<LocalizationRequest>): LocalizationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocalizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizationRequest): LocalizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.LocalizationVM localization */ 2:
                    message.localization = LocalizationVM.internalBinaryRead(reader, reader.uint32(), options, message.localization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocalizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.LocalizationVM localization = 2; */
        if (message.localization)
            LocalizationVM.internalBinaryWrite(message.localization, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocalizationRequest
 */
export const LocalizationRequest = new LocalizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderTypesResponse$Type extends MessageType<OrderTypesResponse> {
    constructor() {
        super("Mgmt.OrderTypesResponse", [
            { no: 1, name: "options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderTypeOptionVM },
            { no: 2, name: "posOrderTypes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PosOrderTypeVM }
        ]);
    }
    create(value?: PartialMessage<OrderTypesResponse>): OrderTypesResponse {
        const message = { options: [], posOrderTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderTypesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderTypesResponse): OrderTypesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.OrderTypeOptionVM options */ 1:
                    message.options.push(OrderTypeOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Mgmt.PosOrderTypeVM posOrderTypes */ 2:
                    message.posOrderTypes.push(PosOrderTypeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderTypesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.OrderTypeOptionVM options = 1; */
        for (let i = 0; i < message.options.length; i++)
            OrderTypeOptionVM.internalBinaryWrite(message.options[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.PosOrderTypeVM posOrderTypes = 2; */
        for (let i = 0; i < message.posOrderTypes.length; i++)
            PosOrderTypeVM.internalBinaryWrite(message.posOrderTypes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderTypesResponse
 */
export const OrderTypesResponse = new OrderTypesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertOrderTypesRequest$Type extends MessageType<UpsertOrderTypesRequest> {
    constructor() {
        super("Mgmt.UpsertOrderTypesRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "orderTypeOptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderTypeOptionVM }
        ]);
    }
    create(value?: PartialMessage<UpsertOrderTypesRequest>): UpsertOrderTypesRequest {
        const message = { locationId: "", orderTypeOptions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertOrderTypesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertOrderTypesRequest): UpsertOrderTypesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* repeated Mgmt.OrderTypeOptionVM orderTypeOptions */ 2:
                    message.orderTypeOptions.push(OrderTypeOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertOrderTypesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* repeated Mgmt.OrderTypeOptionVM orderTypeOptions = 2; */
        for (let i = 0; i < message.orderTypeOptions.length; i++)
            OrderTypeOptionVM.internalBinaryWrite(message.orderTypeOptions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpsertOrderTypesRequest
 */
export const UpsertOrderTypesRequest = new UpsertOrderTypesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertOrderTokenRequest$Type extends MessageType<UpsertOrderTokenRequest> {
    constructor() {
        super("Mgmt.UpsertOrderTokenRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tokenSettings", kind: "message", T: () => OrderTokenSettingsVM }
        ]);
    }
    create(value?: PartialMessage<UpsertOrderTokenRequest>): UpsertOrderTokenRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertOrderTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertOrderTokenRequest): UpsertOrderTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.OrderTokenSettingsVM tokenSettings */ 2:
                    message.tokenSettings = OrderTokenSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.tokenSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertOrderTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.OrderTokenSettingsVM tokenSettings = 2; */
        if (message.tokenSettings)
            OrderTokenSettingsVM.internalBinaryWrite(message.tokenSettings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpsertOrderTokenRequest
 */
export const UpsertOrderTokenRequest = new UpsertOrderTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgeVerificationSettingsRequest$Type extends MessageType<AgeVerificationSettingsRequest> {
    constructor() {
        super("Mgmt.AgeVerificationSettingsRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => AgeVerificationSettingsVM }
        ]);
    }
    create(value?: PartialMessage<AgeVerificationSettingsRequest>): AgeVerificationSettingsRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgeVerificationSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgeVerificationSettingsRequest): AgeVerificationSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.AgeVerificationSettingsVM settings */ 2:
                    message.settings = AgeVerificationSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgeVerificationSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.AgeVerificationSettingsVM settings = 2; */
        if (message.settings)
            AgeVerificationSettingsVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AgeVerificationSettingsRequest
 */
export const AgeVerificationSettingsRequest = new AgeVerificationSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoutSettingsRequest$Type extends MessageType<TimeoutSettingsRequest> {
    constructor() {
        super("Mgmt.TimeoutSettingsRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => TimeoutSettingsVM }
        ]);
    }
    create(value?: PartialMessage<TimeoutSettingsRequest>): TimeoutSettingsRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeoutSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeoutSettingsRequest): TimeoutSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.TimeoutSettingsVM settings */ 2:
                    message.settings = TimeoutSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeoutSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.TimeoutSettingsVM settings = 2; */
        if (message.settings)
            TimeoutSettingsVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TimeoutSettingsRequest
 */
export const TimeoutSettingsRequest = new TimeoutSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusinessHoursRequest$Type extends MessageType<BusinessHoursRequest> {
    constructor() {
        super("Mgmt.BusinessHoursRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "configuration", kind: "message", T: () => BusinessHoursConfigurationVM }
        ]);
    }
    create(value?: PartialMessage<BusinessHoursRequest>): BusinessHoursRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BusinessHoursRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusinessHoursRequest): BusinessHoursRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.BusinessHoursConfigurationVM configuration */ 2:
                    message.configuration = BusinessHoursConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.configuration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusinessHoursRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.BusinessHoursConfigurationVM configuration = 2; */
        if (message.configuration)
            BusinessHoursConfigurationVM.internalBinaryWrite(message.configuration, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BusinessHoursRequest
 */
export const BusinessHoursRequest = new BusinessHoursRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipSettingsRequest$Type extends MessageType<TipSettingsRequest> {
    constructor() {
        super("Mgmt.TipSettingsRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => TipSettingsVM }
        ]);
    }
    create(value?: PartialMessage<TipSettingsRequest>): TipSettingsRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipSettingsRequest): TipSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.TipSettingsVM settings */ 2:
                    message.settings = TipSettingsVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.TipSettingsVM settings = 2; */
        if (message.settings)
            TipSettingsVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.TipSettingsRequest
 */
export const TipSettingsRequest = new TipSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderLimitsConfigRequest$Type extends MessageType<OrderLimitsConfigRequest> {
    constructor() {
        super("Mgmt.OrderLimitsConfigRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => OrderLimitsConfigVM }
        ]);
    }
    create(value?: PartialMessage<OrderLimitsConfigRequest>): OrderLimitsConfigRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrderLimitsConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderLimitsConfigRequest): OrderLimitsConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.OrderLimitsConfigVM settings */ 2:
                    message.settings = OrderLimitsConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderLimitsConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.OrderLimitsConfigVM settings = 2; */
        if (message.settings)
            OrderLimitsConfigVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.OrderLimitsConfigRequest
 */
export const OrderLimitsConfigRequest = new OrderLimitsConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisclaimerConfigurationRequest$Type extends MessageType<DisclaimerConfigurationRequest> {
    constructor() {
        super("Mgmt.DisclaimerConfigurationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => DisclaimerConfigurationVM }
        ]);
    }
    create(value?: PartialMessage<DisclaimerConfigurationRequest>): DisclaimerConfigurationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisclaimerConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisclaimerConfigurationRequest): DisclaimerConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.DisclaimerConfigurationVM settings */ 2:
                    message.settings = DisclaimerConfigurationVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisclaimerConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.DisclaimerConfigurationVM settings = 2; */
        if (message.settings)
            DisclaimerConfigurationVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DisclaimerConfigurationRequest
 */
export const DisclaimerConfigurationRequest = new DisclaimerConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LegalCopyConfigurationRequest$Type extends MessageType<LegalCopyConfigurationRequest> {
    constructor() {
        super("Mgmt.LegalCopyConfigurationRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "settings", kind: "message", T: () => LegalCopyVM }
        ]);
    }
    create(value?: PartialMessage<LegalCopyConfigurationRequest>): LegalCopyConfigurationRequest {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LegalCopyConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LegalCopyConfigurationRequest): LegalCopyConfigurationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.LegalCopyVM settings */ 2:
                    message.settings = LegalCopyVM.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LegalCopyConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.LegalCopyVM settings = 2; */
        if (message.settings)
            LegalCopyVM.internalBinaryWrite(message.settings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LegalCopyConfigurationRequest
 */
export const LegalCopyConfigurationRequest = new LegalCopyConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class POSAutoAppliedFlagRes$Type extends MessageType<POSAutoAppliedFlagRes> {
    constructor() {
        super("Mgmt.POSAutoAppliedFlagRes", [
            { no: 1, name: "posAutoAppliedDiscount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<POSAutoAppliedFlagRes>): POSAutoAppliedFlagRes {
        const message = { posAutoAppliedDiscount: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<POSAutoAppliedFlagRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: POSAutoAppliedFlagRes): POSAutoAppliedFlagRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool posAutoAppliedDiscount */ 1:
                    message.posAutoAppliedDiscount = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: POSAutoAppliedFlagRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool posAutoAppliedDiscount = 1; */
        if (message.posAutoAppliedDiscount !== false)
            writer.tag(1, WireType.Varint).bool(message.posAutoAppliedDiscount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.POSAutoAppliedFlagRes
 */
export const POSAutoAppliedFlagRes = new POSAutoAppliedFlagRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckPOSFlagRes$Type extends MessageType<CheckPOSFlagRes> {
    constructor() {
        super("Mgmt.CheckPOSFlagRes", [
            { no: 1, name: "checkPosFlow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckPOSFlagRes>): CheckPOSFlagRes {
        const message = { checkPosFlow: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckPOSFlagRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckPOSFlagRes): CheckPOSFlagRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool checkPosFlow */ 1:
                    message.checkPosFlow = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckPOSFlagRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool checkPosFlow = 1; */
        if (message.checkPosFlow !== false)
            writer.tag(1, WireType.Varint).bool(message.checkPosFlow);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CheckPOSFlagRes
 */
export const CheckPOSFlagRes = new CheckPOSFlagRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateDefaultModiiferPriceFlagRes$Type extends MessageType<CalculateDefaultModiiferPriceFlagRes> {
    constructor() {
        super("Mgmt.CalculateDefaultModiiferPriceFlagRes", [
            { no: 1, name: "calculateDefaultModifierPrice", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CalculateDefaultModiiferPriceFlagRes>): CalculateDefaultModiiferPriceFlagRes {
        const message = { calculateDefaultModifierPrice: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateDefaultModiiferPriceFlagRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateDefaultModiiferPriceFlagRes): CalculateDefaultModiiferPriceFlagRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool calculateDefaultModifierPrice */ 1:
                    message.calculateDefaultModifierPrice = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalculateDefaultModiiferPriceFlagRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool calculateDefaultModifierPrice = 1; */
        if (message.calculateDefaultModifierPrice !== false)
            writer.tag(1, WireType.Varint).bool(message.calculateDefaultModifierPrice);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CalculateDefaultModiiferPriceFlagRes
 */
export const CalculateDefaultModiiferPriceFlagRes = new CalculateDefaultModiiferPriceFlagRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetItem86SyncIntervalRes$Type extends MessageType<GetItem86SyncIntervalRes> {
    constructor() {
        super("Mgmt.GetItem86SyncIntervalRes", [
            { no: 1, name: "intervel", kind: "message", T: () => SyncInterval }
        ]);
    }
    create(value?: PartialMessage<GetItem86SyncIntervalRes>): GetItem86SyncIntervalRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetItem86SyncIntervalRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetItem86SyncIntervalRes): GetItem86SyncIntervalRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.SyncInterval intervel */ 1:
                    message.intervel = SyncInterval.internalBinaryRead(reader, reader.uint32(), options, message.intervel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetItem86SyncIntervalRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.SyncInterval intervel = 1; */
        if (message.intervel)
            SyncInterval.internalBinaryWrite(message.intervel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GetItem86SyncIntervalRes
 */
export const GetItem86SyncIntervalRes = new GetItem86SyncIntervalRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateItem86SyncIntervalReq$Type extends MessageType<UpdateItem86SyncIntervalReq> {
    constructor() {
        super("Mgmt.UpdateItem86SyncIntervalReq", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "intervel", kind: "message", T: () => SyncInterval }
        ]);
    }
    create(value?: PartialMessage<UpdateItem86SyncIntervalReq>): UpdateItem86SyncIntervalReq {
        const message = { locationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateItem86SyncIntervalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateItem86SyncIntervalReq): UpdateItem86SyncIntervalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.SyncInterval intervel */ 2:
                    message.intervel = SyncInterval.internalBinaryRead(reader, reader.uint32(), options, message.intervel);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateItem86SyncIntervalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.SyncInterval intervel = 2; */
        if (message.intervel)
            SyncInterval.internalBinaryWrite(message.intervel, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateItem86SyncIntervalReq
 */
export const UpdateItem86SyncIntervalReq = new UpdateItem86SyncIntervalReq$Type();
/**
 * @generated ServiceType for protobuf service Mgmt.KioskConfigService
 */
export const KioskConfigService = new ServiceType("Mgmt.KioskConfigService", [
    { name: "GetKioskConfig", options: {}, I: LocationRequest, O: KioskConfigVM },
    { name: "GetOrderTypes", options: {}, I: LocationRequest, O: OrderTypesResponse },
    { name: "UpsertOrderTypes", options: {}, I: UpsertOrderTypesRequest, O: OrderTypesResponse },
    { name: "GetOrderToken", options: {}, I: LocationRequest, O: OrderTokenSettingsVM },
    { name: "UpsertOrderToken", options: {}, I: UpsertOrderTokenRequest, O: OrderTokenSettingsVM },
    { name: "GetLocalization", options: {}, I: LocationRequest, O: LocalizationVM },
    { name: "UpsertLocalization", options: {}, I: LocalizationRequest, O: LocalizationVM },
    { name: "GetAgeVerificationSettings", options: {}, I: LocationRequest, O: AgeVerificationSettingsVM },
    { name: "UpsertAgeVerificationSettings", options: {}, I: AgeVerificationSettingsRequest, O: AgeVerificationSettingsVM },
    { name: "GetTimeoutSettings", options: {}, I: LocationRequest, O: TimeoutSettingsVM },
    { name: "UpsertTimeoutSettings", options: {}, I: TimeoutSettingsRequest, O: TimeoutSettingsVM },
    { name: "GetGratuityTenders", options: {}, I: LocationRequest, O: GratuityTendersVM },
    { name: "GetTipSettings", options: {}, I: LocationRequest, O: TipSettingsVM },
    { name: "UpsertTipSettings", options: {}, I: TipSettingsRequest, O: TipSettingsVM },
    { name: "GetOrderLimitsConfig", options: {}, I: LocationRequest, O: OrderLimitsConfigVM },
    { name: "UpsertOrderLimitsConfig", options: {}, I: OrderLimitsConfigRequest, O: OrderLimitsConfigVM },
    { name: "UpdateBarcodeScannerConfiguration", options: {}, I: BarcodeScannerConfigurationRequest, O: EmptyResponse },
    { name: "UpdateAdaConfiguration", options: {}, I: AdaConfigurationRequest, O: EmptyResponse },
    { name: "UpdateLegalCopyFeatureFlag", options: {}, I: LegalCopyFeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateConceptFeatureFlag", options: {}, I: ConceptFeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateShowCaloriesFeatureFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateItemSpecialRequestFeatureFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateDiscountsFeatureFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateMenuBehaviorConfiguration", options: {}, I: UpdateMenuBehaviorConfigurationRequest, O: EmptyResponse },
    { name: "UpdateTrackKioskUserBehaviorFeatureFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "GetConcepts", options: {}, I: LocationRequest, O: ConceptsResponse },
    { name: "CreateConcept", options: {}, I: ConceptRequest, O: ConceptsResponse },
    { name: "UpdateConcept", options: {}, I: ConceptRequest, O: ConceptsResponse },
    { name: "RemoveConcept", options: {}, I: RemoveConceptRequest, O: EmptyResponse },
    { name: "ReorderConcepts", options: {}, I: ReorderConceptsRequest, O: ConceptsResponse },
    { name: "GetBusinessHoursConfiguration", options: {}, I: LocationRequest, O: BusinessHoursConfigurationVM },
    { name: "UpsertBusinessHoursConfiguration", options: {}, I: BusinessHoursRequest, O: BusinessHoursConfigurationVM },
    { name: "GetDisclaimerConfiguration", options: {}, I: LocationRequest, O: DisclaimerConfigurationVM },
    { name: "UpsertDisclaimerConfiguration", options: {}, I: DisclaimerConfigurationRequest, O: DisclaimerConfigurationVM },
    { name: "GetLegalCopyConfiguration", options: {}, I: LocationRequest, O: LegalCopyVM },
    { name: "UpsertLegalCopyConfiguration", options: {}, I: LegalCopyConfigurationRequest, O: LegalCopyVM },
    { name: "GetPosAutoAppliedDiscountFlag", options: {}, I: LocationRequest, O: POSAutoAppliedFlagRes },
    { name: "UpdatePosAutoAppliedDiscountFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "GetCheckPosFlowFlag", options: {}, I: LocationRequest, O: CheckPOSFlagRes },
    { name: "UpdateCheckPosFlowFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "GetCalculateDefaultModifierPriceFlag", options: {}, I: LocationRequest, O: CalculateDefaultModiiferPriceFlagRes },
    { name: "UpdateCalculateDefaultModifierPriceFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "UpdateLoyaltyFeatureFlag", options: {}, I: FeatureFlagRequest, O: EmptyResponse },
    { name: "GetItem86SyncInterval", options: {}, I: LocationRequest, O: GetItem86SyncIntervalRes },
    { name: "UpdateItem86SyncInterval", options: {}, I: UpdateItem86SyncIntervalReq, O: EmptyResponse }
]);
