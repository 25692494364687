/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "paymentService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PaymentService } from "./paymentService_pb";
import type { EmptyResponse } from "./common_pb";
import type { UpdateKioskPaymentConfigRequest } from "./paymentService_pb";
import type { KioskRequest } from "./paymentService_pb";
import type { PaymentIntegrationConfigListVM } from "./payment_pb";
import type { PaymentIntegrationConfigVM } from "./payment_pb";
import type { LocationPaymentConfigRequest } from "./paymentService_pb";
import type { PaymentTendersVM } from "./payment_pb";
import type { PaymentRequest } from "./paymentService_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { PaymentIntegrationsVM } from "./payment_pb";
import type { LocationRequest } from "./common_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Mgmt.PaymentService
 */
export interface IPaymentServiceClient {
    /**
     * @generated from protobuf rpc: GetLocationPaymentIntegrations(Mgmt.LocationRequest) returns (Mgmt.PaymentIntegrationsVM);
     */
    getLocationPaymentIntegrations(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PaymentIntegrationsVM>;
    /**
     * @generated from protobuf rpc: GetLocationPaymentTenders(Mgmt.PaymentRequest) returns (Mgmt.PaymentTendersVM);
     */
    getLocationPaymentTenders(input: PaymentRequest, options?: RpcOptions): UnaryCall<PaymentRequest, PaymentTendersVM>;
    /**
     * @generated from protobuf rpc: UpdateLocationPaymentIntegrationConfig(Mgmt.LocationPaymentConfigRequest) returns (Mgmt.PaymentIntegrationConfigVM);
     */
    updateLocationPaymentIntegrationConfig(input: LocationPaymentConfigRequest, options?: RpcOptions): UnaryCall<LocationPaymentConfigRequest, PaymentIntegrationConfigVM>;
    /**
     * @generated from protobuf rpc: RemoveLocationPaymentIntegration(Mgmt.PaymentRequest) returns (Mgmt.PaymentIntegrationConfigListVM);
     */
    removeLocationPaymentIntegration(input: PaymentRequest, options?: RpcOptions): UnaryCall<PaymentRequest, PaymentIntegrationConfigListVM>;
    /**
     * @generated from protobuf rpc: GetKioskPaymentIntegrations(Mgmt.KioskRequest) returns (Mgmt.PaymentIntegrationsVM);
     */
    getKioskPaymentIntegrations(input: KioskRequest, options?: RpcOptions): UnaryCall<KioskRequest, PaymentIntegrationsVM>;
    /**
     * @generated from protobuf rpc: UpdateKioskPaymentIntegrations(Mgmt.UpdateKioskPaymentConfigRequest) returns (Mgmt.EmptyResponse);
     */
    updateKioskPaymentIntegrations(input: UpdateKioskPaymentConfigRequest, options?: RpcOptions): UnaryCall<UpdateKioskPaymentConfigRequest, EmptyResponse>;
}
/**
 * @generated from protobuf service Mgmt.PaymentService
 */
export class PaymentServiceClient implements IPaymentServiceClient, ServiceInfo {
    typeName = PaymentService.typeName;
    methods = PaymentService.methods;
    options = PaymentService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetLocationPaymentIntegrations(Mgmt.LocationRequest) returns (Mgmt.PaymentIntegrationsVM);
     */
    getLocationPaymentIntegrations(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PaymentIntegrationsVM> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, PaymentIntegrationsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationPaymentTenders(Mgmt.PaymentRequest) returns (Mgmt.PaymentTendersVM);
     */
    getLocationPaymentTenders(input: PaymentRequest, options?: RpcOptions): UnaryCall<PaymentRequest, PaymentTendersVM> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<PaymentRequest, PaymentTendersVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationPaymentIntegrationConfig(Mgmt.LocationPaymentConfigRequest) returns (Mgmt.PaymentIntegrationConfigVM);
     */
    updateLocationPaymentIntegrationConfig(input: LocationPaymentConfigRequest, options?: RpcOptions): UnaryCall<LocationPaymentConfigRequest, PaymentIntegrationConfigVM> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationPaymentConfigRequest, PaymentIntegrationConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveLocationPaymentIntegration(Mgmt.PaymentRequest) returns (Mgmt.PaymentIntegrationConfigListVM);
     */
    removeLocationPaymentIntegration(input: PaymentRequest, options?: RpcOptions): UnaryCall<PaymentRequest, PaymentIntegrationConfigListVM> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<PaymentRequest, PaymentIntegrationConfigListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetKioskPaymentIntegrations(Mgmt.KioskRequest) returns (Mgmt.PaymentIntegrationsVM);
     */
    getKioskPaymentIntegrations(input: KioskRequest, options?: RpcOptions): UnaryCall<KioskRequest, PaymentIntegrationsVM> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<KioskRequest, PaymentIntegrationsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateKioskPaymentIntegrations(Mgmt.UpdateKioskPaymentConfigRequest) returns (Mgmt.EmptyResponse);
     */
    updateKioskPaymentIntegrations(input: UpdateKioskPaymentConfigRequest, options?: RpcOptions): UnaryCall<UpdateKioskPaymentConfigRequest, EmptyResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateKioskPaymentConfigRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
}
