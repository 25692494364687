/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "locationsService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LocationsService } from "./locationsService_pb";
import type { PosSyncIntegrationConfigVM } from "./locations_pb";
import type { UpdatePosSyncRequest } from "./locationsService_pb";
import type { GEMAccessTokenResponse } from "./locationsService_pb";
import type { GEMAccessTokenRequest } from "./locationsService_pb";
import type { AddTransformConfigRequest } from "./locationsService_pb";
import type { TransformConfigVM } from "./locations_pb";
import type { RemoveStaffRequest } from "./locationsService_pb";
import type { LocationStaffVM } from "./locations_pb";
import type { AddStaffRequest } from "./locationsService_pb";
import type { RemoveLocationUserAccessRequest } from "./locationsService_pb";
import type { UpdateLocationUserAccessRequest } from "./locationsService_pb";
import type { AddLocationUserAccessRequest } from "./locationsService_pb";
import type { AccessControlListVM } from "./accessList_pb";
import type { UpsertLocationRequest } from "./locationsService_pb";
import type { AddLocationRequest } from "./locationsService_pb";
import type { EmptyResponse } from "./common_pb";
import type { UpdateLocationBaseRequest } from "./locationsService_pb";
import type { PosIntegrationConfigVM } from "./locations_pb";
import type { UpdatePosRequest } from "./locationsService_pb";
import type { PosIntegrationMenuSettingsVM } from "./locations_pb";
import type { PosIntegrationVM } from "./locations_pb";
import type { LocationSetupStatusVM } from "./locations_pb";
import type { LocationBaseVM } from "./locations_pb";
import type { LocationDetailVM } from "./locations_pb";
import type { LocationRequest } from "./common_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { LocationsVM } from "./locations_pb";
import type { CompanyRequest } from "./common_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Mgmt.LocationsService
 */
export interface ILocationsServiceClient {
    /**
     * @generated from protobuf rpc: GetLocations(Mgmt.CompanyRequest) returns (Mgmt.LocationsVM);
     */
    getLocations(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, LocationsVM>;
    /**
     * @generated from protobuf rpc: GetLocationDetails(Mgmt.LocationRequest) returns (Mgmt.LocationDetailVM);
     */
    getLocationDetails(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationDetailVM>;
    /**
     * @generated from protobuf rpc: GetLocationSummary(Mgmt.LocationRequest) returns (Mgmt.LocationBaseVM);
     */
    getLocationSummary(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationBaseVM>;
    /**
     * @generated from protobuf rpc: GetLocationSetupStatus(Mgmt.LocationRequest) returns (Mgmt.LocationSetupStatusVM);
     */
    getLocationSetupStatus(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationSetupStatusVM>;
    /**
     * @generated from protobuf rpc: GetLocationPosSettings(Mgmt.LocationRequest) returns (Mgmt.PosIntegrationVM);
     */
    getLocationPosSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PosIntegrationVM>;
    /**
     * @generated from protobuf rpc: GetLocationPosMenuSettings(Mgmt.LocationRequest) returns (Mgmt.PosIntegrationMenuSettingsVM);
     */
    getLocationPosMenuSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PosIntegrationMenuSettingsVM>;
    /**
     * @generated from protobuf rpc: UpdateLocationPos(Mgmt.UpdatePosRequest) returns (Mgmt.PosIntegrationConfigVM);
     */
    updateLocationPos(input: UpdatePosRequest, options?: RpcOptions): UnaryCall<UpdatePosRequest, PosIntegrationConfigVM>;
    /**
     * @generated from protobuf rpc: RemoveLocationPos(Mgmt.UpdatePosRequest) returns (Mgmt.PosIntegrationConfigVM);
     */
    removeLocationPos(input: UpdatePosRequest, options?: RpcOptions): UnaryCall<UpdatePosRequest, PosIntegrationConfigVM>;
    /**
     * @generated from protobuf rpc: UpdateLocationBase(Mgmt.UpdateLocationBaseRequest) returns (Mgmt.LocationBaseVM);
     */
    updateLocationBase(input: UpdateLocationBaseRequest, options?: RpcOptions): UnaryCall<UpdateLocationBaseRequest, LocationBaseVM>;
    /**
     * @generated from protobuf rpc: ProxyRemoteSync(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    proxyRemoteSync(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: PullLatestPosServiceSnapshot(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    pullLatestPosServiceSnapshot(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse>;
    /**
     * Location CRUD
     *
     * @generated from protobuf rpc: AddLocation(Mgmt.AddLocationRequest) returns (Mgmt.LocationDetailVM);
     */
    addLocation(input: AddLocationRequest, options?: RpcOptions): UnaryCall<AddLocationRequest, LocationDetailVM>;
    /**
     * @generated from protobuf rpc: UpdateLocation(Mgmt.UpsertLocationRequest) returns (Mgmt.LocationDetailVM);
     */
    updateLocation(input: UpsertLocationRequest, options?: RpcOptions): UnaryCall<UpsertLocationRequest, LocationDetailVM>;
    /**
     * @generated from protobuf rpc: RemoveLocation(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    removeLocation(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetLocationUserAccessList(Mgmt.LocationRequest) returns (Mgmt.AccessControlListVM);
     */
    getLocationUserAccessList(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: AddUserAccess(Mgmt.AddLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    addUserAccess(input: AddLocationUserAccessRequest, options?: RpcOptions): UnaryCall<AddLocationUserAccessRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: UpdateUserAccess(Mgmt.UpdateLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    updateUserAccess(input: UpdateLocationUserAccessRequest, options?: RpcOptions): UnaryCall<UpdateLocationUserAccessRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: RemoveUserAccess(Mgmt.RemoveLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    removeUserAccess(input: RemoveLocationUserAccessRequest, options?: RpcOptions): UnaryCall<RemoveLocationUserAccessRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: AddStaff(Mgmt.AddStaffRequest) returns (Mgmt.LocationStaffVM);
     */
    addStaff(input: AddStaffRequest, options?: RpcOptions): UnaryCall<AddStaffRequest, LocationStaffVM>;
    /**
     * @generated from protobuf rpc: RemoveStaff(Mgmt.RemoveStaffRequest) returns (Mgmt.LocationStaffVM);
     */
    removeStaff(input: RemoveStaffRequest, options?: RpcOptions): UnaryCall<RemoveStaffRequest, LocationStaffVM>;
    /**
     * @generated from protobuf rpc: GetStaff(Mgmt.LocationRequest) returns (Mgmt.LocationStaffVM);
     */
    getStaff(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationStaffVM>;
    /**
     * @generated from protobuf rpc: GetTransformConfig(Mgmt.LocationRequest) returns (Mgmt.TransformConfigVM);
     */
    getTransformConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TransformConfigVM>;
    /**
     * @generated from protobuf rpc: AddTransformConfig(Mgmt.AddTransformConfigRequest) returns (Mgmt.EmptyResponse);
     */
    addTransformConfig(input: AddTransformConfigRequest, options?: RpcOptions): UnaryCall<AddTransformConfigRequest, EmptyResponse>;
    /**
     * GEM
     *
     * @generated from protobuf rpc: GetGEMAccessToken(Mgmt.GEMAccessTokenRequest) returns (Mgmt.GEMAccessTokenResponse);
     */
    getGEMAccessToken(input: GEMAccessTokenRequest, options?: RpcOptions): UnaryCall<GEMAccessTokenRequest, GEMAccessTokenResponse>;
    /**
     * Sync
     *
     * @generated from protobuf rpc: UpdatePosSyncSetting(Mgmt.UpdatePosSyncRequest) returns (Mgmt.PosSyncIntegrationConfigVM);
     */
    updatePosSyncSetting(input: UpdatePosSyncRequest, options?: RpcOptions): UnaryCall<UpdatePosSyncRequest, PosSyncIntegrationConfigVM>;
}
/**
 * @generated from protobuf service Mgmt.LocationsService
 */
export class LocationsServiceClient implements ILocationsServiceClient, ServiceInfo {
    typeName = LocationsService.typeName;
    methods = LocationsService.methods;
    options = LocationsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetLocations(Mgmt.CompanyRequest) returns (Mgmt.LocationsVM);
     */
    getLocations(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, LocationsVM> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompanyRequest, LocationsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationDetails(Mgmt.LocationRequest) returns (Mgmt.LocationDetailVM);
     */
    getLocationDetails(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationDetailVM> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LocationDetailVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationSummary(Mgmt.LocationRequest) returns (Mgmt.LocationBaseVM);
     */
    getLocationSummary(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationBaseVM> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LocationBaseVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationSetupStatus(Mgmt.LocationRequest) returns (Mgmt.LocationSetupStatusVM);
     */
    getLocationSetupStatus(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationSetupStatusVM> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LocationSetupStatusVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationPosSettings(Mgmt.LocationRequest) returns (Mgmt.PosIntegrationVM);
     */
    getLocationPosSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PosIntegrationVM> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, PosIntegrationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationPosMenuSettings(Mgmt.LocationRequest) returns (Mgmt.PosIntegrationMenuSettingsVM);
     */
    getLocationPosMenuSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, PosIntegrationMenuSettingsVM> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, PosIntegrationMenuSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationPos(Mgmt.UpdatePosRequest) returns (Mgmt.PosIntegrationConfigVM);
     */
    updateLocationPos(input: UpdatePosRequest, options?: RpcOptions): UnaryCall<UpdatePosRequest, PosIntegrationConfigVM> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePosRequest, PosIntegrationConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveLocationPos(Mgmt.UpdatePosRequest) returns (Mgmt.PosIntegrationConfigVM);
     */
    removeLocationPos(input: UpdatePosRequest, options?: RpcOptions): UnaryCall<UpdatePosRequest, PosIntegrationConfigVM> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePosRequest, PosIntegrationConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationBase(Mgmt.UpdateLocationBaseRequest) returns (Mgmt.LocationBaseVM);
     */
    updateLocationBase(input: UpdateLocationBaseRequest, options?: RpcOptions): UnaryCall<UpdateLocationBaseRequest, LocationBaseVM> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationBaseRequest, LocationBaseVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ProxyRemoteSync(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    proxyRemoteSync(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PullLatestPosServiceSnapshot(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    pullLatestPosServiceSnapshot(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Location CRUD
     *
     * @generated from protobuf rpc: AddLocation(Mgmt.AddLocationRequest) returns (Mgmt.LocationDetailVM);
     */
    addLocation(input: AddLocationRequest, options?: RpcOptions): UnaryCall<AddLocationRequest, LocationDetailVM> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddLocationRequest, LocationDetailVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocation(Mgmt.UpsertLocationRequest) returns (Mgmt.LocationDetailVM);
     */
    updateLocation(input: UpsertLocationRequest, options?: RpcOptions): UnaryCall<UpsertLocationRequest, LocationDetailVM> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertLocationRequest, LocationDetailVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveLocation(Mgmt.LocationRequest) returns (Mgmt.EmptyResponse);
     */
    removeLocation(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, EmptyResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocationUserAccessList(Mgmt.LocationRequest) returns (Mgmt.AccessControlListVM);
     */
    getLocationUserAccessList(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, AccessControlListVM> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddUserAccess(Mgmt.AddLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    addUserAccess(input: AddLocationUserAccessRequest, options?: RpcOptions): UnaryCall<AddLocationUserAccessRequest, AccessControlListVM> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddLocationUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserAccess(Mgmt.UpdateLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    updateUserAccess(input: UpdateLocationUserAccessRequest, options?: RpcOptions): UnaryCall<UpdateLocationUserAccessRequest, AccessControlListVM> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveUserAccess(Mgmt.RemoveLocationUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    removeUserAccess(input: RemoveLocationUserAccessRequest, options?: RpcOptions): UnaryCall<RemoveLocationUserAccessRequest, AccessControlListVM> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveLocationUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddStaff(Mgmt.AddStaffRequest) returns (Mgmt.LocationStaffVM);
     */
    addStaff(input: AddStaffRequest, options?: RpcOptions): UnaryCall<AddStaffRequest, LocationStaffVM> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddStaffRequest, LocationStaffVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveStaff(Mgmt.RemoveStaffRequest) returns (Mgmt.LocationStaffVM);
     */
    removeStaff(input: RemoveStaffRequest, options?: RpcOptions): UnaryCall<RemoveStaffRequest, LocationStaffVM> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveStaffRequest, LocationStaffVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStaff(Mgmt.LocationRequest) returns (Mgmt.LocationStaffVM);
     */
    getStaff(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocationStaffVM> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LocationStaffVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransformConfig(Mgmt.LocationRequest) returns (Mgmt.TransformConfigVM);
     */
    getTransformConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TransformConfigVM> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, TransformConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddTransformConfig(Mgmt.AddTransformConfigRequest) returns (Mgmt.EmptyResponse);
     */
    addTransformConfig(input: AddTransformConfigRequest, options?: RpcOptions): UnaryCall<AddTransformConfigRequest, EmptyResponse> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddTransformConfigRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GEM
     *
     * @generated from protobuf rpc: GetGEMAccessToken(Mgmt.GEMAccessTokenRequest) returns (Mgmt.GEMAccessTokenResponse);
     */
    getGEMAccessToken(input: GEMAccessTokenRequest, options?: RpcOptions): UnaryCall<GEMAccessTokenRequest, GEMAccessTokenResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GEMAccessTokenRequest, GEMAccessTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Sync
     *
     * @generated from protobuf rpc: UpdatePosSyncSetting(Mgmt.UpdatePosSyncRequest) returns (Mgmt.PosSyncIntegrationConfigVM);
     */
    updatePosSyncSetting(input: UpdatePosSyncRequest, options?: RpcOptions): UnaryCall<UpdatePosSyncRequest, PosSyncIntegrationConfigVM> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePosSyncRequest, PosSyncIntegrationConfigVM>("unary", this._transport, method, opt, input);
    }
}
