/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "kioskConfigService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { KioskConfigService } from "./kioskConfigService_pb";
import type { UpdateItem86SyncIntervalReq } from "./kioskConfigService_pb";
import type { GetItem86SyncIntervalRes } from "./kioskConfigService_pb";
import type { CalculateDefaultModiiferPriceFlagRes } from "./kioskConfigService_pb";
import type { CheckPOSFlagRes } from "./kioskConfigService_pb";
import type { POSAutoAppliedFlagRes } from "./kioskConfigService_pb";
import type { LegalCopyConfigurationRequest } from "./kioskConfigService_pb";
import type { LegalCopyVM } from "./kioskConfig_pb";
import type { DisclaimerConfigurationRequest } from "./kioskConfigService_pb";
import type { DisclaimerConfigurationVM } from "./kioskConfig_pb";
import type { BusinessHoursRequest } from "./kioskConfigService_pb";
import type { BusinessHoursConfigurationVM } from "./kioskConfig_pb";
import type { ReorderConceptsRequest } from "./kioskConfigService_pb";
import type { RemoveConceptRequest } from "./kioskConfigService_pb";
import type { ConceptRequest } from "./kioskConfigService_pb";
import type { ConceptsResponse } from "./kioskConfig_pb";
import type { UpdateMenuBehaviorConfigurationRequest } from "./kioskConfigService_pb";
import type { FeatureFlagRequest } from "./kioskConfigService_pb";
import type { ConceptFeatureFlagRequest } from "./kioskConfigService_pb";
import type { LegalCopyFeatureFlagRequest } from "./kioskConfigService_pb";
import type { AdaConfigurationRequest } from "./kioskConfigService_pb";
import type { EmptyResponse } from "./common_pb";
import type { BarcodeScannerConfigurationRequest } from "./kioskConfigService_pb";
import type { OrderLimitsConfigRequest } from "./kioskConfigService_pb";
import type { OrderLimitsConfigVM } from "./kioskConfig_pb";
import type { TipSettingsRequest } from "./kioskConfigService_pb";
import type { TipSettingsVM } from "./kioskConfig_pb";
import type { GratuityTendersVM } from "./kioskConfig_pb";
import type { TimeoutSettingsRequest } from "./kioskConfigService_pb";
import type { TimeoutSettingsVM } from "./kioskConfig_pb";
import type { AgeVerificationSettingsRequest } from "./kioskConfigService_pb";
import type { AgeVerificationSettingsVM } from "./kioskConfig_pb";
import type { LocalizationRequest } from "./kioskConfigService_pb";
import type { LocalizationVM } from "./kioskConfig_pb";
import type { UpsertOrderTokenRequest } from "./kioskConfigService_pb";
import type { OrderTokenSettingsVM } from "./kioskConfig_pb";
import type { UpsertOrderTypesRequest } from "./kioskConfigService_pb";
import type { OrderTypesResponse } from "./kioskConfigService_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { KioskConfigVM } from "./kioskConfig_pb";
import type { LocationRequest } from "./common_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Mgmt.KioskConfigService
 */
export interface IKioskConfigServiceClient {
    /**
     * @generated from protobuf rpc: GetKioskConfig(Mgmt.LocationRequest) returns (Mgmt.KioskConfigVM);
     */
    getKioskConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, KioskConfigVM>;
    /**
     * @generated from protobuf rpc: GetOrderTypes(Mgmt.LocationRequest) returns (Mgmt.OrderTypesResponse);
     */
    getOrderTypes(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderTypesResponse>;
    /**
     * @generated from protobuf rpc: UpsertOrderTypes(Mgmt.UpsertOrderTypesRequest) returns (Mgmt.OrderTypesResponse);
     */
    upsertOrderTypes(input: UpsertOrderTypesRequest, options?: RpcOptions): UnaryCall<UpsertOrderTypesRequest, OrderTypesResponse>;
    /**
     * @generated from protobuf rpc: GetOrderToken(Mgmt.LocationRequest) returns (Mgmt.OrderTokenSettingsVM);
     */
    getOrderToken(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderTokenSettingsVM>;
    /**
     * @generated from protobuf rpc: UpsertOrderToken(Mgmt.UpsertOrderTokenRequest) returns (Mgmt.OrderTokenSettingsVM);
     */
    upsertOrderToken(input: UpsertOrderTokenRequest, options?: RpcOptions): UnaryCall<UpsertOrderTokenRequest, OrderTokenSettingsVM>;
    /**
     * @generated from protobuf rpc: GetLocalization(Mgmt.LocationRequest) returns (Mgmt.LocalizationVM);
     */
    getLocalization(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocalizationVM>;
    /**
     * @generated from protobuf rpc: UpsertLocalization(Mgmt.LocalizationRequest) returns (Mgmt.LocalizationVM);
     */
    upsertLocalization(input: LocalizationRequest, options?: RpcOptions): UnaryCall<LocalizationRequest, LocalizationVM>;
    /**
     * @generated from protobuf rpc: GetAgeVerificationSettings(Mgmt.LocationRequest) returns (Mgmt.AgeVerificationSettingsVM);
     */
    getAgeVerificationSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, AgeVerificationSettingsVM>;
    /**
     * @generated from protobuf rpc: UpsertAgeVerificationSettings(Mgmt.AgeVerificationSettingsRequest) returns (Mgmt.AgeVerificationSettingsVM);
     */
    upsertAgeVerificationSettings(input: AgeVerificationSettingsRequest, options?: RpcOptions): UnaryCall<AgeVerificationSettingsRequest, AgeVerificationSettingsVM>;
    /**
     * @generated from protobuf rpc: GetTimeoutSettings(Mgmt.LocationRequest) returns (Mgmt.TimeoutSettingsVM);
     */
    getTimeoutSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TimeoutSettingsVM>;
    /**
     * @generated from protobuf rpc: UpsertTimeoutSettings(Mgmt.TimeoutSettingsRequest) returns (Mgmt.TimeoutSettingsVM);
     */
    upsertTimeoutSettings(input: TimeoutSettingsRequest, options?: RpcOptions): UnaryCall<TimeoutSettingsRequest, TimeoutSettingsVM>;
    /**
     * @generated from protobuf rpc: GetGratuityTenders(Mgmt.LocationRequest) returns (Mgmt.GratuityTendersVM);
     */
    getGratuityTenders(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, GratuityTendersVM>;
    /**
     * @generated from protobuf rpc: GetTipSettings(Mgmt.LocationRequest) returns (Mgmt.TipSettingsVM);
     */
    getTipSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TipSettingsVM>;
    /**
     * @generated from protobuf rpc: UpsertTipSettings(Mgmt.TipSettingsRequest) returns (Mgmt.TipSettingsVM);
     */
    upsertTipSettings(input: TipSettingsRequest, options?: RpcOptions): UnaryCall<TipSettingsRequest, TipSettingsVM>;
    /**
     * @generated from protobuf rpc: GetOrderLimitsConfig(Mgmt.LocationRequest) returns (Mgmt.OrderLimitsConfigVM);
     */
    getOrderLimitsConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderLimitsConfigVM>;
    /**
     * @generated from protobuf rpc: UpsertOrderLimitsConfig(Mgmt.OrderLimitsConfigRequest) returns (Mgmt.OrderLimitsConfigVM);
     */
    upsertOrderLimitsConfig(input: OrderLimitsConfigRequest, options?: RpcOptions): UnaryCall<OrderLimitsConfigRequest, OrderLimitsConfigVM>;
    /**
     * @generated from protobuf rpc: UpdateBarcodeScannerConfiguration(Mgmt.BarcodeScannerConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateBarcodeScannerConfiguration(input: BarcodeScannerConfigurationRequest, options?: RpcOptions): UnaryCall<BarcodeScannerConfigurationRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateAdaConfiguration(Mgmt.AdaConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateAdaConfiguration(input: AdaConfigurationRequest, options?: RpcOptions): UnaryCall<AdaConfigurationRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateLegalCopyFeatureFlag(Mgmt.LegalCopyFeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateLegalCopyFeatureFlag(input: LegalCopyFeatureFlagRequest, options?: RpcOptions): UnaryCall<LegalCopyFeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateConceptFeatureFlag(Mgmt.ConceptFeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateConceptFeatureFlag(input: ConceptFeatureFlagRequest, options?: RpcOptions): UnaryCall<ConceptFeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateShowCaloriesFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateShowCaloriesFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateItemSpecialRequestFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateItemSpecialRequestFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateDiscountsFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateDiscountsFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateMenuBehaviorConfiguration(Mgmt.UpdateMenuBehaviorConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateMenuBehaviorConfiguration(input: UpdateMenuBehaviorConfigurationRequest, options?: RpcOptions): UnaryCall<UpdateMenuBehaviorConfigurationRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateTrackKioskUserBehaviorFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateTrackKioskUserBehaviorFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetConcepts(Mgmt.LocationRequest) returns (Mgmt.ConceptsResponse);
     */
    getConcepts(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, ConceptsResponse>;
    /**
     * @generated from protobuf rpc: CreateConcept(Mgmt.ConceptRequest) returns (Mgmt.ConceptsResponse);
     */
    createConcept(input: ConceptRequest, options?: RpcOptions): UnaryCall<ConceptRequest, ConceptsResponse>;
    /**
     * @generated from protobuf rpc: UpdateConcept(Mgmt.ConceptRequest) returns (Mgmt.ConceptsResponse);
     */
    updateConcept(input: ConceptRequest, options?: RpcOptions): UnaryCall<ConceptRequest, ConceptsResponse>;
    /**
     * @generated from protobuf rpc: RemoveConcept(Mgmt.RemoveConceptRequest) returns (Mgmt.EmptyResponse);
     */
    removeConcept(input: RemoveConceptRequest, options?: RpcOptions): UnaryCall<RemoveConceptRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: ReorderConcepts(Mgmt.ReorderConceptsRequest) returns (Mgmt.ConceptsResponse);
     */
    reorderConcepts(input: ReorderConceptsRequest, options?: RpcOptions): UnaryCall<ReorderConceptsRequest, ConceptsResponse>;
    /**
     * @generated from protobuf rpc: GetBusinessHoursConfiguration(Mgmt.LocationRequest) returns (Mgmt.BusinessHoursConfigurationVM);
     */
    getBusinessHoursConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, BusinessHoursConfigurationVM>;
    /**
     * @generated from protobuf rpc: UpsertBusinessHoursConfiguration(Mgmt.BusinessHoursRequest) returns (Mgmt.BusinessHoursConfigurationVM);
     */
    upsertBusinessHoursConfiguration(input: BusinessHoursRequest, options?: RpcOptions): UnaryCall<BusinessHoursRequest, BusinessHoursConfigurationVM>;
    /**
     * @generated from protobuf rpc: GetDisclaimerConfiguration(Mgmt.LocationRequest) returns (Mgmt.DisclaimerConfigurationVM);
     */
    getDisclaimerConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, DisclaimerConfigurationVM>;
    /**
     * @generated from protobuf rpc: UpsertDisclaimerConfiguration(Mgmt.DisclaimerConfigurationRequest) returns (Mgmt.DisclaimerConfigurationVM);
     */
    upsertDisclaimerConfiguration(input: DisclaimerConfigurationRequest, options?: RpcOptions): UnaryCall<DisclaimerConfigurationRequest, DisclaimerConfigurationVM>;
    /**
     * @generated from protobuf rpc: GetLegalCopyConfiguration(Mgmt.LocationRequest) returns (Mgmt.LegalCopyVM);
     */
    getLegalCopyConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LegalCopyVM>;
    /**
     * @generated from protobuf rpc: UpsertLegalCopyConfiguration(Mgmt.LegalCopyConfigurationRequest) returns (Mgmt.LegalCopyVM);
     */
    upsertLegalCopyConfiguration(input: LegalCopyConfigurationRequest, options?: RpcOptions): UnaryCall<LegalCopyConfigurationRequest, LegalCopyVM>;
    /**
     * @generated from protobuf rpc: GetPosAutoAppliedDiscountFlag(Mgmt.LocationRequest) returns (Mgmt.POSAutoAppliedFlagRes);
     */
    getPosAutoAppliedDiscountFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, POSAutoAppliedFlagRes>;
    /**
     * @generated from protobuf rpc: UpdatePosAutoAppliedDiscountFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updatePosAutoAppliedDiscountFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetCheckPosFlowFlag(Mgmt.LocationRequest) returns (Mgmt.CheckPOSFlagRes);
     */
    getCheckPosFlowFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, CheckPOSFlagRes>;
    /**
     * @generated from protobuf rpc: UpdateCheckPosFlowFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateCheckPosFlowFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetCalculateDefaultModifierPriceFlag(Mgmt.LocationRequest) returns (Mgmt.CalculateDefaultModiiferPriceFlagRes);
     */
    getCalculateDefaultModifierPriceFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, CalculateDefaultModiiferPriceFlagRes>;
    /**
     * @generated from protobuf rpc: UpdateCalculateDefaultModifierPriceFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateCalculateDefaultModifierPriceFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: UpdateLoyaltyFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateLoyaltyFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetItem86SyncInterval(Mgmt.LocationRequest) returns (Mgmt.GetItem86SyncIntervalRes);
     */
    getItem86SyncInterval(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, GetItem86SyncIntervalRes>;
    /**
     * @generated from protobuf rpc: UpdateItem86SyncInterval(Mgmt.UpdateItem86SyncIntervalReq) returns (Mgmt.EmptyResponse);
     */
    updateItem86SyncInterval(input: UpdateItem86SyncIntervalReq, options?: RpcOptions): UnaryCall<UpdateItem86SyncIntervalReq, EmptyResponse>;
}
/**
 * @generated from protobuf service Mgmt.KioskConfigService
 */
export class KioskConfigServiceClient implements IKioskConfigServiceClient, ServiceInfo {
    typeName = KioskConfigService.typeName;
    methods = KioskConfigService.methods;
    options = KioskConfigService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetKioskConfig(Mgmt.LocationRequest) returns (Mgmt.KioskConfigVM);
     */
    getKioskConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, KioskConfigVM> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, KioskConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrderTypes(Mgmt.LocationRequest) returns (Mgmt.OrderTypesResponse);
     */
    getOrderTypes(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderTypesResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, OrderTypesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertOrderTypes(Mgmt.UpsertOrderTypesRequest) returns (Mgmt.OrderTypesResponse);
     */
    upsertOrderTypes(input: UpsertOrderTypesRequest, options?: RpcOptions): UnaryCall<UpsertOrderTypesRequest, OrderTypesResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertOrderTypesRequest, OrderTypesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrderToken(Mgmt.LocationRequest) returns (Mgmt.OrderTokenSettingsVM);
     */
    getOrderToken(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderTokenSettingsVM> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, OrderTokenSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertOrderToken(Mgmt.UpsertOrderTokenRequest) returns (Mgmt.OrderTokenSettingsVM);
     */
    upsertOrderToken(input: UpsertOrderTokenRequest, options?: RpcOptions): UnaryCall<UpsertOrderTokenRequest, OrderTokenSettingsVM> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertOrderTokenRequest, OrderTokenSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLocalization(Mgmt.LocationRequest) returns (Mgmt.LocalizationVM);
     */
    getLocalization(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LocalizationVM> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LocalizationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertLocalization(Mgmt.LocalizationRequest) returns (Mgmt.LocalizationVM);
     */
    upsertLocalization(input: LocalizationRequest, options?: RpcOptions): UnaryCall<LocalizationRequest, LocalizationVM> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocalizationRequest, LocalizationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgeVerificationSettings(Mgmt.LocationRequest) returns (Mgmt.AgeVerificationSettingsVM);
     */
    getAgeVerificationSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, AgeVerificationSettingsVM> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, AgeVerificationSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertAgeVerificationSettings(Mgmt.AgeVerificationSettingsRequest) returns (Mgmt.AgeVerificationSettingsVM);
     */
    upsertAgeVerificationSettings(input: AgeVerificationSettingsRequest, options?: RpcOptions): UnaryCall<AgeVerificationSettingsRequest, AgeVerificationSettingsVM> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<AgeVerificationSettingsRequest, AgeVerificationSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeoutSettings(Mgmt.LocationRequest) returns (Mgmt.TimeoutSettingsVM);
     */
    getTimeoutSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TimeoutSettingsVM> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, TimeoutSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertTimeoutSettings(Mgmt.TimeoutSettingsRequest) returns (Mgmt.TimeoutSettingsVM);
     */
    upsertTimeoutSettings(input: TimeoutSettingsRequest, options?: RpcOptions): UnaryCall<TimeoutSettingsRequest, TimeoutSettingsVM> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<TimeoutSettingsRequest, TimeoutSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGratuityTenders(Mgmt.LocationRequest) returns (Mgmt.GratuityTendersVM);
     */
    getGratuityTenders(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, GratuityTendersVM> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, GratuityTendersVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTipSettings(Mgmt.LocationRequest) returns (Mgmt.TipSettingsVM);
     */
    getTipSettings(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, TipSettingsVM> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, TipSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertTipSettings(Mgmt.TipSettingsRequest) returns (Mgmt.TipSettingsVM);
     */
    upsertTipSettings(input: TipSettingsRequest, options?: RpcOptions): UnaryCall<TipSettingsRequest, TipSettingsVM> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<TipSettingsRequest, TipSettingsVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrderLimitsConfig(Mgmt.LocationRequest) returns (Mgmt.OrderLimitsConfigVM);
     */
    getOrderLimitsConfig(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, OrderLimitsConfigVM> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, OrderLimitsConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertOrderLimitsConfig(Mgmt.OrderLimitsConfigRequest) returns (Mgmt.OrderLimitsConfigVM);
     */
    upsertOrderLimitsConfig(input: OrderLimitsConfigRequest, options?: RpcOptions): UnaryCall<OrderLimitsConfigRequest, OrderLimitsConfigVM> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<OrderLimitsConfigRequest, OrderLimitsConfigVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBarcodeScannerConfiguration(Mgmt.BarcodeScannerConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateBarcodeScannerConfiguration(input: BarcodeScannerConfigurationRequest, options?: RpcOptions): UnaryCall<BarcodeScannerConfigurationRequest, EmptyResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<BarcodeScannerConfigurationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateAdaConfiguration(Mgmt.AdaConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateAdaConfiguration(input: AdaConfigurationRequest, options?: RpcOptions): UnaryCall<AdaConfigurationRequest, EmptyResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdaConfigurationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLegalCopyFeatureFlag(Mgmt.LegalCopyFeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateLegalCopyFeatureFlag(input: LegalCopyFeatureFlagRequest, options?: RpcOptions): UnaryCall<LegalCopyFeatureFlagRequest, EmptyResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<LegalCopyFeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateConceptFeatureFlag(Mgmt.ConceptFeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateConceptFeatureFlag(input: ConceptFeatureFlagRequest, options?: RpcOptions): UnaryCall<ConceptFeatureFlagRequest, EmptyResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConceptFeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateShowCaloriesFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateShowCaloriesFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateItemSpecialRequestFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateItemSpecialRequestFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDiscountsFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateDiscountsFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMenuBehaviorConfiguration(Mgmt.UpdateMenuBehaviorConfigurationRequest) returns (Mgmt.EmptyResponse);
     */
    updateMenuBehaviorConfiguration(input: UpdateMenuBehaviorConfigurationRequest, options?: RpcOptions): UnaryCall<UpdateMenuBehaviorConfigurationRequest, EmptyResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMenuBehaviorConfigurationRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTrackKioskUserBehaviorFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateTrackKioskUserBehaviorFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConcepts(Mgmt.LocationRequest) returns (Mgmt.ConceptsResponse);
     */
    getConcepts(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, ConceptsResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, ConceptsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateConcept(Mgmt.ConceptRequest) returns (Mgmt.ConceptsResponse);
     */
    createConcept(input: ConceptRequest, options?: RpcOptions): UnaryCall<ConceptRequest, ConceptsResponse> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConceptRequest, ConceptsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateConcept(Mgmt.ConceptRequest) returns (Mgmt.ConceptsResponse);
     */
    updateConcept(input: ConceptRequest, options?: RpcOptions): UnaryCall<ConceptRequest, ConceptsResponse> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConceptRequest, ConceptsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveConcept(Mgmt.RemoveConceptRequest) returns (Mgmt.EmptyResponse);
     */
    removeConcept(input: RemoveConceptRequest, options?: RpcOptions): UnaryCall<RemoveConceptRequest, EmptyResponse> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveConceptRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderConcepts(Mgmt.ReorderConceptsRequest) returns (Mgmt.ConceptsResponse);
     */
    reorderConcepts(input: ReorderConceptsRequest, options?: RpcOptions): UnaryCall<ReorderConceptsRequest, ConceptsResponse> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderConceptsRequest, ConceptsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBusinessHoursConfiguration(Mgmt.LocationRequest) returns (Mgmt.BusinessHoursConfigurationVM);
     */
    getBusinessHoursConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, BusinessHoursConfigurationVM> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, BusinessHoursConfigurationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertBusinessHoursConfiguration(Mgmt.BusinessHoursRequest) returns (Mgmt.BusinessHoursConfigurationVM);
     */
    upsertBusinessHoursConfiguration(input: BusinessHoursRequest, options?: RpcOptions): UnaryCall<BusinessHoursRequest, BusinessHoursConfigurationVM> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<BusinessHoursRequest, BusinessHoursConfigurationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDisclaimerConfiguration(Mgmt.LocationRequest) returns (Mgmt.DisclaimerConfigurationVM);
     */
    getDisclaimerConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, DisclaimerConfigurationVM> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, DisclaimerConfigurationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertDisclaimerConfiguration(Mgmt.DisclaimerConfigurationRequest) returns (Mgmt.DisclaimerConfigurationVM);
     */
    upsertDisclaimerConfiguration(input: DisclaimerConfigurationRequest, options?: RpcOptions): UnaryCall<DisclaimerConfigurationRequest, DisclaimerConfigurationVM> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisclaimerConfigurationRequest, DisclaimerConfigurationVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLegalCopyConfiguration(Mgmt.LocationRequest) returns (Mgmt.LegalCopyVM);
     */
    getLegalCopyConfiguration(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, LegalCopyVM> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, LegalCopyVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertLegalCopyConfiguration(Mgmt.LegalCopyConfigurationRequest) returns (Mgmt.LegalCopyVM);
     */
    upsertLegalCopyConfiguration(input: LegalCopyConfigurationRequest, options?: RpcOptions): UnaryCall<LegalCopyConfigurationRequest, LegalCopyVM> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<LegalCopyConfigurationRequest, LegalCopyVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPosAutoAppliedDiscountFlag(Mgmt.LocationRequest) returns (Mgmt.POSAutoAppliedFlagRes);
     */
    getPosAutoAppliedDiscountFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, POSAutoAppliedFlagRes> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, POSAutoAppliedFlagRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePosAutoAppliedDiscountFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updatePosAutoAppliedDiscountFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCheckPosFlowFlag(Mgmt.LocationRequest) returns (Mgmt.CheckPOSFlagRes);
     */
    getCheckPosFlowFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, CheckPOSFlagRes> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, CheckPOSFlagRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCheckPosFlowFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateCheckPosFlowFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCalculateDefaultModifierPriceFlag(Mgmt.LocationRequest) returns (Mgmt.CalculateDefaultModiiferPriceFlagRes);
     */
    getCalculateDefaultModifierPriceFlag(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, CalculateDefaultModiiferPriceFlagRes> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, CalculateDefaultModiiferPriceFlagRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCalculateDefaultModifierPriceFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateCalculateDefaultModifierPriceFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLoyaltyFeatureFlag(Mgmt.FeatureFlagRequest) returns (Mgmt.EmptyResponse);
     */
    updateLoyaltyFeatureFlag(input: FeatureFlagRequest, options?: RpcOptions): UnaryCall<FeatureFlagRequest, EmptyResponse> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<FeatureFlagRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetItem86SyncInterval(Mgmt.LocationRequest) returns (Mgmt.GetItem86SyncIntervalRes);
     */
    getItem86SyncInterval(input: LocationRequest, options?: RpcOptions): UnaryCall<LocationRequest, GetItem86SyncIntervalRes> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<LocationRequest, GetItem86SyncIntervalRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateItem86SyncInterval(Mgmt.UpdateItem86SyncIntervalReq) returns (Mgmt.EmptyResponse);
     */
    updateItem86SyncInterval(input: UpdateItem86SyncIntervalReq, options?: RpcOptions): UnaryCall<UpdateItem86SyncIntervalReq, EmptyResponse> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateItem86SyncIntervalReq, EmptyResponse>("unary", this._transport, method, opt, input);
    }
}
