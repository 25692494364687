/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "companiesService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CompaniesService } from "./companiesService_pb";
import type { RemoveCompanyUserAccessRequest } from "./companiesService_pb";
import type { UpdateCompanyUserAccessRequest } from "./companiesService_pb";
import type { AddCompanyUserAccessRequest } from "./companiesService_pb";
import type { AccessControlListVM } from "./accessList_pb";
import type { EmptyResponse } from "./common_pb";
import type { AddCompanyRequest } from "./companiesService_pb";
import type { CompanyRequest } from "./common_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CompanyVM } from "./companies_pb";
import type { UpdateCompanyRequest } from "./companiesService_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Mgmt.CompaniesService
 */
export interface ICompaniesServiceClient {
    /**
     * @generated from protobuf rpc: UpsertCompany(Mgmt.UpdateCompanyRequest) returns (Mgmt.CompanyVM);
     */
    upsertCompany(input: UpdateCompanyRequest, options?: RpcOptions): UnaryCall<UpdateCompanyRequest, CompanyVM>;
    /**
     * @generated from protobuf rpc: GetCompany(Mgmt.CompanyRequest) returns (Mgmt.CompanyVM);
     */
    getCompany(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, CompanyVM>;
    /**
     * @generated from protobuf rpc: AddCompany(Mgmt.AddCompanyRequest) returns (Mgmt.CompanyVM);
     */
    addCompany(input: AddCompanyRequest, options?: RpcOptions): UnaryCall<AddCompanyRequest, CompanyVM>;
    /**
     * @generated from protobuf rpc: DeactivateCompany(Mgmt.CompanyRequest) returns (Mgmt.EmptyResponse);
     */
    deactivateCompany(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, EmptyResponse>;
    /**
     * @generated from protobuf rpc: GetCompanyUserAccessList(Mgmt.CompanyRequest) returns (Mgmt.AccessControlListVM);
     */
    getCompanyUserAccessList(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: AddUserAccess(Mgmt.AddCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    addUserAccess(input: AddCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<AddCompanyUserAccessRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: UpdateUserAccess(Mgmt.UpdateCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    updateUserAccess(input: UpdateCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<UpdateCompanyUserAccessRequest, AccessControlListVM>;
    /**
     * @generated from protobuf rpc: RemoveUserAccess(Mgmt.RemoveCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    removeUserAccess(input: RemoveCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<RemoveCompanyUserAccessRequest, AccessControlListVM>;
}
/**
 * @generated from protobuf service Mgmt.CompaniesService
 */
export class CompaniesServiceClient implements ICompaniesServiceClient, ServiceInfo {
    typeName = CompaniesService.typeName;
    methods = CompaniesService.methods;
    options = CompaniesService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: UpsertCompany(Mgmt.UpdateCompanyRequest) returns (Mgmt.CompanyVM);
     */
    upsertCompany(input: UpdateCompanyRequest, options?: RpcOptions): UnaryCall<UpdateCompanyRequest, CompanyVM> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyRequest, CompanyVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCompany(Mgmt.CompanyRequest) returns (Mgmt.CompanyVM);
     */
    getCompany(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, CompanyVM> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompanyRequest, CompanyVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddCompany(Mgmt.AddCompanyRequest) returns (Mgmt.CompanyVM);
     */
    addCompany(input: AddCompanyRequest, options?: RpcOptions): UnaryCall<AddCompanyRequest, CompanyVM> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddCompanyRequest, CompanyVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateCompany(Mgmt.CompanyRequest) returns (Mgmt.EmptyResponse);
     */
    deactivateCompany(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, EmptyResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompanyRequest, EmptyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCompanyUserAccessList(Mgmt.CompanyRequest) returns (Mgmt.AccessControlListVM);
     */
    getCompanyUserAccessList(input: CompanyRequest, options?: RpcOptions): UnaryCall<CompanyRequest, AccessControlListVM> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompanyRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddUserAccess(Mgmt.AddCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    addUserAccess(input: AddCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<AddCompanyUserAccessRequest, AccessControlListVM> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddCompanyUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserAccess(Mgmt.UpdateCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    updateUserAccess(input: UpdateCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<UpdateCompanyUserAccessRequest, AccessControlListVM> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveUserAccess(Mgmt.RemoveCompanyUserAccessRequest) returns (Mgmt.AccessControlListVM);
     */
    removeUserAccess(input: RemoveCompanyUserAccessRequest, options?: RpcOptions): UnaryCall<RemoveCompanyUserAccessRequest, AccessControlListVM> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveCompanyUserAccessRequest, AccessControlListVM>("unary", this._transport, method, opt, input);
    }
}
