/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "system.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PaymentIntegrationDefinitionVM } from "./payment_pb";
/**
 * @generated from protobuf message Mgmt.CurrencyOptionsVM
 */
export interface CurrencyOptionsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.CurrencyOptionVM currencyOptions = 1;
     */
    currencyOptions: CurrencyOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.CurrencyOptionVM
 */
export interface CurrencyOptionVM {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: int32 divisor = 3;
     */
    divisor: number;
}
/**
 * @generated from protobuf message Mgmt.LocaleOptionsVM
 */
export interface LocaleOptionsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.LocaleOptionVM localeOptions = 1;
     */
    localeOptions: LocaleOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.LocaleOptionVM
 */
export interface LocaleOptionVM {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message Mgmt.PosSettingsVM
 */
export interface PosSettingsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.PosType values = 1;
     */
    values: PosType[];
}
/**
 * @generated from protobuf message Mgmt.PosType
 */
export interface PosType {
    /**
     * @generated from protobuf field: string posIntegrationId = 1;
     */
    posIntegrationId: string;
    /**
     * @generated from protobuf field: string DisplayName = 2 [json_name = "DisplayName"];
     */
    displayName: string;
    /**
     * @generated from protobuf field: string Vendor = 3 [json_name = "Vendor"];
     */
    vendor: string;
    /**
     * @generated from protobuf field: string IntegrationVersion = 4 [json_name = "IntegrationVersion"];
     */
    integrationVersion: string;
    /**
     * @generated from protobuf field: bool IsCloudIntegration = 5 [json_name = "IsCloudIntegration"];
     */
    isCloudIntegration: boolean;
    /**
     * @generated from protobuf field: repeated string Tenders = 6 [json_name = "Tenders"];
     */
    tenders: string[];
    /**
     * @generated from protobuf field: string Fields = 7 [json_name = "Fields"];
     */
    fields: string;
    /**
     * @generated from protobuf field: string WebhookIdField = 8 [json_name = "WebhookIdField"];
     */
    webhookIdField: string;
    /**
     * @generated from protobuf field: string AssemblyName = 9 [json_name = "AssemblyName"];
     */
    assemblyName: string;
    /**
     * @generated from protobuf field: string IntegrationUrls = 10 [json_name = "IntegrationUrls"];
     */
    integrationUrls: string;
}
/**
 * @generated from protobuf message Mgmt.PaymentSettingsVM
 */
export interface PaymentSettingsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.PaymentIntegrationDefinitionVM values = 1;
     */
    values: PaymentIntegrationDefinitionVM[];
}
/**
 * @generated from protobuf message Mgmt.PosSyncTypeVM
 */
export interface PosSyncTypeVM {
    /**
     * @generated from protobuf field: string Id = 1 [json_name = "Id"];
     */
    id: string;
    /**
     * @generated from protobuf field: Mgmt.PosSyncMetaSettingVM PosSyncSetting = 2 [json_name = "PosSyncSetting"];
     */
    posSyncSetting?: PosSyncMetaSettingVM;
    /**
     * @generated from protobuf field: Mgmt.KioskPushMetaSettingVM KioskPushSetting = 3 [json_name = "KioskPushSetting"];
     */
    kioskPushSetting?: KioskPushMetaSettingVM;
}
/**
 * @generated from protobuf message Mgmt.PosSyncMetaRecordVM
 */
export interface PosSyncMetaRecordVM {
    /**
     * @generated from protobuf field: repeated Mgmt.PosSyncTypeVM PosSyncType = 1 [json_name = "PosSyncType"];
     */
    posSyncType: PosSyncTypeVM[];
}
/**
 * @generated from protobuf message Mgmt.PosSyncIntegrationDefinitionVM
 */
export interface PosSyncIntegrationDefinitionVM {
    /**
     * @generated from protobuf field: Mgmt.PosSyncMetaSettingVM PosSyncSetting = 1 [json_name = "PosSyncSetting"];
     */
    posSyncSetting?: PosSyncMetaSettingVM;
    /**
     * @generated from protobuf field: Mgmt.KioskPushMetaSettingVM KioskPushSetting = 2 [json_name = "KioskPushSetting"];
     */
    kioskPushSetting?: KioskPushMetaSettingVM;
}
/**
 * @generated from protobuf message Mgmt.AddPosSyncReply
 */
export interface AddPosSyncReply {
    /**
     * @generated from protobuf field: string pid = 1;
     */
    pid: string;
    /**
     * @generated from protobuf field: Mgmt.PosSyncIntegrationDefinitionVM PosSyncType = 2 [json_name = "PosSyncType"];
     */
    posSyncType?: PosSyncIntegrationDefinitionVM;
}
/**
 * @generated from protobuf message Mgmt.PosSyncMetaRequest
 */
export interface PosSyncMetaRequest {
    /**
     * @generated from protobuf field: string pid = 1;
     */
    pid: string;
    /**
     * @generated from protobuf field: Mgmt.PosSyncIntegrationDefinitionVM PosSyncIntegrationDefinition = 2 [json_name = "PosSyncIntegrationDefinition"];
     */
    posSyncIntegrationDefinition?: PosSyncIntegrationDefinitionVM;
}
/**
 * @generated from protobuf message Mgmt.PosSyncMetaSettingVM
 */
export interface PosSyncMetaSettingVM {
    /**
     * @generated from protobuf field: bool menuItemOverrides = 1;
     */
    menuItemOverrides: boolean;
    /**
     * @generated from protobuf field: bool menuCategoryOverrides = 2;
     */
    menuCategoryOverrides: boolean;
    /**
     * @generated from protobuf field: bool modifierGroupOverrides = 3;
     */
    modifierGroupOverrides: boolean;
    /**
     * @generated from protobuf field: bool modifierOverrides = 4;
     */
    modifierOverrides: boolean;
}
/**
 * @generated from protobuf message Mgmt.KioskPushMetaSettingVM
 */
export interface KioskPushMetaSettingVM {
    /**
     * @generated from protobuf field: bool kioskPriceUpdate = 1;
     */
    kioskPriceUpdate: boolean;
    /**
     * @generated from protobuf field: bool kioskFullMenuUpdate = 2;
     */
    kioskFullMenuUpdate: boolean;
    /**
     * @generated from protobuf field: bool kioskNoUpdate = 3;
     */
    kioskNoUpdate: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class CurrencyOptionsVM$Type extends MessageType<CurrencyOptionsVM> {
    constructor() {
        super("Mgmt.CurrencyOptionsVM", [
            { no: 1, name: "currencyOptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CurrencyOptionVM }
        ]);
    }
    create(value?: PartialMessage<CurrencyOptionsVM>): CurrencyOptionsVM {
        const message = { currencyOptions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrencyOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrencyOptionsVM): CurrencyOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.CurrencyOptionVM currencyOptions */ 1:
                    message.currencyOptions.push(CurrencyOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrencyOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.CurrencyOptionVM currencyOptions = 1; */
        for (let i = 0; i < message.currencyOptions.length; i++)
            CurrencyOptionVM.internalBinaryWrite(message.currencyOptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CurrencyOptionsVM
 */
export const CurrencyOptionsVM = new CurrencyOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrencyOptionVM$Type extends MessageType<CurrencyOptionVM> {
    constructor() {
        super("Mgmt.CurrencyOptionVM", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "divisor", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CurrencyOptionVM>): CurrencyOptionVM {
        const message = { label: "", code: "", divisor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrencyOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrencyOptionVM): CurrencyOptionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* int32 divisor */ 3:
                    message.divisor = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrencyOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* int32 divisor = 3; */
        if (message.divisor !== 0)
            writer.tag(3, WireType.Varint).int32(message.divisor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CurrencyOptionVM
 */
export const CurrencyOptionVM = new CurrencyOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocaleOptionsVM$Type extends MessageType<LocaleOptionsVM> {
    constructor() {
        super("Mgmt.LocaleOptionsVM", [
            { no: 1, name: "localeOptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocaleOptionVM }
        ]);
    }
    create(value?: PartialMessage<LocaleOptionsVM>): LocaleOptionsVM {
        const message = { localeOptions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocaleOptionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocaleOptionsVM): LocaleOptionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.LocaleOptionVM localeOptions */ 1:
                    message.localeOptions.push(LocaleOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocaleOptionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.LocaleOptionVM localeOptions = 1; */
        for (let i = 0; i < message.localeOptions.length; i++)
            LocaleOptionVM.internalBinaryWrite(message.localeOptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocaleOptionsVM
 */
export const LocaleOptionsVM = new LocaleOptionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocaleOptionVM$Type extends MessageType<LocaleOptionVM> {
    constructor() {
        super("Mgmt.LocaleOptionVM", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocaleOptionVM>): LocaleOptionVM {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocaleOptionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocaleOptionVM): LocaleOptionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocaleOptionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocaleOptionVM
 */
export const LocaleOptionVM = new LocaleOptionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSettingsVM$Type extends MessageType<PosSettingsVM> {
    constructor() {
        super("Mgmt.PosSettingsVM", [
            { no: 1, name: "values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PosType }
        ]);
    }
    create(value?: PartialMessage<PosSettingsVM>): PosSettingsVM {
        const message = { values: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSettingsVM): PosSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.PosType values */ 1:
                    message.values.push(PosType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.PosType values = 1; */
        for (let i = 0; i < message.values.length; i++)
            PosType.internalBinaryWrite(message.values[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSettingsVM
 */
export const PosSettingsVM = new PosSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosType$Type extends MessageType<PosType> {
    constructor() {
        super("Mgmt.PosType", [
            { no: 1, name: "posIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "DisplayName", kind: "scalar", jsonName: "DisplayName", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Vendor", kind: "scalar", jsonName: "Vendor", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "IntegrationVersion", kind: "scalar", jsonName: "IntegrationVersion", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "IsCloudIntegration", kind: "scalar", jsonName: "IsCloudIntegration", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "Tenders", kind: "scalar", jsonName: "Tenders", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "Fields", kind: "scalar", jsonName: "Fields", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "WebhookIdField", kind: "scalar", jsonName: "WebhookIdField", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "AssemblyName", kind: "scalar", jsonName: "AssemblyName", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "IntegrationUrls", kind: "scalar", jsonName: "IntegrationUrls", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PosType>): PosType {
        const message = { posIntegrationId: "", displayName: "", vendor: "", integrationVersion: "", isCloudIntegration: false, tenders: [], fields: "", webhookIdField: "", assemblyName: "", integrationUrls: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosType): PosType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string posIntegrationId */ 1:
                    message.posIntegrationId = reader.string();
                    break;
                case /* string DisplayName = 2 [json_name = "DisplayName"];*/ 2:
                    message.displayName = reader.string();
                    break;
                case /* string Vendor = 3 [json_name = "Vendor"];*/ 3:
                    message.vendor = reader.string();
                    break;
                case /* string IntegrationVersion = 4 [json_name = "IntegrationVersion"];*/ 4:
                    message.integrationVersion = reader.string();
                    break;
                case /* bool IsCloudIntegration = 5 [json_name = "IsCloudIntegration"];*/ 5:
                    message.isCloudIntegration = reader.bool();
                    break;
                case /* repeated string Tenders = 6 [json_name = "Tenders"];*/ 6:
                    message.tenders.push(reader.string());
                    break;
                case /* string Fields = 7 [json_name = "Fields"];*/ 7:
                    message.fields = reader.string();
                    break;
                case /* string WebhookIdField = 8 [json_name = "WebhookIdField"];*/ 8:
                    message.webhookIdField = reader.string();
                    break;
                case /* string AssemblyName = 9 [json_name = "AssemblyName"];*/ 9:
                    message.assemblyName = reader.string();
                    break;
                case /* string IntegrationUrls = 10 [json_name = "IntegrationUrls"];*/ 10:
                    message.integrationUrls = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string posIntegrationId = 1; */
        if (message.posIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.posIntegrationId);
        /* string DisplayName = 2 [json_name = "DisplayName"]; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string Vendor = 3 [json_name = "Vendor"]; */
        if (message.vendor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.vendor);
        /* string IntegrationVersion = 4 [json_name = "IntegrationVersion"]; */
        if (message.integrationVersion !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.integrationVersion);
        /* bool IsCloudIntegration = 5 [json_name = "IsCloudIntegration"]; */
        if (message.isCloudIntegration !== false)
            writer.tag(5, WireType.Varint).bool(message.isCloudIntegration);
        /* repeated string Tenders = 6 [json_name = "Tenders"]; */
        for (let i = 0; i < message.tenders.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.tenders[i]);
        /* string Fields = 7 [json_name = "Fields"]; */
        if (message.fields !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fields);
        /* string WebhookIdField = 8 [json_name = "WebhookIdField"]; */
        if (message.webhookIdField !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.webhookIdField);
        /* string AssemblyName = 9 [json_name = "AssemblyName"]; */
        if (message.assemblyName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.assemblyName);
        /* string IntegrationUrls = 10 [json_name = "IntegrationUrls"]; */
        if (message.integrationUrls !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.integrationUrls);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosType
 */
export const PosType = new PosType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentSettingsVM$Type extends MessageType<PaymentSettingsVM> {
    constructor() {
        super("Mgmt.PaymentSettingsVM", [
            { no: 1, name: "values", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentIntegrationDefinitionVM }
        ]);
    }
    create(value?: PartialMessage<PaymentSettingsVM>): PaymentSettingsVM {
        const message = { values: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentSettingsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentSettingsVM): PaymentSettingsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.PaymentIntegrationDefinitionVM values */ 1:
                    message.values.push(PaymentIntegrationDefinitionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentSettingsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.PaymentIntegrationDefinitionVM values = 1; */
        for (let i = 0; i < message.values.length; i++)
            PaymentIntegrationDefinitionVM.internalBinaryWrite(message.values[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentSettingsVM
 */
export const PaymentSettingsVM = new PaymentSettingsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncTypeVM$Type extends MessageType<PosSyncTypeVM> {
    constructor() {
        super("Mgmt.PosSyncTypeVM", [
            { no: 1, name: "Id", kind: "scalar", jsonName: "Id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PosSyncSetting", kind: "message", jsonName: "PosSyncSetting", T: () => PosSyncMetaSettingVM },
            { no: 3, name: "KioskPushSetting", kind: "message", jsonName: "KioskPushSetting", T: () => KioskPushMetaSettingVM }
        ]);
    }
    create(value?: PartialMessage<PosSyncTypeVM>): PosSyncTypeVM {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncTypeVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncTypeVM): PosSyncTypeVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Id = 1 [json_name = "Id"];*/ 1:
                    message.id = reader.string();
                    break;
                case /* Mgmt.PosSyncMetaSettingVM PosSyncSetting = 2 [json_name = "PosSyncSetting"];*/ 2:
                    message.posSyncSetting = PosSyncMetaSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncSetting);
                    break;
                case /* Mgmt.KioskPushMetaSettingVM KioskPushSetting = 3 [json_name = "KioskPushSetting"];*/ 3:
                    message.kioskPushSetting = KioskPushMetaSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskPushSetting);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncTypeVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Id = 1 [json_name = "Id"]; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* Mgmt.PosSyncMetaSettingVM PosSyncSetting = 2 [json_name = "PosSyncSetting"]; */
        if (message.posSyncSetting)
            PosSyncMetaSettingVM.internalBinaryWrite(message.posSyncSetting, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskPushMetaSettingVM KioskPushSetting = 3 [json_name = "KioskPushSetting"]; */
        if (message.kioskPushSetting)
            KioskPushMetaSettingVM.internalBinaryWrite(message.kioskPushSetting, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncTypeVM
 */
export const PosSyncTypeVM = new PosSyncTypeVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncMetaRecordVM$Type extends MessageType<PosSyncMetaRecordVM> {
    constructor() {
        super("Mgmt.PosSyncMetaRecordVM", [
            { no: 1, name: "PosSyncType", kind: "message", jsonName: "PosSyncType", repeat: 1 /*RepeatType.PACKED*/, T: () => PosSyncTypeVM }
        ]);
    }
    create(value?: PartialMessage<PosSyncMetaRecordVM>): PosSyncMetaRecordVM {
        const message = { posSyncType: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncMetaRecordVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncMetaRecordVM): PosSyncMetaRecordVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.PosSyncTypeVM PosSyncType = 1 [json_name = "PosSyncType"];*/ 1:
                    message.posSyncType.push(PosSyncTypeVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncMetaRecordVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.PosSyncTypeVM PosSyncType = 1 [json_name = "PosSyncType"]; */
        for (let i = 0; i < message.posSyncType.length; i++)
            PosSyncTypeVM.internalBinaryWrite(message.posSyncType[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncMetaRecordVM
 */
export const PosSyncMetaRecordVM = new PosSyncMetaRecordVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncIntegrationDefinitionVM$Type extends MessageType<PosSyncIntegrationDefinitionVM> {
    constructor() {
        super("Mgmt.PosSyncIntegrationDefinitionVM", [
            { no: 1, name: "PosSyncSetting", kind: "message", jsonName: "PosSyncSetting", T: () => PosSyncMetaSettingVM },
            { no: 2, name: "KioskPushSetting", kind: "message", jsonName: "KioskPushSetting", T: () => KioskPushMetaSettingVM }
        ]);
    }
    create(value?: PartialMessage<PosSyncIntegrationDefinitionVM>): PosSyncIntegrationDefinitionVM {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncIntegrationDefinitionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncIntegrationDefinitionVM): PosSyncIntegrationDefinitionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.PosSyncMetaSettingVM PosSyncSetting = 1 [json_name = "PosSyncSetting"];*/ 1:
                    message.posSyncSetting = PosSyncMetaSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncSetting);
                    break;
                case /* Mgmt.KioskPushMetaSettingVM KioskPushSetting = 2 [json_name = "KioskPushSetting"];*/ 2:
                    message.kioskPushSetting = KioskPushMetaSettingVM.internalBinaryRead(reader, reader.uint32(), options, message.kioskPushSetting);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncIntegrationDefinitionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.PosSyncMetaSettingVM PosSyncSetting = 1 [json_name = "PosSyncSetting"]; */
        if (message.posSyncSetting)
            PosSyncMetaSettingVM.internalBinaryWrite(message.posSyncSetting, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.KioskPushMetaSettingVM KioskPushSetting = 2 [json_name = "KioskPushSetting"]; */
        if (message.kioskPushSetting)
            KioskPushMetaSettingVM.internalBinaryWrite(message.kioskPushSetting, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncIntegrationDefinitionVM
 */
export const PosSyncIntegrationDefinitionVM = new PosSyncIntegrationDefinitionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPosSyncReply$Type extends MessageType<AddPosSyncReply> {
    constructor() {
        super("Mgmt.AddPosSyncReply", [
            { no: 1, name: "pid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PosSyncType", kind: "message", jsonName: "PosSyncType", T: () => PosSyncIntegrationDefinitionVM }
        ]);
    }
    create(value?: PartialMessage<AddPosSyncReply>): AddPosSyncReply {
        const message = { pid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddPosSyncReply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPosSyncReply): AddPosSyncReply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pid */ 1:
                    message.pid = reader.string();
                    break;
                case /* Mgmt.PosSyncIntegrationDefinitionVM PosSyncType = 2 [json_name = "PosSyncType"];*/ 2:
                    message.posSyncType = PosSyncIntegrationDefinitionVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncType);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddPosSyncReply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pid = 1; */
        if (message.pid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pid);
        /* Mgmt.PosSyncIntegrationDefinitionVM PosSyncType = 2 [json_name = "PosSyncType"]; */
        if (message.posSyncType)
            PosSyncIntegrationDefinitionVM.internalBinaryWrite(message.posSyncType, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddPosSyncReply
 */
export const AddPosSyncReply = new AddPosSyncReply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncMetaRequest$Type extends MessageType<PosSyncMetaRequest> {
    constructor() {
        super("Mgmt.PosSyncMetaRequest", [
            { no: 1, name: "pid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "PosSyncIntegrationDefinition", kind: "message", jsonName: "PosSyncIntegrationDefinition", T: () => PosSyncIntegrationDefinitionVM }
        ]);
    }
    create(value?: PartialMessage<PosSyncMetaRequest>): PosSyncMetaRequest {
        const message = { pid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncMetaRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncMetaRequest): PosSyncMetaRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pid */ 1:
                    message.pid = reader.string();
                    break;
                case /* Mgmt.PosSyncIntegrationDefinitionVM PosSyncIntegrationDefinition = 2 [json_name = "PosSyncIntegrationDefinition"];*/ 2:
                    message.posSyncIntegrationDefinition = PosSyncIntegrationDefinitionVM.internalBinaryRead(reader, reader.uint32(), options, message.posSyncIntegrationDefinition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncMetaRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pid = 1; */
        if (message.pid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pid);
        /* Mgmt.PosSyncIntegrationDefinitionVM PosSyncIntegrationDefinition = 2 [json_name = "PosSyncIntegrationDefinition"]; */
        if (message.posSyncIntegrationDefinition)
            PosSyncIntegrationDefinitionVM.internalBinaryWrite(message.posSyncIntegrationDefinition, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncMetaRequest
 */
export const PosSyncMetaRequest = new PosSyncMetaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PosSyncMetaSettingVM$Type extends MessageType<PosSyncMetaSettingVM> {
    constructor() {
        super("Mgmt.PosSyncMetaSettingVM", [
            { no: 1, name: "menuItemOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "menuCategoryOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "modifierGroupOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "modifierOverrides", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PosSyncMetaSettingVM>): PosSyncMetaSettingVM {
        const message = { menuItemOverrides: false, menuCategoryOverrides: false, modifierGroupOverrides: false, modifierOverrides: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PosSyncMetaSettingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PosSyncMetaSettingVM): PosSyncMetaSettingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool menuItemOverrides */ 1:
                    message.menuItemOverrides = reader.bool();
                    break;
                case /* bool menuCategoryOverrides */ 2:
                    message.menuCategoryOverrides = reader.bool();
                    break;
                case /* bool modifierGroupOverrides */ 3:
                    message.modifierGroupOverrides = reader.bool();
                    break;
                case /* bool modifierOverrides */ 4:
                    message.modifierOverrides = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PosSyncMetaSettingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool menuItemOverrides = 1; */
        if (message.menuItemOverrides !== false)
            writer.tag(1, WireType.Varint).bool(message.menuItemOverrides);
        /* bool menuCategoryOverrides = 2; */
        if (message.menuCategoryOverrides !== false)
            writer.tag(2, WireType.Varint).bool(message.menuCategoryOverrides);
        /* bool modifierGroupOverrides = 3; */
        if (message.modifierGroupOverrides !== false)
            writer.tag(3, WireType.Varint).bool(message.modifierGroupOverrides);
        /* bool modifierOverrides = 4; */
        if (message.modifierOverrides !== false)
            writer.tag(4, WireType.Varint).bool(message.modifierOverrides);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PosSyncMetaSettingVM
 */
export const PosSyncMetaSettingVM = new PosSyncMetaSettingVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskPushMetaSettingVM$Type extends MessageType<KioskPushMetaSettingVM> {
    constructor() {
        super("Mgmt.KioskPushMetaSettingVM", [
            { no: 1, name: "kioskPriceUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "kioskFullMenuUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "kioskNoUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<KioskPushMetaSettingVM>): KioskPushMetaSettingVM {
        const message = { kioskPriceUpdate: false, kioskFullMenuUpdate: false, kioskNoUpdate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskPushMetaSettingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskPushMetaSettingVM): KioskPushMetaSettingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool kioskPriceUpdate */ 1:
                    message.kioskPriceUpdate = reader.bool();
                    break;
                case /* bool kioskFullMenuUpdate */ 2:
                    message.kioskFullMenuUpdate = reader.bool();
                    break;
                case /* bool kioskNoUpdate */ 3:
                    message.kioskNoUpdate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskPushMetaSettingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool kioskPriceUpdate = 1; */
        if (message.kioskPriceUpdate !== false)
            writer.tag(1, WireType.Varint).bool(message.kioskPriceUpdate);
        /* bool kioskFullMenuUpdate = 2; */
        if (message.kioskFullMenuUpdate !== false)
            writer.tag(2, WireType.Varint).bool(message.kioskFullMenuUpdate);
        /* bool kioskNoUpdate = 3; */
        if (message.kioskNoUpdate !== false)
            writer.tag(3, WireType.Varint).bool(message.kioskNoUpdate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskPushMetaSettingVM
 */
export const KioskPushMetaSettingVM = new KioskPushMetaSettingVM$Type();
