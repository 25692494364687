<div
  class="bg-noaccess d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
  [style.background-image]="'url(./assets/media/errors/noaccess.jpg)'"
>
  <div class="d-flex flex-column flex-column-fluid text-center">
    <a class="mb-10 pt-lg-20 cursor-pointer">
      <img
        alt="Logo"
        src="./assets/media/logos/grubbrr-black-250px.png"
        class="h-50px mb-5"
      />
    </a>
    <div class="pt-lg-10">
      <h1 class="fw-bolder fs-4x text-warning mb-10">No Access</h1>

      <div class="fw-bold fs-3 text-gray-400 mt-10">
        You don't have access to any companies. <br />
        Please contact your administrator, or Grubbrr Support for more
        information.
      </div>

      <div class="mt-5 fw-bold fs-3 text-warning">
        <label>You are currently logged in as {{ currentUser.username }}</label>
      </div>
      <div class="mt-5">
        <a href="/logout" class="btn btn-lg btn-info cursor-pointer">
          Log Off
        </a>
      </div>
    </div>

    <div class="text-center mt-10">
      <a
        href="https://grubbrr.com/support/"
        class="btn btn-lg btn-info fw-bolder cursor-pointer"
      >
        Visit Grubbrr Support
      </a>
    </div>
  </div>

  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="d-flex align-items-center fw-bold fs-6">
      <a href="https://grubbrr.com" class="text-light text-hover-primary px-2">
        Grubbrr
      </a>
      <a
        href="mailto:support@grubbrr.com"
        class="text-light text-hover-primary px-2"
      >
        Contact
      </a>
      <a
        href="https://grubbrr.com/support/"
        class="text-light text-hover-primary px-2"
      >
        Help
      </a>
    </div>
  </div>
</div>
