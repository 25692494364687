/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "accessList.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message Mgmt.AccessControlListVM
 */
export interface AccessControlListVM {
    /**
     * @generated from protobuf field: repeated Mgmt.RoleAssignmentVM list = 1;
     */
    list: RoleAssignmentVM[];
}
/**
 * @generated from protobuf message Mgmt.RoleAssignmentVM
 */
export interface RoleAssignmentVM {
    /**
     * @generated from protobuf field: string UserEmail = 1 [json_name = "UserEmail"];
     */
    userEmail: string;
    /**
     * @generated from protobuf field: string UserId = 2 [json_name = "UserId"];
     */
    userId: string;
    /**
     * @generated from protobuf field: Mgmt.RoleVM Role = 3 [json_name = "Role"];
     */
    role?: RoleVM;
    /**
     * @generated from protobuf field: bool Inherited = 4 [json_name = "Inherited"];
     */
    inherited: boolean;
}
/**
 * @generated from protobuf message Mgmt.RoleVM
 */
export interface RoleVM {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    name: string;
    /**
     * @generated from protobuf field: string Display = 2 [json_name = "Display"];
     */
    display: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class AccessControlListVM$Type extends MessageType<AccessControlListVM> {
    constructor() {
        super("Mgmt.AccessControlListVM", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoleAssignmentVM }
        ]);
    }
    create(value?: PartialMessage<AccessControlListVM>): AccessControlListVM {
        const message = { list: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccessControlListVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccessControlListVM): AccessControlListVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.RoleAssignmentVM list */ 1:
                    message.list.push(RoleAssignmentVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccessControlListVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.RoleAssignmentVM list = 1; */
        for (let i = 0; i < message.list.length; i++)
            RoleAssignmentVM.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AccessControlListVM
 */
export const AccessControlListVM = new AccessControlListVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoleAssignmentVM$Type extends MessageType<RoleAssignmentVM> {
    constructor() {
        super("Mgmt.RoleAssignmentVM", [
            { no: 1, name: "UserEmail", kind: "scalar", jsonName: "UserEmail", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserId", kind: "scalar", jsonName: "UserId", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Role", kind: "message", jsonName: "Role", T: () => RoleVM },
            { no: 4, name: "Inherited", kind: "scalar", jsonName: "Inherited", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RoleAssignmentVM>): RoleAssignmentVM {
        const message = { userEmail: "", userId: "", inherited: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoleAssignmentVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoleAssignmentVM): RoleAssignmentVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string UserEmail = 1 [json_name = "UserEmail"];*/ 1:
                    message.userEmail = reader.string();
                    break;
                case /* string UserId = 2 [json_name = "UserId"];*/ 2:
                    message.userId = reader.string();
                    break;
                case /* Mgmt.RoleVM Role = 3 [json_name = "Role"];*/ 3:
                    message.role = RoleVM.internalBinaryRead(reader, reader.uint32(), options, message.role);
                    break;
                case /* bool Inherited = 4 [json_name = "Inherited"];*/ 4:
                    message.inherited = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoleAssignmentVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string UserEmail = 1 [json_name = "UserEmail"]; */
        if (message.userEmail !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userEmail);
        /* string UserId = 2 [json_name = "UserId"]; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* Mgmt.RoleVM Role = 3 [json_name = "Role"]; */
        if (message.role)
            RoleVM.internalBinaryWrite(message.role, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool Inherited = 4 [json_name = "Inherited"]; */
        if (message.inherited !== false)
            writer.tag(4, WireType.Varint).bool(message.inherited);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RoleAssignmentVM
 */
export const RoleAssignmentVM = new RoleAssignmentVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoleVM$Type extends MessageType<RoleVM> {
    constructor() {
        super("Mgmt.RoleVM", [
            { no: 1, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Display", kind: "scalar", jsonName: "Display", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoleVM>): RoleVM {
        const message = { name: "", display: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoleVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoleVM): RoleVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.name = reader.string();
                    break;
                case /* string Display = 2 [json_name = "Display"];*/ 2:
                    message.display = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoleVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string Display = 2 [json_name = "Display"]; */
        if (message.display !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.display);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RoleVM
 */
export const RoleVM = new RoleVM$Type();
