/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "payment.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TenderOptionVM } from "./common_pb";
import { FormFieldTypeVM } from "./common_pb";
import { BinRange } from "./loyalty_pb";
import { FormValues } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.PaymentIntegrationConfigListVM
 */
export interface PaymentIntegrationConfigListVM {
    /**
     * @generated from protobuf field: repeated Mgmt.PaymentIntegrationConfigVM list = 1;
     */
    list: PaymentIntegrationConfigVM[];
}
/**
 * @generated from protobuf message Mgmt.PaymentIntegrationConfigVM
 */
export interface PaymentIntegrationConfigVM {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @generated from protobuf oneof: tenderMapping
     */
    tenderMapping: {
        oneofKind: "tender";
        /**
         * @generated from protobuf field: string tender = 2;
         */
        tender: string;
    } | {
        oneofKind: "cardNetworks";
        /**
         * @generated from protobuf field: Mgmt.CardNetworkMappingVM cardNetworks = 4;
         */
        cardNetworks: CardNetworkMappingVM;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: Mgmt.FormValues settings = 3;
     */
    settings?: FormValues;
    /**
     * @generated from protobuf field: repeated Mgmt.BinRange binRanges = 5;
     */
    binRanges: BinRange[];
    /**
     * @generated from protobuf field: string displayName = 6;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string checkoutImagePath = 7;
     */
    checkoutImagePath: string;
    /**
     * @generated from protobuf field: int32 displayOrder = 8;
     */
    displayOrder: number;
}
/**
 * @generated from protobuf message Mgmt.PaymentIntegrationUrl
 */
export interface PaymentIntegrationUrl {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message Mgmt.CardNetworkMappingVM
 */
export interface CardNetworkMappingVM {
    /**
     * @generated from protobuf field: string unknown = 1;
     */
    unknown: string;
    /**
     * @generated from protobuf field: string visa = 2;
     */
    visa: string;
    /**
     * @generated from protobuf field: string masterCard = 3;
     */
    masterCard: string;
    /**
     * @generated from protobuf field: string amex = 4;
     */
    amex: string;
    /**
     * @generated from protobuf field: string discover = 5;
     */
    discover: string;
}
/**
 * @generated from protobuf message Mgmt.PaymentIntegrationsVM
 */
export interface PaymentIntegrationsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.PaymentIntegrationConfigVM configuredList = 1;
     */
    configuredList: PaymentIntegrationConfigVM[];
    /**
     * @generated from protobuf field: repeated Mgmt.PaymentIntegrationDefinitionVM availableIntegrations = 2;
     */
    availableIntegrations: PaymentIntegrationDefinitionVM[];
    /**
     * @generated from protobuf field: string defaultPaymentTender = 3;
     */
    defaultPaymentTender: string;
    /**
     * @generated from protobuf field: optional bool enableAmazonOnePay = 4;
     */
    enableAmazonOnePay?: boolean;
}
/**
 * @generated from protobuf message Mgmt.PaymentIntegrationDefinitionVM
 */
export interface PaymentIntegrationDefinitionVM {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @generated from protobuf field: string displayName = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string paymentMethod = 3;
     */
    paymentMethod: string;
    /**
     * @generated from protobuf field: map<string, Mgmt.FormFieldTypeVM> locationFields = 4;
     */
    locationFields: {
        [key: string]: FormFieldTypeVM;
    };
    /**
     * @generated from protobuf field: map<string, Mgmt.FormFieldTypeVM> KioskFields = 5 [json_name = "KioskFields"];
     */
    kioskFields: {
        [key: string]: FormFieldTypeVM;
    };
    /**
     * @generated from protobuf field: repeated Mgmt.PaymentIntegrationUrl PaymentIntegrationUrls = 6 [json_name = "PaymentIntegrationUrls"];
     */
    paymentIntegrationUrls: PaymentIntegrationUrl[];
}
/**
 * @generated from protobuf message Mgmt.PaymentTendersVM
 */
export interface PaymentTendersVM {
    /**
     * @generated from protobuf field: string requiredTenderType = 1;
     */
    requiredTenderType: string;
    /**
     * @generated from protobuf field: repeated Mgmt.TenderOptionVM tenderOptions = 2;
     */
    tenderOptions: TenderOptionVM[];
    /**
     * @generated from protobuf field: repeated Mgmt.TenderOptionVM cardNetworkMappings = 3;
     */
    cardNetworkMappings: TenderOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.KioskPaymentConfigVM
 */
export interface KioskPaymentConfigVM {
    /**
     * @generated from protobuf field: repeated Mgmt.KioskPaymentIntegrationConfigVM integrations = 1;
     */
    integrations: KioskPaymentIntegrationConfigVM[];
}
/**
 * @generated from protobuf message Mgmt.KioskPaymentIntegrationConfigVM
 */
export interface KioskPaymentIntegrationConfigVM {
    /**
     * @generated from protobuf field: string paymentIntegrationId = 1;
     */
    paymentIntegrationId: string;
    /**
     * @generated from protobuf field: Mgmt.FormValues settings = 3;
     */
    settings?: FormValues;
}
// @generated message type with reflection information, may provide speed optimized methods
class PaymentIntegrationConfigListVM$Type extends MessageType<PaymentIntegrationConfigListVM> {
    constructor() {
        super("Mgmt.PaymentIntegrationConfigListVM", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentIntegrationConfigVM }
        ]);
    }
    create(value?: PartialMessage<PaymentIntegrationConfigListVM>): PaymentIntegrationConfigListVM {
        const message = { list: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentIntegrationConfigListVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentIntegrationConfigListVM): PaymentIntegrationConfigListVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.PaymentIntegrationConfigVM list */ 1:
                    message.list.push(PaymentIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentIntegrationConfigListVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.PaymentIntegrationConfigVM list = 1; */
        for (let i = 0; i < message.list.length; i++)
            PaymentIntegrationConfigVM.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentIntegrationConfigListVM
 */
export const PaymentIntegrationConfigListVM = new PaymentIntegrationConfigListVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentIntegrationConfigVM$Type extends MessageType<PaymentIntegrationConfigVM> {
    constructor() {
        super("Mgmt.PaymentIntegrationConfigVM", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tender", kind: "scalar", oneof: "tenderMapping", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cardNetworks", kind: "message", oneof: "tenderMapping", T: () => CardNetworkMappingVM },
            { no: 3, name: "settings", kind: "message", T: () => FormValues },
            { no: 5, name: "binRanges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BinRange },
            { no: 6, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "checkoutImagePath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "displayOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentIntegrationConfigVM>): PaymentIntegrationConfigVM {
        const message = { paymentIntegrationId: "", tenderMapping: { oneofKind: undefined }, binRanges: [], displayName: "", checkoutImagePath: "", displayOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentIntegrationConfigVM): PaymentIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string tender */ 2:
                    message.tenderMapping = {
                        oneofKind: "tender",
                        tender: reader.string()
                    };
                    break;
                case /* Mgmt.CardNetworkMappingVM cardNetworks */ 4:
                    message.tenderMapping = {
                        oneofKind: "cardNetworks",
                        cardNetworks: CardNetworkMappingVM.internalBinaryRead(reader, reader.uint32(), options, (message.tenderMapping as any).cardNetworks)
                    };
                    break;
                case /* Mgmt.FormValues settings */ 3:
                    message.settings = FormValues.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                case /* repeated Mgmt.BinRange binRanges */ 5:
                    message.binRanges.push(BinRange.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string displayName */ 6:
                    message.displayName = reader.string();
                    break;
                case /* string checkoutImagePath */ 7:
                    message.checkoutImagePath = reader.string();
                    break;
                case /* int32 displayOrder */ 8:
                    message.displayOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string tender = 2; */
        if (message.tenderMapping.oneofKind === "tender")
            writer.tag(2, WireType.LengthDelimited).string(message.tenderMapping.tender);
        /* Mgmt.CardNetworkMappingVM cardNetworks = 4; */
        if (message.tenderMapping.oneofKind === "cardNetworks")
            CardNetworkMappingVM.internalBinaryWrite(message.tenderMapping.cardNetworks, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.FormValues settings = 3; */
        if (message.settings)
            FormValues.internalBinaryWrite(message.settings, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.BinRange binRanges = 5; */
        for (let i = 0; i < message.binRanges.length; i++)
            BinRange.internalBinaryWrite(message.binRanges[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string displayName = 6; */
        if (message.displayName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.displayName);
        /* string checkoutImagePath = 7; */
        if (message.checkoutImagePath !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.checkoutImagePath);
        /* int32 displayOrder = 8; */
        if (message.displayOrder !== 0)
            writer.tag(8, WireType.Varint).int32(message.displayOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentIntegrationConfigVM
 */
export const PaymentIntegrationConfigVM = new PaymentIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentIntegrationUrl$Type extends MessageType<PaymentIntegrationUrl> {
    constructor() {
        super("Mgmt.PaymentIntegrationUrl", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentIntegrationUrl>): PaymentIntegrationUrl {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentIntegrationUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentIntegrationUrl): PaymentIntegrationUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentIntegrationUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentIntegrationUrl
 */
export const PaymentIntegrationUrl = new PaymentIntegrationUrl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardNetworkMappingVM$Type extends MessageType<CardNetworkMappingVM> {
    constructor() {
        super("Mgmt.CardNetworkMappingVM", [
            { no: 1, name: "unknown", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "visa", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "masterCard", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amex", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "discover", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CardNetworkMappingVM>): CardNetworkMappingVM {
        const message = { unknown: "", visa: "", masterCard: "", amex: "", discover: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CardNetworkMappingVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CardNetworkMappingVM): CardNetworkMappingVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string unknown */ 1:
                    message.unknown = reader.string();
                    break;
                case /* string visa */ 2:
                    message.visa = reader.string();
                    break;
                case /* string masterCard */ 3:
                    message.masterCard = reader.string();
                    break;
                case /* string amex */ 4:
                    message.amex = reader.string();
                    break;
                case /* string discover */ 5:
                    message.discover = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CardNetworkMappingVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string unknown = 1; */
        if (message.unknown !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.unknown);
        /* string visa = 2; */
        if (message.visa !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.visa);
        /* string masterCard = 3; */
        if (message.masterCard !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.masterCard);
        /* string amex = 4; */
        if (message.amex !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.amex);
        /* string discover = 5; */
        if (message.discover !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.discover);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CardNetworkMappingVM
 */
export const CardNetworkMappingVM = new CardNetworkMappingVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentIntegrationsVM$Type extends MessageType<PaymentIntegrationsVM> {
    constructor() {
        super("Mgmt.PaymentIntegrationsVM", [
            { no: 1, name: "configuredList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentIntegrationConfigVM },
            { no: 2, name: "availableIntegrations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentIntegrationDefinitionVM },
            { no: 3, name: "defaultPaymentTender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "enableAmazonOnePay", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentIntegrationsVM>): PaymentIntegrationsVM {
        const message = { configuredList: [], availableIntegrations: [], defaultPaymentTender: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentIntegrationsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentIntegrationsVM): PaymentIntegrationsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.PaymentIntegrationConfigVM configuredList */ 1:
                    message.configuredList.push(PaymentIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Mgmt.PaymentIntegrationDefinitionVM availableIntegrations */ 2:
                    message.availableIntegrations.push(PaymentIntegrationDefinitionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string defaultPaymentTender */ 3:
                    message.defaultPaymentTender = reader.string();
                    break;
                case /* optional bool enableAmazonOnePay */ 4:
                    message.enableAmazonOnePay = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentIntegrationsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.PaymentIntegrationConfigVM configuredList = 1; */
        for (let i = 0; i < message.configuredList.length; i++)
            PaymentIntegrationConfigVM.internalBinaryWrite(message.configuredList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.PaymentIntegrationDefinitionVM availableIntegrations = 2; */
        for (let i = 0; i < message.availableIntegrations.length; i++)
            PaymentIntegrationDefinitionVM.internalBinaryWrite(message.availableIntegrations[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string defaultPaymentTender = 3; */
        if (message.defaultPaymentTender !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.defaultPaymentTender);
        /* optional bool enableAmazonOnePay = 4; */
        if (message.enableAmazonOnePay !== undefined)
            writer.tag(4, WireType.Varint).bool(message.enableAmazonOnePay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentIntegrationsVM
 */
export const PaymentIntegrationsVM = new PaymentIntegrationsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentIntegrationDefinitionVM$Type extends MessageType<PaymentIntegrationDefinitionVM> {
    constructor() {
        super("Mgmt.PaymentIntegrationDefinitionVM", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "paymentMethod", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationFields", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FormFieldTypeVM } },
            { no: 5, name: "KioskFields", kind: "map", jsonName: "KioskFields", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FormFieldTypeVM } },
            { no: 6, name: "PaymentIntegrationUrls", kind: "message", jsonName: "PaymentIntegrationUrls", repeat: 1 /*RepeatType.PACKED*/, T: () => PaymentIntegrationUrl }
        ]);
    }
    create(value?: PartialMessage<PaymentIntegrationDefinitionVM>): PaymentIntegrationDefinitionVM {
        const message = { paymentIntegrationId: "", displayName: "", paymentMethod: "", locationFields: {}, kioskFields: {}, paymentIntegrationUrls: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentIntegrationDefinitionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentIntegrationDefinitionVM): PaymentIntegrationDefinitionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* string displayName */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string paymentMethod */ 3:
                    message.paymentMethod = reader.string();
                    break;
                case /* map<string, Mgmt.FormFieldTypeVM> locationFields */ 4:
                    this.binaryReadMap4(message.locationFields, reader, options);
                    break;
                case /* map<string, Mgmt.FormFieldTypeVM> KioskFields = 5 [json_name = "KioskFields"];*/ 5:
                    this.binaryReadMap5(message.kioskFields, reader, options);
                    break;
                case /* repeated Mgmt.PaymentIntegrationUrl PaymentIntegrationUrls = 6 [json_name = "PaymentIntegrationUrls"];*/ 6:
                    message.paymentIntegrationUrls.push(PaymentIntegrationUrl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: PaymentIntegrationDefinitionVM["locationFields"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PaymentIntegrationDefinitionVM["locationFields"] | undefined, val: PaymentIntegrationDefinitionVM["locationFields"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FormFieldTypeVM.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.PaymentIntegrationDefinitionVM.locationFields");
            }
        }
        map[key ?? ""] = val ?? FormFieldTypeVM.create();
    }
    private binaryReadMap5(map: PaymentIntegrationDefinitionVM["kioskFields"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PaymentIntegrationDefinitionVM["kioskFields"] | undefined, val: PaymentIntegrationDefinitionVM["kioskFields"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FormFieldTypeVM.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.PaymentIntegrationDefinitionVM.KioskFields");
            }
        }
        map[key ?? ""] = val ?? FormFieldTypeVM.create();
    }
    internalBinaryWrite(message: PaymentIntegrationDefinitionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* string displayName = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string paymentMethod = 3; */
        if (message.paymentMethod !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.paymentMethod);
        /* map<string, Mgmt.FormFieldTypeVM> locationFields = 4; */
        for (let k of Object.keys(message.locationFields)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FormFieldTypeVM.internalBinaryWrite(message.locationFields[k], writer, options);
            writer.join().join();
        }
        /* map<string, Mgmt.FormFieldTypeVM> KioskFields = 5 [json_name = "KioskFields"]; */
        for (let k of Object.keys(message.kioskFields)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FormFieldTypeVM.internalBinaryWrite(message.kioskFields[k], writer, options);
            writer.join().join();
        }
        /* repeated Mgmt.PaymentIntegrationUrl PaymentIntegrationUrls = 6 [json_name = "PaymentIntegrationUrls"]; */
        for (let i = 0; i < message.paymentIntegrationUrls.length; i++)
            PaymentIntegrationUrl.internalBinaryWrite(message.paymentIntegrationUrls[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentIntegrationDefinitionVM
 */
export const PaymentIntegrationDefinitionVM = new PaymentIntegrationDefinitionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentTendersVM$Type extends MessageType<PaymentTendersVM> {
    constructor() {
        super("Mgmt.PaymentTendersVM", [
            { no: 1, name: "requiredTenderType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenderOptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TenderOptionVM },
            { no: 3, name: "cardNetworkMappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TenderOptionVM }
        ]);
    }
    create(value?: PartialMessage<PaymentTendersVM>): PaymentTendersVM {
        const message = { requiredTenderType: "", tenderOptions: [], cardNetworkMappings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentTendersVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentTendersVM): PaymentTendersVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string requiredTenderType */ 1:
                    message.requiredTenderType = reader.string();
                    break;
                case /* repeated Mgmt.TenderOptionVM tenderOptions */ 2:
                    message.tenderOptions.push(TenderOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Mgmt.TenderOptionVM cardNetworkMappings */ 3:
                    message.cardNetworkMappings.push(TenderOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentTendersVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string requiredTenderType = 1; */
        if (message.requiredTenderType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requiredTenderType);
        /* repeated Mgmt.TenderOptionVM tenderOptions = 2; */
        for (let i = 0; i < message.tenderOptions.length; i++)
            TenderOptionVM.internalBinaryWrite(message.tenderOptions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated Mgmt.TenderOptionVM cardNetworkMappings = 3; */
        for (let i = 0; i < message.cardNetworkMappings.length; i++)
            TenderOptionVM.internalBinaryWrite(message.cardNetworkMappings[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaymentTendersVM
 */
export const PaymentTendersVM = new PaymentTendersVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskPaymentConfigVM$Type extends MessageType<KioskPaymentConfigVM> {
    constructor() {
        super("Mgmt.KioskPaymentConfigVM", [
            { no: 1, name: "integrations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KioskPaymentIntegrationConfigVM }
        ]);
    }
    create(value?: PartialMessage<KioskPaymentConfigVM>): KioskPaymentConfigVM {
        const message = { integrations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskPaymentConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskPaymentConfigVM): KioskPaymentConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.KioskPaymentIntegrationConfigVM integrations */ 1:
                    message.integrations.push(KioskPaymentIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskPaymentConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.KioskPaymentIntegrationConfigVM integrations = 1; */
        for (let i = 0; i < message.integrations.length; i++)
            KioskPaymentIntegrationConfigVM.internalBinaryWrite(message.integrations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskPaymentConfigVM
 */
export const KioskPaymentConfigVM = new KioskPaymentConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KioskPaymentIntegrationConfigVM$Type extends MessageType<KioskPaymentIntegrationConfigVM> {
    constructor() {
        super("Mgmt.KioskPaymentIntegrationConfigVM", [
            { no: 1, name: "paymentIntegrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "settings", kind: "message", T: () => FormValues }
        ]);
    }
    create(value?: PartialMessage<KioskPaymentIntegrationConfigVM>): KioskPaymentIntegrationConfigVM {
        const message = { paymentIntegrationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KioskPaymentIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KioskPaymentIntegrationConfigVM): KioskPaymentIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string paymentIntegrationId */ 1:
                    message.paymentIntegrationId = reader.string();
                    break;
                case /* Mgmt.FormValues settings */ 3:
                    message.settings = FormValues.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KioskPaymentIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string paymentIntegrationId = 1; */
        if (message.paymentIntegrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentIntegrationId);
        /* Mgmt.FormValues settings = 3; */
        if (message.settings)
            FormValues.internalBinaryWrite(message.settings, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.KioskPaymentIntegrationConfigVM
 */
export const KioskPaymentIntegrationConfigVM = new KioskPaymentIntegrationConfigVM$Type();
