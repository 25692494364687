/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "companiesService.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import { AccessControlListVM } from "./accessList_pb";
import { EmptyResponse } from "./common_pb";
import { CompanyRequest } from "./common_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { CompanyVM } from "./companies_pb";
/**
 * @generated from protobuf message Mgmt.UpdateCompanyRequest
 */
export interface UpdateCompanyRequest {
    /**
     * @generated from protobuf field: string companyId = 1;
     */
    companyId: string;
    /**
     * @generated from protobuf field: Mgmt.CompanyVM company = 2;
     */
    company?: CompanyVM;
}
/**
 * @generated from protobuf message Mgmt.AddCompanyRequest
 */
export interface AddCompanyRequest {
    /**
     * @generated from protobuf field: Mgmt.CompanyVM company = 1;
     */
    company?: CompanyVM;
}
/**
 * @generated from protobuf message Mgmt.AddCompanyUserAccessRequest
 */
export interface AddCompanyUserAccessRequest {
    /**
     * @generated from protobuf field: string CompanyId = 1 [json_name = "CompanyId"];
     */
    companyId: string;
    /**
     * @generated from protobuf field: string UserEmail = 2 [json_name = "UserEmail"];
     */
    userEmail: string;
    /**
     * @generated from protobuf field: string RoleName = 3 [json_name = "RoleName"];
     */
    roleName: string;
}
/**
 * @generated from protobuf message Mgmt.UpdateCompanyUserAccessRequest
 */
export interface UpdateCompanyUserAccessRequest {
    /**
     * @generated from protobuf field: string CompanyId = 1 [json_name = "CompanyId"];
     */
    companyId: string;
    /**
     * @generated from protobuf field: string UserId = 2 [json_name = "UserId"];
     */
    userId: string;
    /**
     * @generated from protobuf field: string RoleName = 3 [json_name = "RoleName"];
     */
    roleName: string;
}
/**
 * @generated from protobuf message Mgmt.RemoveCompanyUserAccessRequest
 */
export interface RemoveCompanyUserAccessRequest {
    /**
     * @generated from protobuf field: string CompanyId = 1 [json_name = "CompanyId"];
     */
    companyId: string;
    /**
     * @generated from protobuf field: string UserId = 2 [json_name = "UserId"];
     */
    userId: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyRequest$Type extends MessageType<UpdateCompanyRequest> {
    constructor() {
        super("Mgmt.UpdateCompanyRequest", [
            { no: 1, name: "companyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "company", kind: "message", T: () => CompanyVM }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyRequest>): UpdateCompanyRequest {
        const message = { companyId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyRequest): UpdateCompanyRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string companyId */ 1:
                    message.companyId = reader.string();
                    break;
                case /* Mgmt.CompanyVM company */ 2:
                    message.company = CompanyVM.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string companyId = 1; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* Mgmt.CompanyVM company = 2; */
        if (message.company)
            CompanyVM.internalBinaryWrite(message.company, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateCompanyRequest
 */
export const UpdateCompanyRequest = new UpdateCompanyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompanyRequest$Type extends MessageType<AddCompanyRequest> {
    constructor() {
        super("Mgmt.AddCompanyRequest", [
            { no: 1, name: "company", kind: "message", T: () => CompanyVM }
        ]);
    }
    create(value?: PartialMessage<AddCompanyRequest>): AddCompanyRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddCompanyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompanyRequest): AddCompanyRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Mgmt.CompanyVM company */ 1:
                    message.company = CompanyVM.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompanyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Mgmt.CompanyVM company = 1; */
        if (message.company)
            CompanyVM.internalBinaryWrite(message.company, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddCompanyRequest
 */
export const AddCompanyRequest = new AddCompanyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompanyUserAccessRequest$Type extends MessageType<AddCompanyUserAccessRequest> {
    constructor() {
        super("Mgmt.AddCompanyUserAccessRequest", [
            { no: 1, name: "CompanyId", kind: "scalar", jsonName: "CompanyId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserEmail", kind: "scalar", jsonName: "UserEmail", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "RoleName", kind: "scalar", jsonName: "RoleName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddCompanyUserAccessRequest>): AddCompanyUserAccessRequest {
        const message = { companyId: "", userEmail: "", roleName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddCompanyUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompanyUserAccessRequest): AddCompanyUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CompanyId = 1 [json_name = "CompanyId"];*/ 1:
                    message.companyId = reader.string();
                    break;
                case /* string UserEmail = 2 [json_name = "UserEmail"];*/ 2:
                    message.userEmail = reader.string();
                    break;
                case /* string RoleName = 3 [json_name = "RoleName"];*/ 3:
                    message.roleName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompanyUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CompanyId = 1 [json_name = "CompanyId"]; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* string UserEmail = 2 [json_name = "UserEmail"]; */
        if (message.userEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userEmail);
        /* string RoleName = 3 [json_name = "RoleName"]; */
        if (message.roleName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roleName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.AddCompanyUserAccessRequest
 */
export const AddCompanyUserAccessRequest = new AddCompanyUserAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompanyUserAccessRequest$Type extends MessageType<UpdateCompanyUserAccessRequest> {
    constructor() {
        super("Mgmt.UpdateCompanyUserAccessRequest", [
            { no: 1, name: "CompanyId", kind: "scalar", jsonName: "CompanyId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserId", kind: "scalar", jsonName: "UserId", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "RoleName", kind: "scalar", jsonName: "RoleName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompanyUserAccessRequest>): UpdateCompanyUserAccessRequest {
        const message = { companyId: "", userId: "", roleName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCompanyUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompanyUserAccessRequest): UpdateCompanyUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CompanyId = 1 [json_name = "CompanyId"];*/ 1:
                    message.companyId = reader.string();
                    break;
                case /* string UserId = 2 [json_name = "UserId"];*/ 2:
                    message.userId = reader.string();
                    break;
                case /* string RoleName = 3 [json_name = "RoleName"];*/ 3:
                    message.roleName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompanyUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CompanyId = 1 [json_name = "CompanyId"]; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* string UserId = 2 [json_name = "UserId"]; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string RoleName = 3 [json_name = "RoleName"]; */
        if (message.roleName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roleName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.UpdateCompanyUserAccessRequest
 */
export const UpdateCompanyUserAccessRequest = new UpdateCompanyUserAccessRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveCompanyUserAccessRequest$Type extends MessageType<RemoveCompanyUserAccessRequest> {
    constructor() {
        super("Mgmt.RemoveCompanyUserAccessRequest", [
            { no: 1, name: "CompanyId", kind: "scalar", jsonName: "CompanyId", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UserId", kind: "scalar", jsonName: "UserId", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveCompanyUserAccessRequest>): RemoveCompanyUserAccessRequest {
        const message = { companyId: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveCompanyUserAccessRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveCompanyUserAccessRequest): RemoveCompanyUserAccessRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CompanyId = 1 [json_name = "CompanyId"];*/ 1:
                    message.companyId = reader.string();
                    break;
                case /* string UserId = 2 [json_name = "UserId"];*/ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveCompanyUserAccessRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CompanyId = 1 [json_name = "CompanyId"]; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* string UserId = 2 [json_name = "UserId"]; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RemoveCompanyUserAccessRequest
 */
export const RemoveCompanyUserAccessRequest = new RemoveCompanyUserAccessRequest$Type();
/**
 * @generated ServiceType for protobuf service Mgmt.CompaniesService
 */
export const CompaniesService = new ServiceType("Mgmt.CompaniesService", [
    { name: "UpsertCompany", options: {}, I: UpdateCompanyRequest, O: CompanyVM },
    { name: "GetCompany", options: {}, I: CompanyRequest, O: CompanyVM },
    { name: "AddCompany", options: {}, I: AddCompanyRequest, O: CompanyVM },
    { name: "DeactivateCompany", options: {}, I: CompanyRequest, O: EmptyResponse },
    { name: "GetCompanyUserAccessList", options: {}, I: CompanyRequest, O: AccessControlListVM },
    { name: "AddUserAccess", options: {}, I: AddCompanyUserAccessRequest, O: AccessControlListVM },
    { name: "UpdateUserAccess", options: {}, I: UpdateCompanyUserAccessRequest, O: AccessControlListVM },
    { name: "RemoveUserAccess", options: {}, I: RemoveCompanyUserAccessRequest, O: AccessControlListVM }
]);
