/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "companies.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AddressVM } from "./common_pb";
import { ImageVM } from "./common_pb";
import { PhoneNumberVM } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.CompanyVM
 */
export interface CompanyVM {
    /**
     * @generated from protobuf field: string companyId = 1;
     */
    companyId: string;
    /**
     * @generated from protobuf field: string companyName = 2;
     */
    companyName: string;
    /**
     * @generated from protobuf field: string businessEmail = 3;
     */
    businessEmail: string;
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM businessPhone = 4;
     */
    businessPhone?: PhoneNumberVM;
    /**
     * @generated from protobuf field: Mgmt.PhoneNumberVM alternatePhone = 5;
     */
    alternatePhone?: PhoneNumberVM;
    /**
     * @generated from protobuf field: Mgmt.ImageVM businessLogo = 6;
     */
    businessLogo?: ImageVM;
    /**
     * @generated from protobuf field: bool isActive = 7;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: Mgmt.AddressVM address = 9;
     */
    address?: AddressVM;
    /**
     * @generated from protobuf field: repeated string enabledPosIntegrations = 10;
     */
    enabledPosIntegrations: string[];
    /**
     * @generated from protobuf field: repeated string enabledPaymentIntegrations = 11;
     */
    enabledPaymentIntegrations: string[];
    /**
     * @generated from protobuf field: repeated string enabledLoyaltyIntegrations = 12;
     */
    enabledLoyaltyIntegrations: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CompanyVM$Type extends MessageType<CompanyVM> {
    constructor() {
        super("Mgmt.CompanyVM", [
            { no: 1, name: "companyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "companyName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "businessEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "businessPhone", kind: "message", T: () => PhoneNumberVM },
            { no: 5, name: "alternatePhone", kind: "message", T: () => PhoneNumberVM },
            { no: 6, name: "businessLogo", kind: "message", T: () => ImageVM },
            { no: 7, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "address", kind: "message", T: () => AddressVM },
            { no: 10, name: "enabledPosIntegrations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "enabledPaymentIntegrations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "enabledLoyaltyIntegrations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompanyVM>): CompanyVM {
        const message = { companyId: "", companyName: "", businessEmail: "", isActive: false, enabledPosIntegrations: [], enabledPaymentIntegrations: [], enabledLoyaltyIntegrations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompanyVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompanyVM): CompanyVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string companyId */ 1:
                    message.companyId = reader.string();
                    break;
                case /* string companyName */ 2:
                    message.companyName = reader.string();
                    break;
                case /* string businessEmail */ 3:
                    message.businessEmail = reader.string();
                    break;
                case /* Mgmt.PhoneNumberVM businessPhone */ 4:
                    message.businessPhone = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.businessPhone);
                    break;
                case /* Mgmt.PhoneNumberVM alternatePhone */ 5:
                    message.alternatePhone = PhoneNumberVM.internalBinaryRead(reader, reader.uint32(), options, message.alternatePhone);
                    break;
                case /* Mgmt.ImageVM businessLogo */ 6:
                    message.businessLogo = ImageVM.internalBinaryRead(reader, reader.uint32(), options, message.businessLogo);
                    break;
                case /* bool isActive */ 7:
                    message.isActive = reader.bool();
                    break;
                case /* Mgmt.AddressVM address */ 9:
                    message.address = AddressVM.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* repeated string enabledPosIntegrations */ 10:
                    message.enabledPosIntegrations.push(reader.string());
                    break;
                case /* repeated string enabledPaymentIntegrations */ 11:
                    message.enabledPaymentIntegrations.push(reader.string());
                    break;
                case /* repeated string enabledLoyaltyIntegrations */ 12:
                    message.enabledLoyaltyIntegrations.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompanyVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string companyId = 1; */
        if (message.companyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyId);
        /* string companyName = 2; */
        if (message.companyName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.companyName);
        /* string businessEmail = 3; */
        if (message.businessEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.businessEmail);
        /* Mgmt.PhoneNumberVM businessPhone = 4; */
        if (message.businessPhone)
            PhoneNumberVM.internalBinaryWrite(message.businessPhone, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PhoneNumberVM alternatePhone = 5; */
        if (message.alternatePhone)
            PhoneNumberVM.internalBinaryWrite(message.alternatePhone, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.ImageVM businessLogo = 6; */
        if (message.businessLogo)
            ImageVM.internalBinaryWrite(message.businessLogo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool isActive = 7; */
        if (message.isActive !== false)
            writer.tag(7, WireType.Varint).bool(message.isActive);
        /* Mgmt.AddressVM address = 9; */
        if (message.address)
            AddressVM.internalBinaryWrite(message.address, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated string enabledPosIntegrations = 10; */
        for (let i = 0; i < message.enabledPosIntegrations.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.enabledPosIntegrations[i]);
        /* repeated string enabledPaymentIntegrations = 11; */
        for (let i = 0; i < message.enabledPaymentIntegrations.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.enabledPaymentIntegrations[i]);
        /* repeated string enabledLoyaltyIntegrations = 12; */
        for (let i = 0; i < message.enabledLoyaltyIntegrations.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.enabledLoyaltyIntegrations[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.CompanyVM
 */
export const CompanyVM = new CompanyVM$Type();
