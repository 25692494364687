/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable,add_pb_suffix,long_type_string,generate_dependencies
// @generated from protobuf file "loyalty.proto" (package "Mgmt", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TenderOptionVM } from "./common_pb";
import { FormFieldTypeVM } from "./common_pb";
/**
 * @generated from protobuf message Mgmt.RemoveIntegrationRequest
 */
export interface RemoveIntegrationRequest {
    /**
     * @generated from protobuf field: string integrationId = 1;
     */
    integrationId: string;
}
/**
 * @generated from protobuf message Mgmt.LocationLoyaltyConfigRequest
 */
export interface LocationLoyaltyConfigRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: Mgmt.LoyaltyIntegrationConfigVM configuration = 2;
     */
    configuration?: LoyaltyIntegrationConfigVM;
    /**
     * @generated from protobuf field: string integrationType = 3;
     */
    integrationType: string;
}
/**
 * @generated from protobuf message Mgmt.LoyaltyIntegrationDefinitionsVM
 */
export interface LoyaltyIntegrationDefinitionsVM {
    /**
     * @generated from protobuf field: repeated Mgmt.LoyaltyIntegrationDefinitionVM integrations = 1;
     */
    integrations: LoyaltyIntegrationDefinitionVM[];
}
/**
 * @generated from protobuf message Mgmt.LoyaltyIntegrationConfigVM
 */
export interface LoyaltyIntegrationConfigVM {
    /**
     * @generated from protobuf field: string integrationId = 1;
     */
    integrationId: string;
    /**
     * @generated from protobuf oneof: configuration
     */
    configuration: {
        oneofKind: "punchh";
        /**
         * @generated from protobuf field: Mgmt.PunchhIntegrationConfigVM punchh = 2;
         */
        punchh: PunchhIntegrationConfigVM;
    } | {
        oneofKind: "paytronix";
        /**
         * @generated from protobuf field: Mgmt.PaytronixIntegrationConfigVM paytronix = 3;
         */
        paytronix: PaytronixIntegrationConfigVM;
    } | {
        oneofKind: "generic";
        /**
         * @generated from protobuf field: Mgmt.GenericIntegrationConfig generic = 7;
         */
        generic: GenericIntegrationConfig;
    } | {
        oneofKind: "rbi";
        /**
         * @generated from protobuf field: Mgmt.RbiIntegrationConfigVM rbi = 8;
         */
        rbi: RbiIntegrationConfigVM;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: int32 maximum_selectable_rewards = 4;
     */
    maximumSelectableRewards: number;
    /**
     * @generated from protobuf field: bool enabled = 5;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: string tender = 6;
     */
    tender: string;
}
/**
 * @generated from protobuf message Mgmt.GenericIntegrationConfig
 */
export interface GenericIntegrationConfig {
    /**
     * @generated from protobuf field: map<string, string> values = 1;
     */
    values: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message Mgmt.PunchhIntegrationConfigVM
 */
export interface PunchhIntegrationConfigVM {
    /**
     * @generated from protobuf field: string locationKey = 1;
     */
    locationKey: string;
    /**
     * @generated from protobuf field: string businessKey = 2;
     */
    businessKey: string;
    /**
     * @generated from protobuf field: bool askForDateOfBirth = 3;
     */
    askForDateOfBirth: boolean;
    /**
     * @generated from protobuf field: bool askForGender = 4;
     */
    askForGender: boolean;
    /**
     * @generated from protobuf field: string taxMenuItemID = 5;
     */
    taxMenuItemID: string;
}
/**
 * @generated from protobuf message Mgmt.BinRange
 */
export interface BinRange {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string;
}
/**
 * @generated from protobuf message Mgmt.PaytronixIntegrationConfigVM
 */
export interface PaytronixIntegrationConfigVM {
    /**
     * @generated from protobuf field: int64 merchantId = 1;
     */
    merchantId: string;
    /**
     * @generated from protobuf field: string storeCode = 2;
     */
    storeCode: string;
    /**
     * @generated from protobuf field: string operatorId = 3;
     */
    operatorId: string;
    /**
     * @generated from protobuf field: string terminalId = 4;
     */
    terminalId: string;
    /**
     * @generated from protobuf field: string url = 5;
     */
    url: string;
    /**
     * @generated from protobuf field: string api_version = 6;
     */
    apiVersion: string;
    /**
     * @generated from protobuf field: int64 loyalty_template_code = 7;
     */
    loyaltyTemplateCode: string;
    /**
     * @generated from protobuf field: int64 loyalty_wallet_code = 8;
     */
    loyaltyWalletCode: string;
    /**
     * @generated from protobuf field: repeated Mgmt.BinRange binRanges = 9;
     */
    binRanges: BinRange[];
}
/**
 * @generated from protobuf message Mgmt.LoyaltyIntegrationDefinitionVM
 */
export interface LoyaltyIntegrationDefinitionVM {
    /**
     * @generated from protobuf field: string integrationId = 1;
     */
    integrationId: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: map<string, Mgmt.FormFieldTypeVM> fields = 4;
     */
    fields: {
        [key: string]: FormFieldTypeVM;
    };
}
/**
 * @generated from protobuf message Mgmt.DiscountTendersVM
 */
export interface DiscountTendersVM {
    /**
     * @generated from protobuf field: repeated Mgmt.TenderOptionVM discountTenders = 1;
     */
    discountTenders: TenderOptionVM[];
}
/**
 * @generated from protobuf message Mgmt.RbiIntegrationConfigVM
 */
export interface RbiIntegrationConfigVM {
    /**
     * @generated from protobuf field: string apiUrl = 1;
     */
    apiUrl: string;
    /**
     * @generated from protobuf field: string apiKey = 2;
     */
    apiKey: string;
    /**
     * @generated from protobuf field: string postype = 3;
     */
    postype: string;
    /**
     * @generated from protobuf field: string storeId = 4;
     */
    storeId: string;
    /**
     * @generated from protobuf field: string terminal = 5;
     */
    terminal: string;
    /**
     * @generated from protobuf field: string operatorID = 6;
     */
    operatorID: string;
    /**
     * @generated from protobuf field: string supportingPos = 7;
     */
    supportingPos: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RemoveIntegrationRequest$Type extends MessageType<RemoveIntegrationRequest> {
    constructor() {
        super("Mgmt.RemoveIntegrationRequest", [
            { no: 1, name: "integrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveIntegrationRequest>): RemoveIntegrationRequest {
        const message = { integrationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveIntegrationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveIntegrationRequest): RemoveIntegrationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string integrationId */ 1:
                    message.integrationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveIntegrationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string integrationId = 1; */
        if (message.integrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.integrationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RemoveIntegrationRequest
 */
export const RemoveIntegrationRequest = new RemoveIntegrationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationLoyaltyConfigRequest$Type extends MessageType<LocationLoyaltyConfigRequest> {
    constructor() {
        super("Mgmt.LocationLoyaltyConfigRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "configuration", kind: "message", T: () => LoyaltyIntegrationConfigVM },
            { no: 3, name: "integrationType", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LocationLoyaltyConfigRequest>): LocationLoyaltyConfigRequest {
        const message = { locationId: "", integrationType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocationLoyaltyConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationLoyaltyConfigRequest): LocationLoyaltyConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* Mgmt.LoyaltyIntegrationConfigVM configuration */ 2:
                    message.configuration = LoyaltyIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, message.configuration);
                    break;
                case /* string integrationType */ 3:
                    message.integrationType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationLoyaltyConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* Mgmt.LoyaltyIntegrationConfigVM configuration = 2; */
        if (message.configuration)
            LoyaltyIntegrationConfigVM.internalBinaryWrite(message.configuration, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string integrationType = 3; */
        if (message.integrationType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.integrationType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LocationLoyaltyConfigRequest
 */
export const LocationLoyaltyConfigRequest = new LocationLoyaltyConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyIntegrationDefinitionsVM$Type extends MessageType<LoyaltyIntegrationDefinitionsVM> {
    constructor() {
        super("Mgmt.LoyaltyIntegrationDefinitionsVM", [
            { no: 1, name: "integrations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LoyaltyIntegrationDefinitionVM }
        ]);
    }
    create(value?: PartialMessage<LoyaltyIntegrationDefinitionsVM>): LoyaltyIntegrationDefinitionsVM {
        const message = { integrations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoyaltyIntegrationDefinitionsVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyIntegrationDefinitionsVM): LoyaltyIntegrationDefinitionsVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.LoyaltyIntegrationDefinitionVM integrations */ 1:
                    message.integrations.push(LoyaltyIntegrationDefinitionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyIntegrationDefinitionsVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.LoyaltyIntegrationDefinitionVM integrations = 1; */
        for (let i = 0; i < message.integrations.length; i++)
            LoyaltyIntegrationDefinitionVM.internalBinaryWrite(message.integrations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LoyaltyIntegrationDefinitionsVM
 */
export const LoyaltyIntegrationDefinitionsVM = new LoyaltyIntegrationDefinitionsVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyIntegrationConfigVM$Type extends MessageType<LoyaltyIntegrationConfigVM> {
    constructor() {
        super("Mgmt.LoyaltyIntegrationConfigVM", [
            { no: 1, name: "integrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "punchh", kind: "message", oneof: "configuration", T: () => PunchhIntegrationConfigVM },
            { no: 3, name: "paytronix", kind: "message", oneof: "configuration", T: () => PaytronixIntegrationConfigVM },
            { no: 7, name: "generic", kind: "message", oneof: "configuration", T: () => GenericIntegrationConfig },
            { no: 8, name: "rbi", kind: "message", oneof: "configuration", T: () => RbiIntegrationConfigVM },
            { no: 4, name: "maximum_selectable_rewards", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "tender", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoyaltyIntegrationConfigVM>): LoyaltyIntegrationConfigVM {
        const message = { integrationId: "", configuration: { oneofKind: undefined }, maximumSelectableRewards: 0, enabled: false, tender: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoyaltyIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyIntegrationConfigVM): LoyaltyIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string integrationId */ 1:
                    message.integrationId = reader.string();
                    break;
                case /* Mgmt.PunchhIntegrationConfigVM punchh */ 2:
                    message.configuration = {
                        oneofKind: "punchh",
                        punchh: PunchhIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).punchh)
                    };
                    break;
                case /* Mgmt.PaytronixIntegrationConfigVM paytronix */ 3:
                    message.configuration = {
                        oneofKind: "paytronix",
                        paytronix: PaytronixIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).paytronix)
                    };
                    break;
                case /* Mgmt.GenericIntegrationConfig generic */ 7:
                    message.configuration = {
                        oneofKind: "generic",
                        generic: GenericIntegrationConfig.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).generic)
                    };
                    break;
                case /* Mgmt.RbiIntegrationConfigVM rbi */ 8:
                    message.configuration = {
                        oneofKind: "rbi",
                        rbi: RbiIntegrationConfigVM.internalBinaryRead(reader, reader.uint32(), options, (message.configuration as any).rbi)
                    };
                    break;
                case /* int32 maximum_selectable_rewards */ 4:
                    message.maximumSelectableRewards = reader.int32();
                    break;
                case /* bool enabled */ 5:
                    message.enabled = reader.bool();
                    break;
                case /* string tender */ 6:
                    message.tender = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoyaltyIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string integrationId = 1; */
        if (message.integrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.integrationId);
        /* Mgmt.PunchhIntegrationConfigVM punchh = 2; */
        if (message.configuration.oneofKind === "punchh")
            PunchhIntegrationConfigVM.internalBinaryWrite(message.configuration.punchh, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.PaytronixIntegrationConfigVM paytronix = 3; */
        if (message.configuration.oneofKind === "paytronix")
            PaytronixIntegrationConfigVM.internalBinaryWrite(message.configuration.paytronix, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.GenericIntegrationConfig generic = 7; */
        if (message.configuration.oneofKind === "generic")
            GenericIntegrationConfig.internalBinaryWrite(message.configuration.generic, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* Mgmt.RbiIntegrationConfigVM rbi = 8; */
        if (message.configuration.oneofKind === "rbi")
            RbiIntegrationConfigVM.internalBinaryWrite(message.configuration.rbi, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 maximum_selectable_rewards = 4; */
        if (message.maximumSelectableRewards !== 0)
            writer.tag(4, WireType.Varint).int32(message.maximumSelectableRewards);
        /* bool enabled = 5; */
        if (message.enabled !== false)
            writer.tag(5, WireType.Varint).bool(message.enabled);
        /* string tender = 6; */
        if (message.tender !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tender);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LoyaltyIntegrationConfigVM
 */
export const LoyaltyIntegrationConfigVM = new LoyaltyIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenericIntegrationConfig$Type extends MessageType<GenericIntegrationConfig> {
    constructor() {
        super("Mgmt.GenericIntegrationConfig", [
            { no: 1, name: "values", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<GenericIntegrationConfig>): GenericIntegrationConfig {
        const message = { values: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenericIntegrationConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenericIntegrationConfig): GenericIntegrationConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> values */ 1:
                    this.binaryReadMap1(message.values, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GenericIntegrationConfig["values"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GenericIntegrationConfig["values"] | undefined, val: GenericIntegrationConfig["values"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.GenericIntegrationConfig.values");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GenericIntegrationConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> values = 1; */
        for (let k of Object.keys(message.values))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.values[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.GenericIntegrationConfig
 */
export const GenericIntegrationConfig = new GenericIntegrationConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PunchhIntegrationConfigVM$Type extends MessageType<PunchhIntegrationConfigVM> {
    constructor() {
        super("Mgmt.PunchhIntegrationConfigVM", [
            { no: 1, name: "locationKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "businessKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "askForDateOfBirth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "askForGender", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "taxMenuItemID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PunchhIntegrationConfigVM>): PunchhIntegrationConfigVM {
        const message = { locationKey: "", businessKey: "", askForDateOfBirth: false, askForGender: false, taxMenuItemID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PunchhIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PunchhIntegrationConfigVM): PunchhIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationKey */ 1:
                    message.locationKey = reader.string();
                    break;
                case /* string businessKey */ 2:
                    message.businessKey = reader.string();
                    break;
                case /* bool askForDateOfBirth */ 3:
                    message.askForDateOfBirth = reader.bool();
                    break;
                case /* bool askForGender */ 4:
                    message.askForGender = reader.bool();
                    break;
                case /* string taxMenuItemID */ 5:
                    message.taxMenuItemID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PunchhIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationKey = 1; */
        if (message.locationKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationKey);
        /* string businessKey = 2; */
        if (message.businessKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.businessKey);
        /* bool askForDateOfBirth = 3; */
        if (message.askForDateOfBirth !== false)
            writer.tag(3, WireType.Varint).bool(message.askForDateOfBirth);
        /* bool askForGender = 4; */
        if (message.askForGender !== false)
            writer.tag(4, WireType.Varint).bool(message.askForGender);
        /* string taxMenuItemID = 5; */
        if (message.taxMenuItemID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.taxMenuItemID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PunchhIntegrationConfigVM
 */
export const PunchhIntegrationConfigVM = new PunchhIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BinRange$Type extends MessageType<BinRange> {
    constructor() {
        super("Mgmt.BinRange", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BinRange>): BinRange {
        const message = { from: "", to: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BinRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BinRange): BinRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BinRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.BinRange
 */
export const BinRange = new BinRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaytronixIntegrationConfigVM$Type extends MessageType<PaytronixIntegrationConfigVM> {
    constructor() {
        super("Mgmt.PaytronixIntegrationConfigVM", [
            { no: 1, name: "merchantId", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 2, name: "storeCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "operatorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "terminalId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "api_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "loyalty_template_code", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 8, name: "loyalty_wallet_code", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 9, name: "binRanges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BinRange }
        ]);
    }
    create(value?: PartialMessage<PaytronixIntegrationConfigVM>): PaytronixIntegrationConfigVM {
        const message = { merchantId: "0", storeCode: "", operatorId: "", terminalId: "", url: "", apiVersion: "", loyaltyTemplateCode: "0", loyaltyWalletCode: "0", binRanges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaytronixIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaytronixIntegrationConfigVM): PaytronixIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 merchantId */ 1:
                    message.merchantId = reader.int64().toString();
                    break;
                case /* string storeCode */ 2:
                    message.storeCode = reader.string();
                    break;
                case /* string operatorId */ 3:
                    message.operatorId = reader.string();
                    break;
                case /* string terminalId */ 4:
                    message.terminalId = reader.string();
                    break;
                case /* string url */ 5:
                    message.url = reader.string();
                    break;
                case /* string api_version */ 6:
                    message.apiVersion = reader.string();
                    break;
                case /* int64 loyalty_template_code */ 7:
                    message.loyaltyTemplateCode = reader.int64().toString();
                    break;
                case /* int64 loyalty_wallet_code */ 8:
                    message.loyaltyWalletCode = reader.int64().toString();
                    break;
                case /* repeated Mgmt.BinRange binRanges */ 9:
                    message.binRanges.push(BinRange.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaytronixIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 merchantId = 1; */
        if (message.merchantId !== "0")
            writer.tag(1, WireType.Varint).int64(message.merchantId);
        /* string storeCode = 2; */
        if (message.storeCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.storeCode);
        /* string operatorId = 3; */
        if (message.operatorId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operatorId);
        /* string terminalId = 4; */
        if (message.terminalId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.terminalId);
        /* string url = 5; */
        if (message.url !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.url);
        /* string api_version = 6; */
        if (message.apiVersion !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.apiVersion);
        /* int64 loyalty_template_code = 7; */
        if (message.loyaltyTemplateCode !== "0")
            writer.tag(7, WireType.Varint).int64(message.loyaltyTemplateCode);
        /* int64 loyalty_wallet_code = 8; */
        if (message.loyaltyWalletCode !== "0")
            writer.tag(8, WireType.Varint).int64(message.loyaltyWalletCode);
        /* repeated Mgmt.BinRange binRanges = 9; */
        for (let i = 0; i < message.binRanges.length; i++)
            BinRange.internalBinaryWrite(message.binRanges[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.PaytronixIntegrationConfigVM
 */
export const PaytronixIntegrationConfigVM = new PaytronixIntegrationConfigVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyIntegrationDefinitionVM$Type extends MessageType<LoyaltyIntegrationDefinitionVM> {
    constructor() {
        super("Mgmt.LoyaltyIntegrationDefinitionVM", [
            { no: 1, name: "integrationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "fields", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FormFieldTypeVM } }
        ]);
    }
    create(value?: PartialMessage<LoyaltyIntegrationDefinitionVM>): LoyaltyIntegrationDefinitionVM {
        const message = { integrationId: "", name: "", fields: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoyaltyIntegrationDefinitionVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyIntegrationDefinitionVM): LoyaltyIntegrationDefinitionVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string integrationId */ 1:
                    message.integrationId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* map<string, Mgmt.FormFieldTypeVM> fields */ 4:
                    this.binaryReadMap4(message.fields, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: LoyaltyIntegrationDefinitionVM["fields"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyIntegrationDefinitionVM["fields"] | undefined, val: LoyaltyIntegrationDefinitionVM["fields"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FormFieldTypeVM.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Mgmt.LoyaltyIntegrationDefinitionVM.fields");
            }
        }
        map[key ?? ""] = val ?? FormFieldTypeVM.create();
    }
    internalBinaryWrite(message: LoyaltyIntegrationDefinitionVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string integrationId = 1; */
        if (message.integrationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.integrationId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* map<string, Mgmt.FormFieldTypeVM> fields = 4; */
        for (let k of Object.keys(message.fields)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FormFieldTypeVM.internalBinaryWrite(message.fields[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.LoyaltyIntegrationDefinitionVM
 */
export const LoyaltyIntegrationDefinitionVM = new LoyaltyIntegrationDefinitionVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiscountTendersVM$Type extends MessageType<DiscountTendersVM> {
    constructor() {
        super("Mgmt.DiscountTendersVM", [
            { no: 1, name: "discountTenders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TenderOptionVM }
        ]);
    }
    create(value?: PartialMessage<DiscountTendersVM>): DiscountTendersVM {
        const message = { discountTenders: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DiscountTendersVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DiscountTendersVM): DiscountTendersVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Mgmt.TenderOptionVM discountTenders */ 1:
                    message.discountTenders.push(TenderOptionVM.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DiscountTendersVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Mgmt.TenderOptionVM discountTenders = 1; */
        for (let i = 0; i < message.discountTenders.length; i++)
            TenderOptionVM.internalBinaryWrite(message.discountTenders[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.DiscountTendersVM
 */
export const DiscountTendersVM = new DiscountTendersVM$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RbiIntegrationConfigVM$Type extends MessageType<RbiIntegrationConfigVM> {
    constructor() {
        super("Mgmt.RbiIntegrationConfigVM", [
            { no: 1, name: "apiUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "apiKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "postype", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "storeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "terminal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "operatorID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "supportingPos", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RbiIntegrationConfigVM>): RbiIntegrationConfigVM {
        const message = { apiUrl: "", apiKey: "", postype: "", storeId: "", terminal: "", operatorID: "", supportingPos: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RbiIntegrationConfigVM>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RbiIntegrationConfigVM): RbiIntegrationConfigVM {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string apiUrl */ 1:
                    message.apiUrl = reader.string();
                    break;
                case /* string apiKey */ 2:
                    message.apiKey = reader.string();
                    break;
                case /* string postype */ 3:
                    message.postype = reader.string();
                    break;
                case /* string storeId */ 4:
                    message.storeId = reader.string();
                    break;
                case /* string terminal */ 5:
                    message.terminal = reader.string();
                    break;
                case /* string operatorID */ 6:
                    message.operatorID = reader.string();
                    break;
                case /* string supportingPos */ 7:
                    message.supportingPos = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RbiIntegrationConfigVM, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string apiUrl = 1; */
        if (message.apiUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.apiUrl);
        /* string apiKey = 2; */
        if (message.apiKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.apiKey);
        /* string postype = 3; */
        if (message.postype !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.postype);
        /* string storeId = 4; */
        if (message.storeId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.storeId);
        /* string terminal = 5; */
        if (message.terminal !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.terminal);
        /* string operatorID = 6; */
        if (message.operatorID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.operatorID);
        /* string supportingPos = 7; */
        if (message.supportingPos !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.supportingPos);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Mgmt.RbiIntegrationConfigVM
 */
export const RbiIntegrationConfigVM = new RbiIntegrationConfigVM$Type();
